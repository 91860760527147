import React, { useCallback, useEffect, useState } from "react";
import GetImg from "../../components/getImgs";
import wrapper from "../../hooks/wrapper";
import Functions from "../../hooks/functions";
import BreadcrumsClass from "../../js/BreadcrumsClass";
import { useDispatch, useStore } from "../../store/StoreProvider";
import LinkRef from "../../components/Others/LinkRef";
import { useHistory } from "react-router";

export default function SingleSuscription(params) {
    const Dispatch = useDispatch()
    const Store = useStore()
    const history = useHistory()
    const [DataSuscription, setDataSuscription] = useState({})
    const [Ordes, setOrdes] = useState([])
    const CancelSuscription = () => {
        Dispatch({ type: 'notification', data: { comfirm: true, open: true, vertical: 'top', horizontal: 'right', data: { mess: "Deseas cancelar la suscripcion" } }, exist: true })
    }
    const getDataSuscription = useCallback(async (id) => {
        // console.log(id);
        let dataSuscription = await wrapper.getDataU('subscriptions/' + id)
        if (dataSuscription === 400) {
            history.push("/")
        } else {
            // console.log(dataSuscription);
            setDataSuscription(dataSuscription?.data?.data)
            setOrdes(Functions.convertObjInArr(dataSuscription?.data?.data?.Orders))
            let bread = new BreadcrumsClass()
            bread.addBreadcrum('Tienda', "/")
            bread.addBreadcrum('Mis Suscripciones', "/subscriptions")
            bread.addBreadcrum(dataSuscription?.data?.data?.ServiceInfo?.PlanInfo?.PlanTitle, "")
            Dispatch({ type: 'addBread', data: bread })
        }
        // console.log(Functions.convertObjInArr(dataSuscription.data.data.Orders));
    }, [history, Dispatch])
    const handdleCancelSuscription = () => {
        // console.log("cancelar suscripcion");
        // console.log(DataSuscription.SSubID);
        // // console.log(cancelar);
        cancel()
        setTimeout(() => {
            Dispatch({ type: 'confirm', data: false })
        }, 1000);
        return <div></div>
    }
    const cancel = async()=>{
        const cancelar = await wrapper.deleteDataN("subscriptions/"+DataSuscription.SSubID+"/cancel",{})
        if (cancelar.status === 200) {
            history.push("/subscriptions")
        }
        // console.log("cancelar");
        // console.log(cancelar);
    }
    useEffect(() => {
        let id = window.location.pathname.split('/')[2]
        // console.log(id);

        getDataSuscription(id)
    }, [getDataSuscription])
    return (
        <div className="w-80 m-auto hminvh-75">
            {Store?.confirm ?
                handdleCancelSuscription() : ""}
            <div className="row d-block">
                <div className="col s12 m12 l8 stiky-f">
                    <div className="row">
                        <div className="col s6">
                            {/* <h5 className="mt-20">Suscripción #{DataSuscription?.SSubID}</h5> */}
                            <h5 className="mt-20">{DataSuscription?.ServiceInfo?.PlanInfo?.PlanTitle}</h5>
                        </div>
                        <div className="col s6 text-right mt-12">
                            <div>
                                {/* <div><span></span></div> */}
                                <div><span className={DataSuscription?.SSubStatus + '-inter p-2'}>{DataSuscription?.SSubStatus}</span></div>
                                {/* <div><small>Realizada el:</small></div> */}
                                <div><small>Realizada el: {Functions.fechaFormat(DataSuscription?.SSubStartDate)}</small></div>
                            </div>
                        </div>
                    </div>
                    <div className="card p-20">
                        <div className="row bg-orange white-text text-white p-20 w-100 m-auto mt-10">
                            <div className="col s12 l12 text-left centrar">
                                <h6 className="white-text text-white m-auto">Suscripciones</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s12 m12 l6 centrar pl-20">{DataSuscription?.ServiceInfo?.PlanInfo?.PlanTitle}
                            </div>
                            <div className="col s12 m12 l6 rigth">
                                <p>
                                    <strong>SubTotal:
                                    </strong>${DataSuscription?.SSubValue} {DataSuscription?.SSubCurrency}
                                </p>
                                <p>
                                    <strong>Total:
                                    </strong>${DataSuscription?.SSubValue} {DataSuscription?.SSubCurrency}
                                </p>
                                <p>
                                    <strong>Periodos:
                                    </strong> {DataSuscription?.SSybCurrentCycle}
                                </p>
                                {/* SSybCurrentCycle */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col s12 m12 l4 stiky-f" id="terminosF">
                    <div className="card p-20">
                        <div className="text-center">
                            <strong>Detalles de Suscripción</strong>
                            <hr />
                        </div>
                        <div className="text-left">
                            <div className="col s12 text-right">
                                <span className="logoPago mt-40">
                                    <GetImg type={DataSuscription?.SSubGatewayCode} />
                                </span>
                            </div>
                            <div>
                                <strong>Fecha de inicio:</strong>
                                <p className="mt-0"><small>{Functions.fechaFormat(DataSuscription?.SSubStartDate)}</small></p>
                            </div>
                            {DataSuscription?.SSubCancelledUser === "1" ?
                                <div>
                                    <strong>Fecha de renovación:</strong>
                                    <p className="mt-0"><small>{Functions.fechaFormat(DataSuscription?.SSubNextRenew)}</small></p>
                                </div> : ""}
                            <div>
                                <strong>Valor:</strong>
                                <p className="mt-0"><small>{Functions.formatPrice(DataSuscription?.SSubValue) + " MXN"}</small></p>
                            </div>
                            <div>
                                <strong>Frecuencia de Pago:</strong>
                                <p className="mt-0"><small>{DataSuscription?.SSubPaymentFrequency}</small></p>
                            </div>
                        </div>
                        <hr />
                        <div>
                            {Ordes.map((e, i) => {
                                return (
                                    <LinkRef key={"order" + i} to={"/MyOrders/" + e.OrderID}>
                                        <p>{Functions.fechaFormat(e.OrderDate)} Orden No. {e.OrderID}</p>
                                    </LinkRef>
                                )
                            })}
                        </div>
                        <hr />
                        <div>

                            <LinkRef to='/subscriptions' clas="btn bg-orange mt-20 w-100">Regresar</LinkRef>
                            {DataSuscription?.SSubStatus === "Active" && DataSuscription?.SSubCancelledUser === "0" ?
                                <button className="btn outline mt-20 w-100" onClick={() => CancelSuscription()}>Cancelar Suscripción</button>
                                : ""}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}