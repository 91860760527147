import React, { useState,useEffect } from 'react';
import { messageService } from "../hooks/global";
import Url from "../hooks/url";
import Wrapper from "../hooks/wrapper";
import visa from "../img/visa.png";
import FormCard from "../components/formCard";

const AddMethods = ()=> {
    const [metodos, setMetodos] = useState({});
    const [view, setView] = useState('lista');
    const [alert, setAlert] = useState(false);
    const [id, setId] = useState('');

    useEffect (() => { 
        messageService.sendMessage('Tienda,/ > Metodos de pago','bread');
        getMetodos()
    },[])
    async function getMetodos() {
        const getmetodos = await Wrapper.getData(Url+'users/preferences/payment_method')
        // console.log(getmetodos);
        setMetodos(getmetodos.data.data)
    }
    async function deleteTarjet() {
        await Wrapper.deleteData(Url+'users/preference/'+id)
        getMetodos()
        setAlert(false)
    }
    function viewMetodos() {
        if (Object.keys(metodos).length > 0) {
            let keyM = Object.keys(metodos)
            return(
                <div className="card pb-20">
                    <div className="row card-title bg-orange">
                        <div className="col s6 centrar">
                            <h4 className="sm white-text text-white">Mis tarjetas</h4>
                        </div>
                        <div className="col s6 centrar">
                            <button className="btn white text-orange ml-auto" onClick={()=>setView('registrar')}>Registrar tarjeta</button>
                        </div>
                    </div>
                    {keyM.map((e,index)=>{
                        // console.log(metodos[e].UPID);
                        return(
                            <div className="row" key={'mpago'+index}>
                                <div className="col s3 centrar">
                                    <span>
                                        {metodos[e].Info.CardBank}
                                    </span>
                                    <span className="ml-2">
                                        <img src={visa} alt="visa" width="100px" className="ml-3"/>
                                        {/* {metodos[e].Info.CardBrand} */}
                                    </span>
                                </div>
                                <div className="col s3 centrar">Vencimiento:{metodos[e].Info.CardExp}</div>
                                <div className="col s3 centrar">**** **** **** {metodos[e].Info.CardLast4}</div>
                                <div className="col s3 centrar">
                                    {/* <button className="btn bg-orange m-auto" onClick={()=>deleteTarjet(metodos[e].UPID)setAlert(false)}>Eliminar tarjeta</button> */}
                                    <button className="btn bg-orange m-auto" onClick={()=>deleteQuestion(metodos[e].UPID)}>Eliminar tarjeta</button>
                                </div>
                            </div>
                        )
                    })}
                </div>
            )
        }else{
            return(
                <div className="card pb-20">
                    <div className="row card-title bg-orange">
                        <div className="col s6 centrar">
                            <h4 className="sm white-text text-white">Mis tarjetas</h4>
                        </div>
                        <div className="col s6 centrar">
                            <button className="btn white text-orange ml-auto" onClick={()=>setView('registrar')}>Registrar tarjeta</button>
                        </div>
                    </div>
                </div>
            )
        }
    }
    function deleteQuestion(id) {
        setAlert(true) 
        setId(id)
    }
    async function handleInputChange(event) {
        event.preventDefault()
        let tarjeta = document.querySelector('#tarjeta')
        let data = {}
        data.titular = tarjeta[0].value
        data.PaymentMethod = 'card'
        data.Token = tarjeta[1].value
        data.caducidad = tarjeta[2].value+'/'+tarjeta[3].value
        data.code = tarjeta[4].value
        await Wrapper.postData(Url+'users/preferences/payment_method',data)
        getMetodos()
        setView('lista')
    }
    return(
        <div className="container pt-20 pb-20">
            {alert?
            <div className="pop">
                <div className="alert card pb-20">
                    <div className="card-title bg-orange">
                        <small className="white-text text-white fz-14">Deseas eliminar esta tarjeta de tus metodos de pago</small>
                    </div>
                    <button className="btn bg-orange" onClick={deleteTarjet}>Aceptar</button>
                    <button className="btn outline ml-5" onClick={()=>setAlert(false)}>Cancelar</button>
                </div>
            </div>:
            <div></div>
            }
            {view === 'lista'?
            viewMetodos():
            <div>
                <FormCard handleInputChange={handleInputChange} cancelar={setView}/>
            </div>
            }
        </div>
    )
}
export default AddMethods