import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";

class count extends React.Component{
    render(){
        const {title,descrip} = this.props
        return(
            <div className="w-100">
                <div className="circleSrvice m-auto">
                    <FontAwesomeIcon icon={faCog} />
                    <h5>{title}</h5>
                </div>
                <p className="text-center">{descrip}</p>
            </div>
        )
    }
}
export default count