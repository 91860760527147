import React from "react";
import { Link } from "react-router-dom";

function navAuthor(params) {
    return(
        <div className="col l2 bg-orange stiky-f">
            <div className="optionsAuthor mt-20">
                {/* <Link to="/Author/MyCourses" className="centrar white-text text-white"><i className="material-icons">assignment</i> Mis Cursos</Link> */}
                <Link to="/author/sales" className="centrar white-text text-white mt-20"><i className="material-icons-outlined">assessment</i> Ventas</Link>
                <Link to="/author/payment" className="centrar white-text text-white mt-20"><i className="material-icons-outlined">attach_money</i> Pagos</Link>
                <Link to="/author/invoices" className="centrar white-text text-white mt-20"><i className="material-icons-outlined">description</i> Facturas</Link>
            </div>
        </div>
    )
}

export default navAuthor