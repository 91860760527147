import React from "react";
export default function LeyenPaypal(params) {
    return (
        <div>
            <ol>
                <li>Lee y Acepta los términos y condiciones de servicio</li>
                <li>Da clic en el botón de pagar ahora</li>
                <li>El sistema te generará un número de referencia</li>
            </ol>
            <div className="opps-footnote">Nota: Tu pago en OXXO podría tardar en verse reflejado hasta 48 hrs despues de realizar el pago.</div>
        </div>
    )
}