import React from "react";
import Card from "./card";
import Loader from "./loader";
import Wrapper from "../hooks/wrapper";
import Url from "../hooks/url";
import Paginador from "./paginador";
// import Acordeon from "../components/acordeon";

const headerss = {
    head1:[
        // { id: 'CourseID', label: 'ID', minWidth: 100, align: 'center'},
        { id: 'CourseName', label: 'Curso', minWidth: 100, align: 'center'},
        { id: 'AuthorName', label: 'Creado por', minWidth: 100, align: 'center'},
        { id: 'CourseDuration', label: 'Duración (hrs)', minWidth: 100, align: 'center'},
        { id: 'CourseLevel', label: 'Nivel', minWidth: 200, align: 'center'},
        { id: 'CourseCategory', label: 'Categoria', minWidth: 100, align: 'center'},
        { id: 'CoursePrice', label: 'Precio', minWidth: 100, align: 'center'},
        { id: 'carrito', label: 'Carrito', minWidth: 100, align: 'center'},
    ]
}

class popular extends React.Component{
    constructor() {
        super();
        this.state= {
            keys:[],
            completo:[],
            cursos:[],
            acordeon:[],
            categories:[],
            duration:[],
            level:[],
            author:[],
            filtros:{},
            sendFiltros:{},
            vista:'mosaico',
            busqueda:false
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.clearFiltros = this.clearFiltros.bind(this);
        this.getFiltros = this.getFiltros.bind(this);
        this.buscador = this.buscador.bind(this);
        this.changeView = this.changeView.bind(this);
    }
    componentDidMount(){
        // console.log(this.props?.pop);
        if (this.props?.pop !== undefined) {
            this.setState({keys:Object.keys(this.props?.pop)})
        }
        this.separar()
        this.getFiltros()
    }
    async getFiltros(){
        const filtros = await Wrapper.getData(Url+'categories')
        // console.log(filtros);
        this.setState({sendFiltros:filtros.data.data})
    }
    handleInputChange(event,padre,value,clase) {
        const {categories,duration,level,author} = this.state
        let categorias = categories
        let duracion = duration
        let nivel = level
        let autor = author
        var target
        if (event.target !== undefined) {
            target = event.target;
            // console.log(target);
        }else{
            // console.log('categoria');
            target = document.querySelector('#'+clase)
            // console.log(target.checked);
            // console.log(target.value);
            // console.log(target.className);
        }
        // console.log(target);
        switch (target.className) {
            case 'CATEGORIAS':
                if (target.checked) {
                    categorias.push(parseInt(target.value))
                }else{
                    categorias.splice(categorias.length-1,1)
                }
                break;
            case 'DURACIÓN DEL CURSO':
                let parse = JSON.parse(target.value)
                if (target.checked) {
                    duracion.push(parse)
                }else{
                    duracion.splice(duration.length-1,1)
                }
                break;
            case 'DIFICULTAD DEL CURSO':
                if (target.checked) {
                    nivel.push(target.value)
                }else{
                    nivel.splice(nivel.length-1,1)
                }
                break;
            case 'PROVEEDOR DEL CURSO':
                if (target.checked) {
                    autor.push(parseInt(target.value))
                }else{
                    autor.splice(autor.length-1,1)
                }
                break;
            default:
                break;
        }
        this.filtrarCurso()
    }
    async filtrarCurso(){
        let filtro = {}
        const {categories,duration,level,author} = this.state
        filtro.categories = categories
        filtro.duration = duration
        filtro.level = level
        filtro.author = author
        this.setState({filtros:filtro})
        setTimeout(() => {
            // console.log(this.state.filtros);
            this.postFiltros(this.state.filtros)
        }, 100);
    }
    clearFiltros(){
        this.setState({categories: []})
        this.setState({duration: []})
        this.setState({level: []})
        this.setState({author: []})
        this.setState({completo:this.props?.pop})
        document.getElementById('filtrosC').reset()
        setTimeout(() => {
            this.filtrarCurso()
        }, 100);
    }
    async postFiltros(filtros){
        const filtro = await Wrapper.postData(Url+'courses',filtros)
        // console.log(filtro);
        if (filtro.data.data !== undefined) {
            let acordeon = document.querySelectorAll('.acordeon')
            acordeon.forEach(e=>{
                e.classList.add('oculto')
            })
            this.setState({cursos:filtro.data.data.courses})
        }else{
            let acordeon = document.querySelectorAll('.acordeon')
            acordeon.forEach(e=>{
                e.classList.add('oculto')
            })
            this.setState({cursos:filtro.data.courses})
        }
        // console.log(filtro.data.data.courses);

    }
    separar(){
        let cursos = []
        let acordeon = []
        for (let x = 0; x <= 5; x++) {
            cursos.push(this.props?.pop[x])
        }
        for (let x = 6; x <= this.props?.pop.length-1; x++) {
            acordeon.push(this.props?.pop[x])
        }
        this.setState({completo:this.props?.pop})
        this.setState({cursos:cursos})
        this.setState({acordeon:acordeon})
    }
    ver(){
        let acordeon = document.querySelectorAll('.acordeon')
        acordeon.forEach(e=>{
            e.classList.toggle('oculto')
        })
    }
    async buscador(event){
        event.preventDefault()
        let data = {}
        let valor = document.querySelector('#search')
        data.val = valor.value
        if (valor.value === '') {
            data.val = null
        }
        const result = await Wrapper.postData(Url+'course/search',data)
        // console.log(result);
        if (result !== undefined) {
            if (result.data.data !== undefined) {
                let acordeon = document.querySelectorAll('.acordeon')
                acordeon.forEach(e=>{
                    e.classList.add('oculto')
                })
                this.setState({cursos:{}})
                this.setState({cursos:result.data.data})
                this.setState({completo:result.data.data})
                this.setState({busqueda:true})
            }else{
                let acordeon = document.querySelectorAll('.acordeon')
                acordeon.forEach(e=>{
                    e.classList.add('oculto')
                })
                this.setState({cursos:{}})
                this.setState({cursos:result.data})
                this.setState({completo:result.data})
            }   
        }
    }
    dataDescription(vista,id){
        console.log(vista, id);
        // this.props.changeVista(vista,id)
    }
    changeView(data){
        this.setState({vista:data})
    }
    render(){
        const { keys,cursos,acordeon,sendFiltros,completo,vista,busqueda } = this.state
        // const { pop } = this.props
        if (keys.length === 0 && Object.keys(sendFiltros).length>0) {
            return(
                <Loader/>
            )
        }else{
            return(
                <div className="p-5 center-align" id="titleC">
                    <div className="w-90 m-auto">
                        <div className="row">
                            <div className="col s12 l11">
                                <h2 className="sm">Nuestros cursos</h2>
                            </div>
                            <div className="col s12 l1 centrar">
                                <div className="views" onClick={()=>this.changeView('mosaico')}>
                                    <i className="material-icons">view_module</i>
                                    <div className="tooltip-top">Cursos en tarjetas</div>
                                </div>
                                <div className="views ml-20" onClick={()=>this.changeView('lista')}>
                                    <i className="material-icons">view_list</i> 
                                    <div className="tooltip-top">Cursos  por lista</div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s12 l3 text-left">

                                <form id="filtrosC" onSubmit={this.buscador}>
                                    <strong className="title-filtro">Buscador de cursos</strong>
                                    <input type="text" placeholder="Busqueda" id="search" />
                                </form>
                                <button className="btn outline" onClick={this.buscador}>Buscar</button>
                                <button className="btn bg-orange ml-20" onClick={this.clearFiltros}>Limpiar</button>
                                <div className="centrar mt-20 text-center">
                                    <div className="views" onClick={()=>this.changeView('mosaico')}>
                                        <i className="material-icons">view_module</i>
                                        <div className="tooltip-top">Cursos en tarjetas</div>
                                    </div>
                                    <div className="views ml-20" onClick={()=>this.changeView('lista')}>
                                        <i className="material-icons">view_list</i> 
                                        <div className="tooltip-top">Cursos  por lista</div>
                                    </div>
                                </div>
                                {/* <hr className="bg-orange"/>
                                <p className="text-orange centrar pointer" onClick={this.clearFiltros}><i className="material-icons-outlined text-orange">refresh</i> Restablecer filtros</p> */}
                            </div>
                            <div className="col s12 l9 stiky-f">
                                {vista === 'lista'?
                                <Paginador headers={headerss.head1} body={completo} link="CourseName" dataDescription={this.dataDescription} redirect="Courses" list="cursos" type="nameId"/>:
                                <div className="row">
                                    {cursos.length > 0 ? 
                                    cursos.map((e,index)=>{
                                        // console.log(e);
                                        return(
                                            <div className="col s12 l4 sm" key={"desta"+index}>
                                                <Card position="" data={e}/>
                                            </div>
                                        )
                                    })
                                    :<div className="text-center w-100">
                                        <p><strong>No se encontraron resultados con tu criterio de búsqueda</strong></p>
                                        <button className="btn bg-orange" onClick={this.clearFiltros}>Limpiar busqueda</button>
                                    </div>}
                                </div>
                                }
                                {cursos.length > 0 && Object.keys(this.state.filtros).length === 0 && vista === 'mosaico' && !busqueda?
                                <button className="btn outline acordeon" onClick={this.ver}>Ver más</button>
                                :<div></div>}
                                <div className="row oculto acordeon">
                                    {acordeon.map((e,index)=>{
                                        return(
                                            <div className="col s12 l4 sm" key={"desta"+index}>
                                                <Card position="" data={e}/>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}
export default popular