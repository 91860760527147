import React from "react";
import { Link } from "react-router-dom";
import Url from "../../hooks/url";
import Wrapper from "../../hooks/wrapper";
import {messageService} from "../../hooks/global";
import Loader from "../loader";
import PopFactura from "./popFactura";
import VisorPDF from "../visorPDF";

class singlePaymant extends React.Component{
    constructor(){
        super();
        this.state={
            id:'',
            pago:{},
            pop:false,
            vista:'',
            activePDF:false
        }
        this.activePop = this.activePop.bind(this);
        this.getInicial = this.getInicial.bind(this);
        this.activePopPDF = this.activePopPDF.bind(this);
    }
    componentDidMount(){
        this.getInicial()
    }
    getInicial(){
        let url = window.location.pathname
        url = url.split('/')
        this.setState({vista:url[2]})
        url = url[3]
        messageService.sendMessage('Tienda,/ > Autor > Pagos,/Author/payment > Pago #'+url,'bread')
        this.setState({id:url})
        this.getData(url)
    }
    async getData(id){
        const orderSeller = await Wrapper.getData(Url+'orders_sellers/'+id+'/payment')
        console.log(orderSeller);
        this.setState({pago:orderSeller.data.data.SPaymentInfo})
    }
    activePop(data){
        this.setState({pop:data})
    }
    pop(){
        if (this.state.pop) {
            return(<PopFactura activePop={this.activePop} id={this.state.id} getInicial={this.getInicial} type="payment"/>)
        }
    }
    pdfV(data){
        if (this.state.activePDF) {
            const {id} = this.state
            return(
                <VisorPDF pdf={data} activePop={this.activePopPDF} title={"PDF de pago #"+id}/>
            )
        }
    }
    activePopPDF(data){
        this.setState({activePDF:data})
    }
    render(){
        const {id,pago} = this.state
        const {BookingInfo,OrdersSeller,SPaymentFile} = pago
        if (Object.keys(pago).length === 0) {
            return(<Loader/>)
        } else {
            let Sales = Object.keys(OrdersSeller)
            return(
                <div>
                    {this.pdfV(SPaymentFile)}
                    {this.pop()}
                    <div className="w-90 m-auto hminvh-77">
                        <p><Link to="/">Inicio</Link> / Autor / <Link to="/author/payment">Mis Pagos</Link> /Pago #{id}</p>
                        <div className="row">
                            <div className="col s6 l6">
                                <h5>Pago #{id}</h5>
                            </div>
                            <div className="col s6 l6 text-right">
                                <div><span className={pago.SPaymentStatusName+'-inter bage'}>{pago.SPaymentStatusName}</span></div>
                                <div><small>Realizada el: {pago.SPaymentDate}</small></div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s8">
                                <div className="w-100 card p-20">
                                    <h5>Desglose de Pago</h5>
                                    <hr/>
                                    <p><strong>Total de la venta: </strong><span>{BookingInfo.BSalesTotal}</span></p>
                                    <p><strong>Impuestos: </strong><span>{BookingInfo.BSalesTax}</span></p>
                                    <p><strong>Sub Total antes de IVA: </strong><span>{BookingInfo.BSalesAmount}</span></p>
                                    <p><strong>Retención Fiscal ISR : </strong><span>{BookingInfo.BSalesRetentionISR}</span></p>
                                    <p><strong>Retención Fiscal IVA: </strong><span>{BookingInfo.BSalesRetentionIVA}</span></p>
                                    <p><strong>Total Retenciones: </strong><span>{BookingInfo.BSalesTotalAfterRetentions}</span></p>
                                    <p><strong>Comision de sistema de pago: </strong><span>{BookingInfo.BSalesGatewayFee}</span></p>
                                    <p><strong>Iva de Comision sistema de pago: </strong><span>{BookingInfo.BSalesGatewayFeeTax}</span></p>
                                    <p><strong>SubTotal despues de comisiones Gateweay: </strong><span>{BookingInfo.BSalesTotalAfterFee}</span></p>
                                    <p><strong>Comision XP: </strong><span>{BookingInfo.BSalesXPFee}</span></p>
                                    <p><strong>IVA comision XP: </strong><span>{BookingInfo.BSalesXPFeeTax}</span></p>
                                    <p><strong>Subtotal despues xp: </strong><span>{BookingInfo.BSalesTotalAfterXPFee}</span></p>
                                    <p><strong>Total: </strong><span>{BookingInfo.BSalesTotalTotal}</span></p>
                                </div>
                            </div>
                            <div className="col s4 stiky-f">
                                <div className="w-100 card p-20">
                                    <strong>Detalles del pago</strong>
                                    <hr/>
                                    <p><strong>Total a pagar: </strong><span>{pago.SPaymentTotal}</span></p>
                                    <p><strong>Total de la venta: </strong><span>{pago.SPaymentSales}</span></p>
                                    <button className="btn bg-orange w-100" onClick={()=>this.activePopPDF(true)}>Descargar PDF</button>
                                    {/* <a href={pago.SPaymentInvoiceXMLUrl} download className="btn bg-orange w-100" target="_blank" rel="noopener noreferrer">Descargar factura</a> */}
                                </div>
                                <div className="w-100 card p-20 mt-20">
                                    <strong>Ordenes pagadas</strong>
                                    <hr/>
                                    <ul>
                                        {Sales.map((e,index)=>{
                                            return(
                                                <li key={"venta"+index}>
                                                    <Link to={"/author/sales/"+OrdersSeller[e].OrderSellerID}>
                                                        Venta {OrdersSeller[e].OrderSellerID}
                                                    </Link>
                                                </li>  
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default singlePaymant