import React from "react";
// import { Link } from "react-router-dom";
import { messageService } from "../hooks/global";
import Axios from "axios";
import Url, { appid } from "../hooks/url";
import Wrapper from "../hooks/wrapper";
import Functions from "../hooks/functions";
import Redireccionar from "../components/redireccionar";
import ButtonBuy from "../components/methodsBuy/ButtonBuy";

class buyCurses extends React.Component {
    constructor() {
        super();
        this.state = {
            cartItems: {},
            redi: false,
            ruta: '',
            type: ''
        }
        this.getcart = this.getcart.bind(this);
    }
    componentDidMount() {
        let rut = ''
        if (this.props.data.CourseName === 'Xpertclass') {
            rut = '/Class/' + this.props?.data?.CourseName.replace(/ /g, '-') + '?' + this.props?.data?.CourseID
            this.setState({ type: "xpertclass" })
        } else {
            rut = '/Courses/' + this.props?.data?.CourseName.replace(/ /g, '-') + '?' + this.props?.data?.CourseID
            this.setState({ type: "courses" })
        }
        // console.log(this.props.data);
        this.setState({ ruta: rut })

    }
    async getcart() {
        const cart = await Wrapper.getData(Url + 'cart')
        // console.log('carrito');
        // console.log(cart);
        this.setState({ cartItems: cart.data.data })
        this.addCar()
    }
    buyCart(url) {
        const { ruta } = this.state
        let data = {}
        data.item_id = this.props.data.CourseID
        if (ruta === 'Class') {
            data.item_type = 'xpertclass'
        } else {
            data.item_type = 'courses'
        }
        // console.log('?????????');
        // console.log(data);
        Axios.post(Url + url, data, { withCredentials: true })
            .then(res => {
                messageService.sendMessage(res.data.data.cart.items, 'Cproducto');
                if (res.data.data.cart.alert !== undefined) {
                    messageService.sendMessage('repeat', 'typeAlerta');
                    messageService.sendMessage(res.data.data.cart.alert, 'alerta');
                } else {
                    let key = Object.keys(res.data.data.cart.items)
                    key.forEach(e => {
                        if (res.data.data.cart.items[e].item_id === this.props.data.CourseID.toString()) {
                            messageService.sendMessage('buy', 'typeAlerta');
                            messageService.sendMessage(res.data.data.cart.items[e], 'alerta');
                        }
                    });
                }
                Functions.dataLayerAdd(res.data.data.cart)
                Functions.facebookAdd(res.data.data.cart)
            })
            .catch(err => {
                if (err.response !== undefined) {
                    if (err.response.data.error.repeated) {
                        // console.log(err.response);
                        // this.setState({alerta:true})
                        messageService.sendMessage('repeat', 'typeAlerta');
                        messageService.sendMessage('El curso ya esta agregado en el carrito', 'alerta');
                    } else {
                        messageService.sendMessage('repeat', 'typeAlerta');
                        messageService.sendMessage(err.response.data.error, 'alerta');

                    }
                }
            })
    }
    alerta() {
        const { alerta } = this.state
        if (alerta) {
            setTimeout(() => {
                this.setState({ alerta: false })
            }, 3100);
            return (
                <div className="alerta">El producto ya esta agregado</div>
            )
        }
    }
    addCar() {
        const { cartItems } = this.state
        let invitado = localStorage.getItem('invitado')
        // console.log('buy curses');
        // console.log('cartItems');
        // console.log(cartItems);
        if (cartItems === null) {
            console.log('no exite buycurses');
            if (invitado !== null) {
                this.buyCart('cart/' + invitado)
            } else {
                this.buyCart('cart/add')
            }
        } else {
            console.log('exite buycurses');
            this.buyCart('cart/' + cartItems.id)
        }
    }
    async buyDirecta(data) {
        // console.log('directa');
        // console.log('data');
        // console.log(data);
        // let invitado = localStorage.getItem('invitado')
        const user = await Wrapper.getData(Url + 'users')
        if (user.data.data.user_full_name === 'Invitado') {
            window.location.href = 'https://accounts.xpertcad.com/?'+appid
            // if (window.location.host === 'mplace-dev-h.xpertcad.com') {
            //     window.location.href = 'https://accounts.xpertcad.com/?APPID=8'
            // } else {
            //     window.location.href = 'https://accounts.xpertcad.com/?APPID=4'
            // }
        } else {
            const { ruta } = this.state
            let dataD = {}
            dataD.item_id = data
            if (ruta === 'Class') {
                dataD.item_type = 'xpertclass'
            } else {
                dataD.item_type = 'courses'
            }
            localStorage.setItem('idItem', data)
            Axios.post(Url + 'orders/buyNow/' + data, dataD, { withCredentials: true })
                .then(res => {
                    this.addCar()
                    console.log(res);
                    localStorage.setItem('alerta', res.data.data.alert)
                    localStorage.setItem('idO', res.data.data.Order.OrderID)
                    localStorage.setItem('orden', JSON.stringify(res.data.data.Order))
                    localStorage.setItem('metodos', JSON.stringify(res.data.data.PaymentMethods))
                    this.setState({ redi: true })
                    // window.location.href = '/checkout'
                })
                .catch(err => {
                    console.log(err.response);
                })
        }
    }
    redirec() {
        if (this.state.redi) {
            return (
                <Redireccionar link="/checkout" />
            )
        }
    }
    render() {
        const { CourseImage, CourseDescriptionShort, CoursePrice, CourseCurrency, CourseCategory, CourseTags } = this.props.data
        if (CourseTags !== '') {
            var tags = CourseTags
        }
        return (
            <div className={"grey lighten-4 card " + this.props.position}>
                {this.alerta()}
                {this.redirec()}
                <div className="card-image card-ins">
                    <img className="tumbail" src={CourseImage} alt="curso" />
                </div>
                <div className="card-stacked p-10">
                    <div className="card-content scontenido">
                        {CourseCategory !== '' ?
                            <p className="centrar"><i className="material-icons-outlined text-orange">category</i> <span className="ml-10">{CourseCategory}</span></p>
                            : <div></div>}
                        {tags !== undefined && tags.length > 0 ?
                            <div className="mt-10 centrar">
                                <i className="material-icons-outlined text-orange">label</i>
                                {tags !== undefined ?
                                    tags.map((e, index) => {
                                        return (
                                            <span className="ml-5 tag grey lighten-2 grey-text text-darken-1" key={"tag" + index}>{e}</span>
                                        )
                                    })
                                    : <div></div>}
                            </div>
                            : <div></div>}
                    </div>
                    <div className="center-align">{CourseDescriptionShort}</div>
                    <h5 className="center-align mt-20"><strong>{Functions.formatPrice(CoursePrice)} {CourseCurrency}</strong></h5>
                    <div className="card-action mt-20 sp mb-20">
                        {/* <button className="waves-effect btn outline w-100" onClick={this.getcart}>
                            AGREGAR AL CARRITO
                        </button> */}
                        <ButtonBuy text="AGREGAR AL CARRITO" icon="" clas={"waves-effect btn outline w-100"} data={this.props?.data} ruta={this.state.ruta} />
                        <ButtonBuy directa={true} text="COMPRAR AHORA" icon="" clas={"waves-effect btn bg-orange w-100 mt-20"} data={this.props?.data} ruta={this.state.ruta} type={this.state.type} />
                        {/* <div className="waves-effect btn bg-orange w-100 mt-20" onClick={()=>this.buyDirecta(CourseID)}>
                            COMPRAR AHORA
                        </div> */}
                        {/* <Link to="/checkout" className="waves-effect btn bg-orange w-100 mt-20" onClick={()=>this.buyDirecta(CourseID)}>
                            COMPRAR AHORA
                        </Link> */}
                    </div>
                </div>
            </div>
        )
    }
}
export default buyCurses