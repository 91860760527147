import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTimes, faVolumeOff, faClosedCaptioning } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import Url from "../hooks/url";
import Functions from "../hooks/functions";
import { Link } from "react-router-dom";
class product extends React.Component {
    deleteCart(idcart, idproduct) {
        Axios.delete(Url + 'cart/' + idcart + '/' + idproduct, { withCredentials: true })
            .then(res => {
                // console.log(res);
                this.props.getCart(idcart)
                // this.props.getKeys(true)
            })
            .catch(err => {
                this.props.getCart(idcart)
                // this.props.getKeys(true)
                console.log(err.repose);
            })
    }
    close() {
        const { cart_id, id } = this.props.data
        if (this.props.getCart !== undefined) {
            return (
                <div className="col s1 l1">
                    <div className="close red-text" onClick={() => this.deleteCart(cart_id, id)}>
                        <FontAwesomeIcon icon={faTimes} />
                    </div>
                </div>
            )
        }
    }
    render() {
        if (this.props.data !== undefined) {
            let ordenes = this.props.data.item_info
            if (ordenes !== undefined) {
                // console.log("this.props.data 1");
                // console.log(this.props.data);
                var { price, currency, promo_discount, OItemTotalDiscount, OItemTotalTotal, OItemCurrency, item_info, item_id } = this.props.data
            } else {
                // console.log("this.props.data 2");
                // console.log(this.props.data);
                var { OSellerItemCurrency, OSellerItemTotalTotal, OSellerItemInfo, OSellerItemDescription, OSellerItemTotalAmount, OSellerItemTotalTax } = this.props.data
            }
            let objeto = {}
            if (item_info !== undefined) {
                objeto = item_info
                var { item_thumbnail, item_level, item_name, item_category } = objeto
            } else {
                objeto = OSellerItemInfo
                if (OSellerItemInfo !== undefined) {
                    // var { OSItemThumbnail } = objeto
                    var { OSItemThumbnail, OSItemID } = objeto
                }
            }
            // console.log("this.props.data");
            // console.log(this.props);
            return (
                <div className="row valign-wrapper">
                    <div className="col s12 l3">
                        {item_thumbnail !== undefined || OSItemThumbnail !== undefined ?
                            ordenes !== undefined ?
                                <img className="w-100" src={item_thumbnail} alt="class" /> :
                                <img className="w-100" src={OSItemThumbnail} alt="class" /> : ""
                        }
                    </div>
                    <div className="col s6 l5">
                        <div className="data">
                            {/* {console.log(this.props.data)} */}
                            {this.props.data.ref_module === "xpertclass" || this.props.data.OSellerItemRefModule === "xpertclass" ?
                                <Link to={this.props.data.item_id !== undefined ? '/Class/' + this.props.data.description.replace(/ /g, '-') + '?' + item_id : '/Class/' + this.props.data.OSellerItemInfo.OSellerItemDescription.replace(/ /g, '-') + '?' + item_id}>
                                    <strong className="sm">{this.props.data.description.replace(/ /g, '-') + '?' + item_id}{this.props.data.OSellerItemDescription}</strong>
                                </Link> :
                                item_id === undefined ?
                                    this.props.type === "Service" ?
                                        <strong className="sm">{OSellerItemDescription}</strong> :
                                        <Link to={'/Courses/' + OSellerItemDescription.replace(/ /g, '-') + '?' + OSItemID}>
                                            <strong className="sm">{OSellerItemDescription}</strong>
                                        </Link>
                                    :
                                    <Link to={'/Courses/' + item_name.replace(/ /g, '-') + '?' + item_id}>
                                        <strong className="sm">{item_name}</strong>
                                    </Link>
                            }
                            {parseInt(promo_discount) > 0 ?
                                <p className="sm red-text">Descuento de: {promo_discount} {OItemTotalDiscount}</p>
                                : <div></div>}
                            {this.props.data.description === "Xpertclass" ?
                                <div>
                                    <p className="sm mt-10">
                                        <small>
                                            <strong>Categoria:</strong>
                                            <span className="ml-5">Clase</span>
                                        </small>
                                    </p>
                                    <p className="sm">
                                        <small>
                                            <strong>Tipo:</strong>
                                            <span className="ml-5">Xpertclass</span>
                                        </small>
                                    </p>
                                </div> :
                                ordenes !== undefined ?
                                    <div>
                                        <p className="sm mt-10">
                                            <small>
                                                <strong>Nivel del curso:</strong>
                                                <span className="ml-5">{item_level}</span>
                                            </small>
                                        </p>
                                        <p className="sm">
                                            <small>
                                                <strong>Categorias:</strong>
                                                <span className="ml-5">{item_category}</span>
                                            </small>
                                        </p>
                                    </div>
                                    : <div></div>}
                            {/* <FontAwesomeIcon icon={faVolumeOff}/>
                                {'Español'}
                                <FontAwesomeIcon icon={faClosedCaptioning} className="ml-10"/>
                                {'Español, Ingles, Portuges'} */}
                        </div>
                    </div>
                    <div className="col s6 l3 right-align">
                        {this.props.data.description === "Xpertclass" ?
                            <div>
                                <small>Subtotal: {Functions.formatPrice(this.props.data.total_amount)} {OSellerItemCurrency}</small><br />
                                <small>IVA: {Functions.formatPrice(this.props.data.total_tax)} {OSellerItemCurrency}</small><br />
                                <small>Total: {Functions.formatPrice(this.props.data.total_total)} {OSellerItemCurrency}</small>
                            </div> :
                            ordenes !== undefined ?
                                <p className="sm">{OItemTotalTotal === undefined ? Functions.formatPrice(price) : Functions.formatPrice(OItemTotalTotal)} {currency}{OItemCurrency}</p>
                                : <div>
                                    <small>Subtotal: {Functions.formatPrice(OSellerItemTotalAmount)} {OSellerItemCurrency}</small><br />
                                    <small>IVA: {Functions.formatPrice(OSellerItemTotalTax)} {OSellerItemCurrency}</small><br />
                                    <small>Total: {Functions.formatPrice(OSellerItemTotalTotal)} {OSellerItemCurrency}</small>
                                </div>}
                    </div>
                    {this.close()}
                </div>
            )
        } else {
            return (<div></div>)
        }
    }
}
export default product