import React, { useState,useEffect } from 'react';
import imgPaypal from "../../img/paypal-logo-png-transparent.png";
import { messageService } from "../../hooks/global";
import PaypalButtonsService from "../../hooks/paypalButtonsService";
// import React, { useState } from 'react';
// import React from 'react';

const CheckputService = ()=>{
    const [descript, setDescript] = useState('');
    useEffect (() => { 
        messageService.sendMessage('Tienda,/ > Servicios,/service > Pago de servicio','bread')
    }, [])
    return(
        <div className="w-80 m-auto">
            <h5 className="mt-20">Pago de carrito de compras servicios</h5>
            <div className="row">
                <div className="col s12 l8 sp">
                    <div className="card p-20">
                        <h6 className="dark-text"><strong>Forma de Pago</strong></h6>
                        <div className="row">
                            <div className="col s4 brs">
                                <form className="form-check form-check-inline center-align">
                                    <label>
                                        <input type="radio" name="inlineRadioOptions" id="inlineRadio" value="paypal" onClick={()=>setDescript('paypal')}/>
                                        <span className="logoPago mt-40">
                                            <img src={imgPaypal} alt="Paypal"/>
                                        </span>
                                    </label>
                                </form>
                            </div>
                            <div className="col s8">
                                {descript === 'paypal'?
                                <div>
                                    <ol>
                                        <li>Regístrate en PayPal. Abre tu cuenta PayPal gratis y asocia tus tarjetas de crédito.</li>
                                        <li>Paga con PayPal. Busca el botón de PayPal a la hora de pagar y compra fácilmente ingresando tu correo electrónico y contraseña</li>
                                        <li>¡Listo!</li>
                                    </ol>
                                    <div className="opps-footnote">Nota: Tu pago podría tardar hasta 24 hrs. en verse reflejado en la plataforma.</div>
                                </div>
                                :<div></div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col s12 l4">
                    <div className="card p-20">
                        <h6 className="dark-text"><strong>Detalles de la compra</strong></h6>
                        <PaypalButtonsService total='100' idOrden='0'/>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CheckputService