import React from "react";

class plan extends React.Component{
    render(){
        const {title,descrip,color,icon} = this.props
        return(
            <div className={"card "+color+" darken-1 text-center p-20"}>
                <i className="material-icons white-text medium">{icon}</i>
                <div className="card-content white-text">
                  <span className="card-title">{title}</span>
                  <p>{descrip}</p>
                </div>
                <div className="card-action">
                    <a href="#/checkout" className="waves-effect grey darken-2 btn m-auto">Comprar</a>
                </div>
            </div>
        )
    }
}
export default plan