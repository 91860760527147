import React from "react";
// import logo from "../img/XCAD-Store.svg";
import slide0 from "../img/slide0.png";
import slide2 from "../img/slide2.png";
import slide3 from "../img/slide3.png";
import slide4 from "../img/slide4.png";
var slideIndex = 1;
class carrusel extends React.Component {

    plusSlides(n){
        this.showSlides(slideIndex += n);
    }

    currentSlide(n) {
        this.showSlides(slideIndex = n);
    }

    showSlides(n) {
        setTimeout(() => {
            var i;
            var slides = document.getElementsByClassName("mySlides");
            if (n > slides.length) {slideIndex = 1}    
            if (n < 1) {slideIndex = slides.length}
            for (i = 0; i < slides.length; i++) {
                slides[i].style.display = "none";  
            }
            if (slides[slideIndex-1] !== undefined) {
                slides[slideIndex-1].style.display = "block";  
            }
        }, 100);
    }
    render(){
        return (
            <div>
                <div className="slideshow-container white-text text-white">
                    <div className="mySlides fade">
                        <div className="slide slide-img1 centrar">
                            <div className="row">
                                <div className="col s12 l6">
                                    <img src={slide0} alt="slide0" className="img-slider"/>
                                </div>
                                <div className="col s12 l6">
                                    <p className="line-red">01/04</p>
                                    <p>Adquiere habilidades y conocimientos que te llevarán a destacar en la Industria, con los cursos desarrollados por expertos que tenemos para tí.</p>
                                    <p>Aprende a tu propio ritmo, a través de un esquema de aprendizaje en línea, distinto a otras plataformas, y que te permite desarrollarte en cualquier lugar y a cualquier hora.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mySlides fade">
                        <div className="slide slide-img1 centrar">
                            <div className="row">
                                <div className="col s12 l6">
                                    <img src={slide2} alt="slide2" className="img-slider"/>
                                </div>
                                <div className="col s12 l6">
                                    <p className="line-red white-text text-white">02/04</p>
                                    <p>En Xpertcad Store, podrás adquirir los cursos disponibles de industria 4.0, y otros temas industriales especializados, todos, desarrollados por expertos en cada tema.</p>
                                    <p>Si tu interés es utilizar nuestras plataformas para desarrollar cursos, también podrás rentar nuestra plataforma Xpertcad ® Crea directamente.</p>
                                    <p>Desarrolla tu camino al éxito con los materiales disponibles en Xpertcad® Store</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mySlides fade">
                        <div className="slide slide-img1 centrar">
                            <div className="row">
                                <div className="col s12 l6">
                                    <img src={slide3} alt="slide3" className="img-slider"/>
                                </div>
                                <div className="col s12 l6">
                                    <p className="line-red white-text text-white">03/04</p>
                                    <p>Prepárate para afrontar los nuevos retos laborales: Complementa tu formación académica, especialización o simplemente adquiere nuevos conocimientos tecnológicos industriales de nuestra selección de cursos y accede a contenidos únicos  que te permitan sumarle valor a tu perfil profesional</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mySlides fade">
                        <div className="slide slide-img1 centrar">
                            <div className="row">
                                <div className="col s12 l6">
                                    <img src={slide4} alt="slide4" className="img-slider"/>
                                </div>
                                <div className="col s12 l6">
                                    <p className="line-red white-text text-white">04/04</p>
                                    <p>Eres experto en algún software tecnológico industrial, o en procesos industriales o de manufactura, o posiblemente seas un experto enseñando en cualquier rama de STEM (ciencia, tecnología, ingeniería o matemáticas).</p>
                                    <p>Si es así, desarrolla cursos en nuestra plataforma, comparte y comercializa tu conocimiento en nuestra comunidad, se parte de Xpertcad® Crea, regístrate como “Mentor” y comienza ya!</p>
                                </div>
                            </div>
                        </div>
                    </div>                    
                    <button className="prev text-orange" onClick={()=>this.plusSlides(-1)}>&#10094;</button>
                    <button className="next text-orange" onClick={()=>this.plusSlides(1)}>&#10095;</button>
                </div>
                {this.showSlides()}
            </div>
        )
    }
}

export default carrusel