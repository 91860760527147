import React from "react";
import { Link } from "react-router-dom";

export default function Breadcrums(params) {
    // console.log(params);
    return (
        <div className={params.clas}>
            <div className="params">
                <div className="col s12 centrar">
                    {params?.breads?.breadcrums?.map((e,i)=>{
                        return(
                            e.link !== ''?
                            <Link key={"bread"+i} to={e.link} className="breadcrumb fz-14 hpx-20 centrar pad-bread">{e.text}</Link>:
                            <div key={"bread"+i} to={e.link} className="breadcrumb fz-14 hpx-20 centrar pad-bread">{e.text}</div>
                        )
                    })}
                    {/* {params?.breads?.map((e,i)=>{
                        // console.log(e);
                        return(
                            e.link !== ''?
                            <Link key={"bread"+i} to={e.link} className="breadcrumb fz-14 hpx-20 centrar">{e.text}</Link>:
                            <div key={"bread"+i} to={e.link} className="breadcrumb fz-14 hpx-20 centrar">{e.text}</div>
                        )
                    })} */}
                </div>
            </div>
        </div>
    )
}