import React from "react";
import { Link } from "react-router-dom";
import Wrapper from "../hooks/wrapper";
import Url from "../hooks/url";
import Redireccionar from "../components/redireccionar";
class alerta extends React.Component{
    constructor(){
        super();
        this.state={
            id:'',
            redi:false,
            idItem:''
        }
    }
    componentDidMount(){
        let getId = localStorage.getItem('idO')
        let getItem = localStorage.getItem('idItem')
        this.setState({id:getId})
        this.setState({idItem:getItem})
        // console.log(localStorage.getItem('idO'));
    }
    removeAlerOrden(){
        this.props.activeA()
        localStorage.removeItem('idO')
        localStorage.removeItem('alerta')
    }
    async cancelCar(){
        let data = {
            item_id : this.state.idItem
        }
        // console.log(data);
        const cancelar = await Wrapper.deleteData(Url+'orders/'+this.state.id+'/cancel',data)
        // console.log(cancelar);
        if (cancelar.data.data.canceled === 'ok') {
            this.setState({redi:true})
            this.removeAlerOrden()
        }
    }
    redirec(){
        if (this.state.redi) {
            return(
                <Redireccionar link="/"/>
            )
        }
    }
    typeAlert(){
        switch (this.props.type) {
            case 'buy':
                let data = typeof this.props.data
                // console.log('this.props');
                // console.log(this.props);
                const {cant} = this.props
                if (this.props.data !== undefined) {
                    if (data !== 'string' && this.props.data !== undefined && this.props.type === 'buy') {
                        if(this.props.data.description !== "Xpertclass"){
                            var {item_info} = this.props.data
                            var {item_thumbnail} = item_info   
                        }
                        var {description, item_id} = this.props.data
                    }
                    setTimeout(() => {
                        this.props.activeA()
                    }, 3000);
                    if(this.props.data.description === "Xpertclass"){
                        return(
                            <div className="p-20">
                                <h6><Link to={"/Class/"+item_id}>{description}</Link> fue agregado </h6>
                                <p>{cant} clase se agrego en el carrito</p>
                                <div className="centrar mb-10 p-10">
                                    <button className="btn bg-orange" onClick={()=>this.props.activeA()}><span className="fz-9">Seguir comprando</span></button>
                                    <Link to='/Mycar' className="btn outline ml-10 fz-9" onClick={()=>this.props.activeA()}><span>Finalizar compra</span></Link>
                                </div>
                            </div>
                        )
                    }else{
                        return(
                            <div className="row sm">
                                <div className="col s4 sp">
                                    <img src={item_thumbnail} className="w-100 imgBuy" alt="newBuy"/>
                                </div>
                                <div className="col s8">
                                    <p><Link to={"/Courses/"+item_id}>{description}</Link> fue agregado </p>
                                    <p>{cant} cursos en el carrito</p>
                                    <div className="centrar mb-10 p-10">
                                        <button className="btn bg-orange" onClick={()=>this.props.activeA()}><span className="fz-9">Seguir comprando</span></button>
                                        <Link to='/Mycar' className="btn outline ml-10 fz-9" onClick={()=>this.props.activeA()}><span>Finalizar compra</span></Link>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                }
                break;
            case 'repeat':
                setTimeout(() => {
                    this.props.activeA()
                }, 4000);
                return(
                    <div className="text-center p-20">
                        {/* {console.log(typeof this.props.data)} */}
                        <strong>{typeof this.props.data !== 'object'?this.props.data:''}</strong>
                        {/* <h6>hola</h6>
                        <div className="p-10 text-center">
                            <button className="btn bg-orange" onClick={()=>this.props.activeA()}><span className="fz-9">Seguir comprando</span></button>
                            <Link to='/Mycar' className="btn grey darken-4 ml-10 fz-9" onClick={()=>this.props.activeA()}><span>Finalizar compra</span></Link>
                        </div> */}
                    </div>
                )
            case 'alert-notificacion':
                // function alertA(params) {
                //     this.props.activeA()
                // }
                // setTimeout(() => {
                // }, 3000);
                return(
                    <div className="text-center">
                        <i className="material-icons-outlined yellow-text fz-30">warning</i>
                        <div>
                            {/* {console.log(this.props)} */}
                            <strong>{this.props.data}</strong>
                        </div>
                        <button className="btn bg-orange mt-5" onClick={()=>this.props.activeA()}><small>Aceptar</small></button>
                    </div>
                )
            case 'alert-confirm':
                return(
                    <div className="text-center">
                        <div>
                            <strong>{this.props.data}</strong>
                        </div>
                    </div>
                )
            case 'directa':
                return(
                    <div className="text-center">
                        <div>
                            {/* {console.log(this.props)} */}
                            <strong>{this.props.data}</strong>
                        </div>
                        <div className="p-10 text-center">
                            <button className="btn bg-orange" onClick={()=>this.removeAlerOrden()}><span className="fz-9">Continuar</span></button>
                            <button className="btn outline ml-10 fz-9" onClick={()=>this.cancelCar()}><span></span>Cancelar orden</button>
                        </div>
                    </div>
                )
            case 'close':
                // console.log('cerrar');
                this.removeAlerOrden()
                break;
            default:
                break;
        }
    }
    render(){
        return(
            <div className={this.props.type === 'alert-notificacion' || this.props.type === 'alert-confirm'?"row newBuy pop":"row newBuy"}>
                {this.redirec()}
                <div className="col s12 l4"></div>
                <div className="col s6 l4"></div>
                <div className="col s6 l4 sp">
                    <div className={this.props.type !== 'buy'?"card alert-card p-10":'card'}>
                        {this.typeAlert()}
                    </div>
                </div>
            </div>
        )
    }
}

export default alerta