import React from "react";
import imgSpei from "../../img/spei.png";
// import Url from "../../hooks/url";
// import Functions from "../../hooks/functions";
// import Axios from "axios";

class spei extends React.Component{
    constructor(){
        super();
        this.state = {
            dataTransfer:{}
        }
        this.getData = this.getData.bind(this);
    }
    componentDidMount(){
        const {data} = this.props
        // if (Object.keys(data).length > 0) {
        //     this.setState({dataTransfer:data})
        // }else{
        //     this.getData()
        // }
        if (this.props.buySuccess !== undefined) {
            // console.log("compre oxxo");
            // console.log(this.props);
            // this.buyOxxo()
            this.getData()
        } else {
            // console.log("muestro tiket");
            // console.log(this.props);
            this.setState({dataTransfer:data})
            // this.setState({ oxxo: data })
        }
    }
    getData(){
        // const {id} = this.props
        let data = {
            Toc:1,
            PaymentMethodID: 5,
            ReferenceCode: "spei"
        }
        if (this.props.rfc !== undefined) {
            data.InvoiceRequired = 1
            data.RequesterRFC = this.props.rfc
        }
        this.props.buySuccess({}, data)
        // console.log('SPEI');
        // Axios.post(Url+'orders/'+id+'/buying',data,{withCredentials:true})
        // .then(res=>{
        //     Functions.facebookBuy(this.props.dataL)
        //     Functions.dataLayerBuy(this.props.dataL)
        //     this.setState({dataTransfer:res.data.data.spei_stub})
        // })
        // .catch(err=>{
        //     console.log(err.response);
        // })
    }
    render(){
        const {dataTransfer} = this.state
        if (Object.keys(dataTransfer).length === 0) {
            return(
                <div></div>
            )
        }
        else{
            // const {data} = this.props
            if (dataTransfer?.Total !== undefined) { 
                // console.log("dataTransfer?.Total");
                // console.log(dataTransfer);
                var {Total,PaymentReference} = dataTransfer
            }else{
                // console.log("desde spei dataTransfer?.Total");
                // console.log(dataTransfer.spei_stub);
                var {Amount,CLABE} = dataTransfer.spei_stub
            }
            const {Currency} = dataTransfer
            return(
                <div className="card" id="speiP">
                    <div className="ps">
                        <div className="ps-header">
                            <div className="ps-reminder">Ficha digital. No es necesario imprimir.</div>
                            <div className="ps-info">
                                <div className="ps-brand">
                                    <img src={imgSpei} alt="Banorte"/>
                                </div>
                                <div className="ps-amount">
                                    <div className="tile-spei">Monto a pagar</div>
                                    {Total !== undefined?
                                    <h5>$ {Total} <sup>{Currency}</sup></h5>
                                    :<h5>$ {Amount} <sup>{Currency}</sup></h5>}
                                    
                                    <p>Utiliza exactamente esta cantidad al realizar el pago.</p>
                                </div>
                            </div>
                            <div className="ps-reference">
                                <div className="tile-spei">CLABE</div>
                                {Total !== undefined?
                                <div className="code-spei">{PaymentReference}</div>
                                :<div className="code-spei">{CLABE}</div>}
                            </div>
                        </div>
                        <div className="ps-instructions">
                            <div className="tile-spei">Instrucciones</div>
                            <ol>
                                <li>Accede a tu banca en línea.</li>
                                <li>Da de alta la CLABE en esta ficha. <strong>El banco deberá de ser STP</strong>.</li>
                                <li>Realiza la transferencia correspondiente por la cantidad exacta en esta ficha, <strong>de lo contrario se rechazará el cargo</strong>.</li>
                                <li>Al confirmar tu pago, el portal de tu banco generará un comprobante digital. <strong>En el podrás verificar que se haya realizado correctamente.</strong> Conserva este comprobante de pago.</li>
                            </ol>
                            <div className="ps-footnote">Al completar estos pasos recibirás un correo de <strong>Nombre del negocio</strong> confirmando tu pago.</div>
                        </div>
                    </div>	
                </div>
            )
        }
    }
}
export default spei