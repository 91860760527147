import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import ErrorP from "../pages/error";
import Home from "../pages/home";
import Curses from "../pages/curses";
import Instructor from "../pages/instructo";
import Car from "../pages/car";
import Orden from "../pages/orden";
import Servicios from "../pages/servicios/service";
import ListOrdes from "../pages/listOrdes";
import Pruebas from "../pages/pruebas";
import SingleOrden from "../pages/singleOrden";
import Thaks from "../pages/thaks";
import SingleService from "../pages/servicios/singleService";
import CheckoutService from "../pages/servicios/checkoutService";
import Suscription from "../pages/servicios/suscription";
import SingleSuscrption from "../pages/servicios/singleSuscrption";
import AddMethods from "../pages/addMethods";
import ListBuys from "../components/Author/listBuys";
import ListPayment from "../components/Author/listPayment";
import SinglePayment from "../components/Author/singlePayment";
import SingleInvoice from "../components/Author/singleInvoice";
import ListInvoice from "../components/Author/listInvoice";
import Checkin from "../components/checkIn";
import Foter from "../components/foter";
// import Navbar from "../components/navbar";
import Alerta from "../components/alerta";
import Receipt from "../components/Receipt";
// import SingleSales from "../components/Author/singleSales";
import Plans from "../pages/plans/Home";
// import FooterPlan from "../components/Plans/FooterPlan";
// import NavPlan from "../components/Plans/NavPlan";
import Plan from "../pages/plans/plan/Plan";
// import PlanOrGroupPlans from "../pages/plans/PlanOrGroupPlans";
import CheckOutPlan from "../pages/plans/CheckOutPlan";
import Navbar from "../components/main/Navbar";
import GroupPlans from "../pages/plans/GroupPlans.js/GroupPlans";
import ThaksPlan from "../pages/plans/ThaksPlan";
import NotificationBuy from "../components/Others/NotificationBuy";
// import { useStore } from "../store/StoreProvider";
import OrdenPlan from "../components/Plans/OrdenPlan";
import Subscriptions from "../pages/subscriptions/Subscriptions";
import SingleSuscription from "../pages/subscriptions/SingleSuscription";
// import Apis from "../hooks/apis";


function RouterApp(props) {
    // const store = useStore()
    // console.log(store.typeStore);
    function activeA() {
        props.activeA()
    }
    function alerta() {
        if (props.Aalert) {
            return (
                <Alerta data={props.dataA} cant={props.cantP} activeA={activeA} type={props.type} />
            )
        }
    }
    return (
        <BrowserRouter>
            <Navbar />
            {alerta()}
            <NotificationBuy />
            <Switch>
                {/* Redirect */}
                <Redirect from="/cart" to="/"/>
                <Redirect from="/courses" to="/"/>
                <Redirect from="/Courses" to="/"/>
                {/* planes */}
                {/* https://dev2.store.xpertcad.com:3000/plans/checkout/industry */}
                <Route exact path="/" component={Plans} />
                <Route exact path="/plans" component={Plans} />
                <Route exact path="/plans/checkout/:id" component={CheckOutPlan} />
                <Route exact path="/plans/checkout/:group/:id" component={CheckOutPlan} />
                <Route exact path="/plans/thanks" component={ThaksPlan} />
                <Route exact path="/plans/grupo/:Plan" component={GroupPlans} />
                <Route exact path="/plans/:GrouPlan/:Plan" component={Plan} />
                <Route path="/plans/:PlanOrGroupPlans" component={Plan} />
                {/* Cursos */}
                {/*
                <Route exact path="/courses" component={Home} />
                <Route path="/Courses" component={Curses} />
                <Route path="/Class" component={Curses} />
                <Route path="/Instructor" component={Instructor} />
                <Route path="/cart" component={Car} />
                <Route path="/checkout" component={Orden} />
                <Route exact path="/service" component={Servicios} />
                */}
                <Route exact path="/MyOrders" component={ListOrdes} />
                <Route exact path="/MyMethods" component={AddMethods} />
                <Route exact path="/MyOrders/plans/:id" component={OrdenPlan} />
                <Route path="/MyOrders/:id" component={SingleOrden} />
                <Route path="/receipt/:id/:sub" component={Receipt} />
                <Route path="/ThankYou" component={Thaks} />
                <Route path="//check-in//" component={Checkin} />
                <Route path="/service/plan" component={SingleService} />
                <Route exact path="/service/checkout" component={CheckoutService} />
                <Route exact path="/service/suscription" component={Suscription} />
                <Route exact path="/subscriptions" component={Subscriptions} />
                <Route exact path="/subscriptions/:id" component={SingleSuscription} />
                <Route exact path="/service/subscriptions/:id/:type" component={SingleSuscrption} />
                <Route exact path="/author/sales" component={ListBuys} />
                <Route exact path="/author/payment" component={ListPayment} />
                <Route exact path="/author/invoices" component={ListInvoice} />
                {/* <Route exact path="/author/sales/:id" component={SingleSales} /> */}
                <Route exact path="/author/payment/:id" component={SinglePayment} />
                <Route exact path="/author/invoices/:id" component={SingleInvoice} />
                {/* <Route path="/i" component={''} /> */}
                <Route path="*" component={ErrorP} />
            </Switch>
            <Foter />


        </BrowserRouter>
    )
}

export default RouterApp