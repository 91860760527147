import Wrapper from "../hooks/wrapper";
import Url from "../hooks/url";
const funciones = {
    seeMoreText(text){
        let tt = text.split('*').join('<li> *')
        // console.log(tt);
        let content = {}
        content.content = tt.substr(0,80)
        content.more = tt.substr(80)
        return content
    },
    getStatus(data) {
        switch (data) {
            case 'Pending':
                return 'Pendiente'
            case 'Done':
                return 'Emitida'
            case 'New':
                return 'Nuevo'
            default:
                break;
        }
    },
    searchInObject(obj, serch, keysDefault) {
        if (serch !== "") {
            let keys = Object.keys(obj)
            let keysSearch = Object.keys(obj[keys[0]])
            let resultSerch = []
            // console.log(keysSearch);
            keys.forEach((e, i) => {
                if (keysDefault !== undefined) {
                    keysDefault.forEach((a) => {
                        if (obj[e][a].toString().toLowerCase().indexOf(serch.toString().toLowerCase()) > -1) {
                            // console.log(obj[e]);
                            resultSerch.push(obj[e])
                        }
                    })
                } else {
                    keysSearch.forEach((a) => {
                        if (obj[e][a].toString().toLowerCase().indexOf(serch.toString().toLowerCase()) > -1) {
                            // console.log(obj[e]);
                            resultSerch.push(obj[e])
                        }
                    })
                }
            })
            let text = []
            resultSerch.forEach(e => {
                text.push(JSON.stringify(e))
            })
            var unicos = new Set(text)
            text = []
            unicos.forEach(e => {
                text.push(JSON.parse(e))
            });
            return text
        } else {
            return obj
        }
    },
    errorCode(ErrorCode, ErrorType) {
        switch (ErrorCode) {
            case "ExternalAPI":
                return ('Ocurrió un problema, no pudimos realizar la solicitud, vuelve a intentarlo mas tarde')
            case "RequesterRFC":
                // console.log(data.error);
                if (ErrorType === "InvalidValue") {
                    return ('Verifica este dato: El RFC del solicitante no tiene un formato valido')
                } else {
                    return ('Verifica este dato: El RFC del solicitante es requerido')
                }
            case "StreetExtNumber":
                if (ErrorType === "InvalidValue") {
                    return ('Verifica este dato: El numero exterior no tiene un formato valido')
                } else {
                    return ('Verifica este dato: El numero exterior es requerido')
                }
            case "StreetIntNumber":
                if (ErrorType === "InvalidValue") {
                    return ('Verifica este dato: El numero interior no tiene un formato valido')
                } else {
                    return ('Verifica este dato: El numero interior es requerido')
                }
            case "ZipCode":
                return ('Verifica este dato: El codigo postal no tiene un formato valido')
            case "RequesterName":
                return ('Verifica este dato: La razon social es un campo requerido')
            case "CFDIUSE":
                return ('Verifica este dato: El CFDI es un campo requerido')
            case "StreetName":
                return ('Verifica este dato: La calle es un campo requerido')
            default:
                break;
        }
    },
    convertObjInArr(obj) {
        let ob = Object.keys(obj)
        let empty = []
        ob.forEach(e => {
            empty.push(obj[e])
        })
        // console.log(empty);
        return empty
    },
    validForm(idform) {
        // console.log('idform');
        // console.log(idform);
        let form = document.querySelector('#' + idform)
        let obj = {}
        let invalid = []
        let data = {}
        for (let a = 0; a < form.length; a++) {
            if (form[a].required) {
                if (form[a].value === "") {
                    invalid.push(form[a])
                    form[a].style = "border-bottom: solid red 1px;"
                } else {
                    data[form[a].name] = form[a].value
                    form[a].style = "border-bottom: solid green 1px;"
                }
            }
        }
        if (invalid.length > 0) {
            invalid[0].focus()
        }
        obj.invalid = invalid
        obj.data = data
        return obj
    },
    TarjetaCD(dataL, tokenParams, idOrden, keys, rfc, type, action, errorAction) {
        // console.log("dataL: ");
        // console.log( dataL);
        // console.log("tokenParams: ")
        // console.log(tokenParams);
        // console.log("idOrden: " + idOrden);
        // console.log("keys: " + keys);
        // console.log("rfc: " + rfc);
        // console.log("type: " + type);
        let token = {}
        token.card = tokenParams
        window.Conekta.setPublicKey(keys);
        var successResponseHandler = async (res) => {
            var data = {}
            if (rfc !== undefined) {
                data.Toc = 1
                data.PaymentDetails = btoa(tokenParams.number.substr(-4) + "/" + tokenParams.exp_month + "/" + tokenParams.exp_year)
                data.PaymentMethodID = "3"
                data.ReferenceCode = res.id
                data.BillingInfo = rfc
            } else if (rfc !== '') {
                data.Toc = 1
                data.PaymentDetails = btoa(tokenParams.number.substr(-4) + "/" + tokenParams.exp_month + "/" + tokenParams.exp_year)
                data.PaymentMethodID = "3"
                data.ReferenceCode = res.id
                data.BillingInfo = rfc
            } else {
                data.Toc = 1
                data.PaymentDetails = btoa(tokenParams.number.substr(-4) + "/" + tokenParams.exp_month + "/" + tokenParams.exp_year)
                data.PaymentMethodID = "3"
                data.ReferenceCode = res.id
            }
            action(dataL, data)
        }
        var errorResponseHandler = function (err) {
            errorAction(err)
            // console.log(err);
            // let message = document.querySelector('#error-alert')
            // message.innerHTML = err.message_to_purchaser
        };
        window.Conekta.Token.create(token, successResponseHandler, errorResponseHandler);
    },
    bodyDefault(data, busca, caja) {
        let busc = busca
        let condi = data[busc]
        let bus = busca.split('-')
        if (bus.length > 1) {
            condi = data[bus[0]][bus[1]]
        }
        if (condi === undefined) {
            let obj = {}
            caja.forEach(e => {
                obj[e] = ''
            })
            data[busca] = obj
            return data
        } else {
            caja.forEach(e => {
                if (condi[e] === undefined) {
                    condi[e] = ''
                }
            })
            return condi
        }
    },
    dropdown(id) {
        let contenDown = document.querySelector('#' + id)
        contenDown.classList.toggle('d-block')
        contenDown.classList.toggle('opacity-1')
    },
    seeMore(arr, cant) {
        // console.log(cant);
        let arreglo = []
        let data = this.TypeData(arr)
        if (data === "object") {
            arreglo = this.convertObjInArr(arr)
        }else{
            arreglo = arr
        }
        // console.log(arreglo);
        let obj = {}
        let view = []
        let more = []
        for (let a = 0; a < arreglo.length; a++) {
            if (a <= cant) {
                view.push(arreglo[a])
            } else {
                more.push(arreglo[a])
            }
        }
        obj.view = view
        obj.more = more
        return obj
    },
    TypeData(valor) {
        switch (Object.prototype.toString.call(valor)) {
            case '[object Array]':
                return ('array')
            case '[object Object]':
                return ('object')
            default:
        }
    },
    dowloadPDF(data) {
        var MIME_TYPE = "text/pdf";

        var blob = new Blob([data], { type: MIME_TYPE });
        window.location.href = window.URL.createObjectURL(blob);
    },
    descargarArchivo(xmlData, nombreArchivo) {
        var xmltext = atob(xmlData);
        var filename = nombreArchivo;
        var pom = document.createElement('a');
        var bb = new Blob([xmltext], { type: 'text/xml' });

        pom.setAttribute('href', window.URL.createObjectURL(bb));
        pom.setAttribute('download', filename);

        pom.dataset.downloadurl = ['text/xml', pom.download, pom.href].join(':');
        pom.draggable = true;
        pom.classList.add('dragout');

        pom.click();
    },
    renderHTML(data, contenido) {
        setTimeout(() => {
            let div = document.querySelector('.' + data)
            if (div !== null) {
                div.innerHTML = contenido
            }
        }, 100);
    },
    getDescription(data) {
        // console.log(data);
        let keys = Object.keys(data)
        let description = []
        keys.forEach(e => {
            if (data[e].OItemDescription !== undefined) {
                description.push('<li>' + data[e].OItemDescription + '</li>')
            } else {
                description.push('<li>' + data[e].OSellerItemDescription + '</li>')
            }
            // console.log(data[e]);
        })
        return '<ol class="text-left descList">' + (description.toString()).replace(/,/g, '') + '</ol>'
    },
    PassAleatorio(length) {
        var result = '';
        // var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var characters = '0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    },
    formatArray(myArray) {
        let string = []
        let final = []
        myArray.forEach(e => {
            string.push(JSON.stringify(e))
        });
        var myUniqueArray1 = [...new Set(string)];
        myUniqueArray1.forEach(e => {
            final.push(JSON.parse(e))
        });
        return final
    },
    async user() {
        const user = await Wrapper.getData(Url + 'users')
        // console.log('user funcionts');
        // console.log(user);
        if (user !== undefined) {
            return await this.menu(user.data.data.user_id)
        }
        // return await user.data.data
    },
    async menu(id) {
        const menu = await Wrapper.getData(Url + 'users/menu/' + id)
        // console.log('menu');
        if (menu !== undefined) {
            return await menu.data.data

        }
    },
    imprimir(nombreDiv) {
        // console.log(nombreDiv)
        // console.log(document.getElementById(nombreDiv))
        var contenido = document.getElementById(nombreDiv).innerHTML;
        var contenidoOriginal = document.body.innerHTML;
        document.body.innerHTML = contenido;
        window.print();
        document.body.innerHTML = contenidoOriginal;
    },
    fechaFormat(data) {
        let mes = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
        // console.log(data);
        if (data !== undefined) {
            let fecha = data.split(' ')
            let format = fecha[0].split('-')
            // console.log(mes[format[1]-1]);
            let formatF = format[2] + '/' + mes[format[1] - 1] + '/' + format[0]
            return formatF
        }
    },
    formatPrice(data) {
        let cant = new Intl.NumberFormat("en-IN").format(data)
        let miles = cant.split(',')
        if (miles.length > 1) {
            cant = miles.join("")
        }
        return '$' + this.format(parseFloat(cant).toFixed(2))
    },
    format(n, sep, decimals) {
        n = n.toString()
        while (true) {
            var n2 = n.replace(/(\d)(\d{3})($|,|\.)/g, '$1,$2$3')
            if (n === n2) break
            n = n2
        }
        return n
    },
    formatPriceC(data, moneda) {
        let cant = new Intl.NumberFormat("en-IN").format(data)
        let miles = cant.split(',')
        if (miles.length > 1) {
            cant = miles.join("")
        }
        if (moneda === 'MXN' || moneda === 'USD') {
            return '$' + this.format(parseFloat(cant).toFixed(2))
        } else {
            return '€' + this.format(parseFloat(cant).toFixed(2))
        }
    },
    getKeys(data) {
        let keys = Object.keys(data)
        return keys
    },
    soloNumeros() {
        var key = window.event ? window.event.which : window.event.keyCode;
        if (key < 48 || key > 57) {
            window.event.preventDefault();
        }
    },
    FormatRFC(data) {
        let inpu = document.querySelector('#' + data)
        let mayuscula = inpu.value.toUpperCase()
        inpu.value = mayuscula
    },
    getProducts(data) {
        let buy = []
        let products = []
        if (data.items !== undefined) {
            products = Object.keys(data.items)
            for (let a = 0; a < products.length; a++) {
                let obj = {}
                obj.id = data.items[products[a]].item_id
                obj.name = data.items[products[a]].description
                obj.price = data.items[products[a]].price
                obj.quantity = 1
                buy.push(obj)
            }
        } else {
            products = Object.keys(data.OrderItems)
            console.log(products);
            for (let a = 0; a < products.length; a++) {
                console.log(data.OrderItems[products[a]]);
                let obj = {}
                obj.id = data.OrderItems[products[a]].OItemRefID
                obj.name = data.OrderItems[products[a]].OItemDescription
                obj.price = data.OrderItems[products[a]].OItemTotalTotal
                obj.quantity = 1
                buy.push(obj)
            }
        }
        return buy
    },
    dataLayerContent(data) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'View Content',
            'ecommerce': {
                'currencyCode': data?.CourseCurrency,						//constante
                'actionField': {},
                'detail': {
                    'products': [{
                        'id': data?.CourseID,			 		       //productObj.id
                        'name': data?.CourseName, //productObj.name
                        'price': data?.CoursePrice,			                      //productObj.price
                        'quantity': 1 					     //constante
                    }]
                }
            }
        });
    },
    dataLayerAdd(data) {
        window.dataLayer.push({
            'event': 'Add to Cart',
            'ecommerce': {
                'currencyCode': data.currency_code,							//constante
                'add': {
                    'products': this.getProducts(data)
                }
            }
        });
    },
    dataLayerBuy(data) {
        // Envía información de la compra y los productos comprados en la Thank You Page o en el botón de 
        // “Finalizar Compra” si no disponen de éste tipo de página
        window.dataLayer.push({
            'event': 'Purchase',
            'ecommerce': {
                'currencyCode': data.OrderCurrency,						//constante
                'purchase': {
                    'actionField': {
                        'id': data.OrderID,					// ID de la orden/transacción (no de // los productos comprados)
                        'revenue': data.OrderTotalTotal,				// Total de la compra
                    },
                    'products': this.getProducts(data)
                }
            }
        });
        this.local()
    },
    local() {
        localStorage.setItem('dataLayer', JSON.stringify(window.dataLayer))
    },
    localLayer() {
        let data = JSON.parse(localStorage.getItem('dataLayer'))
        window.dataLayer = data
    },
    getkeys(data) {
        let keys = []
        if (data.items !== undefined) {
            keys = Object.keys(data.items)
        }
        return keys
    },
    facebookContent(data) {
        window.fbq('track', 'ViewContent', {
            content_type: 'product', 	// constante
            content_ids: data?.CourseID, 	// variable con el identificador del producto. Puede ser, por ejemplo, el SKU o el product_id. Hay que asegurarse de que este sea el mismo valor que se utiliza en el catálogo de productos (en xml o csv) que también deben enviarnos. 
            value: data?.CoursePrice, 	// variable con el precio del producto. Debe ser el precio que finalmente paga el cliente, sin símbolos y con punto como separador de decimal
            currency: data?.CourseCurrency	// constante
        });
    },
    facebookAdd(data) {
        // console.log(data);
        window.fbq('track', 'AddToCart', {
            content_type: 'product',  // constante
            content_ids: data?.items[this.getkeys(data)[0]]?.item_id, // variable con el identificador del producto. Puede ser, por ejemplo, el SKU o el product id. Hay que asegurarse de que este sea el mismo valor que se utiliza en el catálogo de productos (en xml o csv) que también deben enviarnos. 
            value: data?.items[this.getkeys(data)[0]]?.total_total, // variable con el precio del producto que se agrega al carrito. Debe ser el precio que finalmente paga el cliente, sin símbolos y con punto como separador de decimal. 
            quantity: '1', // variable con la cantidad de productos agregados al carrito
            currency: data?.items[this.getkeys(data)[0]]?.currency // constante
        });
    },
    facebookBuy(data) {
        window.fbq('track', 'Purchase', {
            content_type: 'product', // constante
            content_ids: data.OrderID, // array con los id’s de los productos comprados 
            value: data.OrderTotalTotal, // variable con el valor total de la orden comprada. Debe ser el precio que finalmente paga el cliente, sin símbolos y con punto como separador de decimal. 
            currency: data.OrderCurrency, // constante
        });
    }
}
export default funciones