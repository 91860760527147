import React, { useCallback, useEffect, useState } from "react";
import wrapper from "../hooks/wrapper";
import Functions from "../hooks/functions";
import GetImg from "./getImgs";
import TableSimple from "../components/Others/TableSimple";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
// import PopFactura from "../components/Others/";
import { messageService } from "../hooks/global";
import MenuOptions from "./Others/MenuOptions";
import VisorPDF from "./visorPDF";
import { Link } from "react-router-dom";
//import PopFactura from "./Author/popFactura";
import PopFactura from "./Plans/popFactura"
const headers = ['Descripción','Precio Unitario','IVA','Total']
const Receipt = ()=>{
    const [Orden, setOrden] = useState({})
    const [SubOrden, setSubOrden] = useState({})
    const [IdSubOrden, setIdSubOrden] = useState('')
    const [IdOrden, setIdOrden] = useState('')
    const [ActivePop, setActivePop] = useState(false)
    const [TypePop, SetTypePop] = useState('')
    const handdleData = useCallback(async()=>{
        let orden = window.location.pathname
        orden = orden?.split('/')
        messageService.sendMessage('Tienda,/ > Mis pedidos,/MyOrders > Orden #'+orden[2]+'-'+orden[3],'bread')
        const orders = await wrapper.getDataU('orders/'+orden[2])
        if (orders !== undefined) {
            // console.log(orders.data.data);
            setOrden(orders.data.data)
            setSubOrden(orders.data.data.SubOrders[orden[3]])
            setIdOrden(orden[2])
            setIdSubOrden(orden[3])
        }else{
            window.location.href = '/'
        }
    },[])
    const activePopFactura = ()=>{
        setActivePop(!ActivePop)
    }
    const getDataFacture = async(data,type)=>{
        setActivePop(false)
        if (type === 'add') {
            let envio= {}
            envio.OrderID = Orden?.OrderID
            envio.BillingInfo = data
            messageService.sendMessage('loader','typeAlerta');
            messageService.sendMessage('Procesando datos...','alerta');
            const solicitud = await wrapper.postDataN('orders/'+SubOrden?.OrderSellerID+'/invoice_request',envio)
            if (solicitud.data.error !== undefined) {
                messageService.sendMessage('alert-notificacion','typeAlerta');
                messageService.sendMessage(Functions.getError(solicitud.data.error),'alerta');
                
            }else if (solicitud !== undefined) {
                messageService.sendMessage('alert-succes','typeAlerta');
                messageService.sendMessage('Datos actualizados','alerta')
            }
            handdleData()
        }
    }
    const activePop = () =>{
        // console.log('active');
        setActivePop(false)
    }
    const handdleActiveInvoice = ()=>{
        if (ActivePop) {
            switch (TypePop) {
                case 'invoice':
                    return(
                        <PopFactura activePopFactura={activePopFactura} getDataFacture={getDataFacture} data={Orden?.BillingInfo}/>
                    )
                case 'pdf':
                    return(
                        <VisorPDF pdf={SubOrden?.InvoiceInfo?.OSInvoiceFileUrlPDF} activePop={activePop} id={'1'} title="PDF de factura"/>
                    )
                case 'xml':
                    // console.log('xml');
                    Functions.descargarArchivo(SubOrden?.InvoiceInfo?.OSInvoiceFileUrlXML,'FacturaXML')
                    break;
                default:
                    break;
            }
        }
    }
    const handleGetInvoice = (active,type)=>{
        setActivePop(active) 
        SetTypePop(type)
    }
    useEffect(()=>{
        handdleData()
    },[handdleData])
    const info = [
        {text:'Razón Social:',data:Orden?.BillingInfo?.RequesterName},
        {text:'RFC:',data:Orden?.BillingInfo?.RequesterRFC},
        {text:'Uso de CFDI:',data:Orden?.BillingInfo?.CFDIUSE  },
        {text:'Código Postal:',data:Orden?.BillingInfo?.RequesterAddress.ZipCode}
    ]
    return(
        <div className="w-80 m-auto hminvh-75 row d-block">
            {handdleActiveInvoice()}
            <div className="col s12 l8 stiky-f">
                <div className="card">
                <div className="card-title bg-orange white-text text-white">
                    <div className="row sm">
                        <div className="col s12">
                            <strong>Orden #{Orden?.OrderID}-{IdSubOrden}</strong>
                        </div>
                    </div>
                </div>
                <div className="row pl-20 pr-20">
                    <div className="col s12 l4">
                        <div className="w-60 m-auto">
                            <p><strong>Fecha:</strong> {Functions.fechaFormat(SubOrden?.OrderSellerDate)}</p>
                            <p><strong>Autor:</strong><br/> {SubOrden?.AuthorName} </p>
                        </div>
                    </div>
                    <div className="col s12 l4">
                        <div className="w-80 m-auto">
                            <p><strong>Comprador:</strong><br/> {Orden?.OrderContactFullName}</p>
                            <p><strong>Email:</strong><br/> {Orden?.OrderContactEmail}</p>
                        </div>
                    </div>
                    <div className="col s12 l4">
                        <div className="w-60 m-auto text-right">
                            <p className='logoPago'>
                                <GetImg type={Orden?.PaymentInfo?.PaymentService}/>
                                <GetImg type={Orden?.PaymentInfo?.CardType}/>
                            </p>
                            <p>
                                <strong>Pago: </strong>
                                <span className={Orden?.OrderStatusName+'-inter p-2'}>{Orden?.OrderStatusName}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="w-90 m-auto mb-40">
                    {SubOrden?.OSellerItems !== undefined?
                        <TableSimple 
                        headers={headers} 
                        data={SubOrden?.OSellerItems} 
                        keys={Object.keys(SubOrden?.OSellerItems)} 
                        curren={Orden?.OrderCurrency}
                        info={['OSellerItemDescription','OSellerItemTotalAmount','OSellerItemTotalTax','OSellerItemTotalTotal']}/>:
                        ''
                    }
                    <div className="row">
                        <div className="col s6 m9 text-right">
                            <p><strong>Subtotal </strong></p>
                            {Orden?.OrderCurrency === "MXN" ? <p><strong>IVA </strong></p> : ""}
                            <p><strong>Total </strong></p>
                        </div>
                        <div className="col s6 m3 text-left pl-40">
                            <p>{Functions.formatPriceC(Orden?.OrderTotalAmount,Orden?.OrderCurrency)}</p>

                            {Orden?.OrderCurrency === "MXN" ? <p>{Functions.formatPriceC(Orden?.OrderTotalTax,Orden?.OrderCurrency)}</p> : ""}

                            <p>{Functions.formatPriceC(Orden?.OrderTotalTotal,Orden?.OrderCurrency)}</p>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="col s12 l4 stiky-f">
                <div className="card p-20">
                    {Orden?.BillingID > 0?
                        <div>
                            <div className="text-center">
                                <strong>Información Facturación
                                    {Orden?.OrderStatusName !== 'Done'?
                                    <span className="ml-20 pointer" onClick={()=>{setActivePop(true); SetTypePop('invoice')}}>
                                        <FontAwesomeIcon icon={faEdit}/>
                                    </span>:
                                    ''
                                    }
                                </strong>
                            </div>
                            <hr/>
                            {info.map((e,index)=>
                                <div className="row w-100 sm" key={'info'+index}>
                                    <div className="col s6 m5">
                                        <p className="sm"><strong>{e.text}</strong></p>
                                    </div>
                                    <div className="col s6 m7">
                                        <p className="sm">{e.data}</p>
                                    </div>
                                </div>
                            )}
                        </div>:
                        ''
                    }
                    <div className={'row w-100 mt-20 m-auto'}>
                        <div className="col s6 l12 text-left centrar">
                            {/* {console.log(SubOrden?.InvoiceInfo?.length)} */}
                            {SubOrden?.InvoiceInfo?.AuthorID !== undefined?
                                <div className="row sm w-100">
                                    <div className="col s6 text-center centrar">
                                        <strong>Factura:</strong>
                                    </div>
                                    <div className="col s6 text-center centrar">
                                        <div className="ml-auto">
                                            <strong>Estado:</strong>
                                            <span className={SubOrden?.InvoiceInfo?.OSInvoiceStatusName+'-inter estado pl-5 pr-5'}>{Functions.getStatus(SubOrden?.InvoiceInfo?.OSInvoiceStatusName)}</span>
                                        </div>
                                    </div>
                                </div>:
                                ''
                            }
                        </div>
                        <div className="col s6 l12 centrar p-relative sp">
                            <button className={SubOrden?.InvoiceInfo?.OSInvoiceStatusName === 'Done'?"btn bg-orange mt-20 mr-20 w-70":"btn bg-orange mt-20 w-100"} disabled={SubOrden?.RequestInvoice === undefined} readOnly={SubOrden?.RequestInvoice === undefined} onClick={()=>SubOrden?.RequestInvoice?handleGetInvoice(true,'invoice'):''}>Solicito factura</button>
                            {SubOrden?.InvoiceInfo?.OSInvoiceStatusName === 'Done'?
                                <MenuOptions setActivePop={setActivePop} SetTypePop={SetTypePop}/>:
                                ''
                            }
                        </div>
                        <Link to='/MyOrders' className="ml-auto btn bg-orange white-text text-white w-100 mt-20">Mis pedidos</Link>
                        <Link to={'/MyOrders/'+IdOrden} className="btn bg-orange white-text text-white w-100 mt-20">Regresar a orden</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Receipt