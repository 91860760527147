
const InitialState = {
    // activeLateral:false
    typeStore: '',
    modal: {},
    cart: '0',
    bread: [],
    typeUser: '',
    notification: {},
    confirm: false,
}
const StoreReducer = (state, action) => {
    // console.log(state);
    // console.log(action);
    switch (action.type) {
        case "confirm":
            return {
                ...state,
                confirm: action.data
            }
        case "notification":
            return {
                ...state,
                notification: action.data
            }
        case "typeUser":
            return {
                ...state,
                typeUser: action.data
            }
        case "typeStore":
            return {
                ...state,
                modal: action.data
            }
        case "Modal":
            return {
                ...state,
                modal: action.data
            }
        case "addcart":
            // console.log(action);
            return {
                ...state,
                cart: action.data
            }
        case "addBread":
            // console.log(action);
            return {
                ...state,
                bread: action.data
            }
        default:
            return state;
    }
}
// export {InitialState, types}
export { InitialState }
export default StoreReducer