import React, { useCallback, useEffect, useState } from "react";
import wrapper from "../../hooks/wrapper";
import Functions from "../../hooks/functions";
import GetImg from "../getImgs";
import FichaOxxo from "../Others/FichasDigitales/FichaOxxo";
import BreadcrumsClass from "../../js/BreadcrumsClass";
import { useDispatch } from "../../store/StoreProvider";

export default function OrdenPlan(params) {
    const Dispatch = useDispatch()
    const [DataPlan, setDataPlan] = useState({})
    const [Items, setItems] = useState([])
    const [seePaymentSheet, setseePaymentSheet] = useState(false)
    const getDataPlan = useCallback(async (id) => {
        console.log(id);
        const orders = await wrapper.getDataU('orders/' + id)
        console.log(orders);
        setDataPlan(orders?.data?.data)
        if (orders?.data?.data?.OrderItems !== undefined) {
            setItems(Functions.convertObjInArr(orders?.data?.data?.OrderItems))
        }
        let bread = new BreadcrumsClass()
        bread.addBreadcrum('Tienda', "/")
        bread.addBreadcrum('Mis Ordenes', "/MyOrders")
        bread.addBreadcrum("Orden #"+orders?.data?.data?.OrderID, "")
        Dispatch({ type: 'addBread', data: bread })
    }, [Dispatch])
    useEffect(() => {
        let id = window.location.pathname.split('/')[3]
        getDataPlan(id)
    }, [getDataPlan])
    return (
        <div className="w-80 m-auto hminvh-75">
            <div className="row d-block">
                <div className="col s12 l8 stiky-f">
                    <div className="row">
                        <div className="col s6">
                            <h5 className="mt-20">Orden #{DataPlan?.OrderID}
                            </h5>
                        </div>
                        <div className="col s6 text-right mt-12">
                            <div>
                                <div>
                                    <span className={DataPlan?.OrderStatusName + "-inter p-2"}>{DataPlan?.OrderStatusName}
                                    </span>
                                </div>
                                <div>
                                    <small>Realizada el: {Functions.fechaFormat(DataPlan?.OrderDate)}
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card text-center p-20">
                        <div>
                            <div className="row bg-orange white-text text-white p-20">
                                <div className="col s12 l12 text-left centrar">
                                    <h6 className="white-text text-white">Suscripción a Plan
                                    </h6>
                                </div>
                            </div>
                            {Items.map((e, i) => {
                                return (
                                    <div className="row" key={"items" + i}>
                                        <div className="col s12 m12 l6 centrar">{e?.OItemDescription}</div>
                                        <div className="col s12 m12 l6 rigth">
                                            <p><strong>Subtotal:</strong>${e?.OItemTotalAmount} {e?.OItemCurrency}</p>
                                            <p><strong>Iva:</strong>${e?.OItemTotalTax} {e?.OItemCurrency}</p>
                                            <p><strong>Total:</strong>${e?.OItemTotalTotal} {e?.OItemCurrency}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    {seePaymentSheet ? <FichaOxxo data={DataPlan?.PaymentInfo?.stub} /> : ''}
                </div>
                <div className="col s12 l4 stiky-f">
                    <div className="card text-center p-20">
                        <strong>Detalles del pedido
                        </strong>
                        <hr />
                        <div className="row">
                            <div className="col s12 text-right">
                                <span className="logoPago mt-40">
                                    <GetImg class="sm" type={DataPlan?.OrderPaymentMethod} />
                                </span>
                            </div>
                            <div className="col s12 text-left">
                                <div>
                                    <strong>Total:
                                    </strong>
                                    <br />
                                    <span>${DataPlan?.OrderTotalTotal} {DataPlan?.OrderCurrency}
                                    </span>
                                    <br />
                                    <div className="mt-20">
                                        <strong>Fecha:
                                        </strong>
                                    </div>
                                    <span>{Functions.fechaFormat(DataPlan?.OrderDate)}
                                    </span>
                                    <br />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div />
                            <a className="btn bg-orange mt-20 w-100" href="/MyOrders">Regresar
                            </a>
                            {DataPlan?.PaymentInfo?.stub?.PaymentMethod === "OxxoPay" || DataPlan?.PaymentInfo?.stub?.PaymentMethod === "Spei" ?
                                <div>
                                    <button className="btn bg-orange mt-20 w-100" onClick={() => setseePaymentSheet(!seePaymentSheet)}>{seePaymentSheet ? 'Ocultar' : 'Visualizar'} Ficha de pago
                                    </button>
                                    {seePaymentSheet?
                                    <button className="btn bg-orange mt-20 w-100" onClick={() => Functions.imprimir("oxxoP")}> Imprimir Ficha de pago
                                    </button>
                                    :''}
                                </div>
                                : ''}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}