import React, {useEffect, useState} from "react";
import { messageService } from "../../hooks/global";
import Wrapper from "../../hooks/wrapper";
import Url from "../../hooks/url";
import Functions from "../../hooks/functions";
import Loader from "../../components/loader";

const SingleSuscrption = () =>{
    const [dataS, setDataS] = useState({});

    useEffect (() => { 
        let url = window.location.pathname;
        url = url.split('/');
        messageService.sendMessage('Tienda,/ > Servicios,/service > Suscripcion,/service/suscription > '+url[4],'bread');
        getData(url[3]);
    },[])
    async function getData(id) {
        const suscrp = await Wrapper.getData(Url+'subscriptions/'+id)
        setDataS(suscrp.data.data)
        // const orders_sellers = await Wrapper.getData(Url+'orders/'+suscrp.data.data.OriginOrderID)
        // console.log(orders_sellers);
    }
    if (Object.keys(dataS).length>0) {
        return(
            <div className="w-80 m-auto pt-40 pb-20">
                <div className="row">
                    <div className="col s12 l6">
                        <div>
                            <strong>Fecha de inicio: </strong>
                            <span>{Functions.fechaFormat(dataS.SSubStartDate)}</span>
                        </div>
                        <div>
                            <strong>Fecha de ultima renovacion: </strong>
                            <span>{Functions.fechaFormat(dataS.SSubExpirationDate)}</span>
                        </div>
                        <div>
                            <strong>Fecha de siguiente renovacion: </strong>
                            <span>{Functions.fechaFormat(dataS.SSubNextRenew)}</span>
                        </div>
                    </div>
                    <div className="col s12 l6">
                        <div>
                            <strong>Precio mensual: </strong>
                            <span>{dataS.ServiceInfo.PlanInfo.PlanPrice} {dataS.ServiceInfo.PlanInfo.PlanCurrency}</span>
                        </div>
                        <small>
                            {dataS.ServiceInfo.ServiceDescription}
                        </small>
                    </div>
                </div>
            </div>
        )
    }else{
        return(
            <Loader/>
        )
    }
}

export default SingleSuscrption