import React, { useCallback, useEffect, useState } from "react";
import M from "materialize-css";
import GetImg from "../getImgs";
import FormCD from "../methodsBuy/formCD";
import LeyenPaypal from "../Others/checkout/LeyendPaypal";
import LeyenOxxo from "../Others/checkout/LeyenOxxo";
import LeyenSpei from "../Others/checkout/LeyenSpei";
import CardComponent from "../Others/CardComponet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import wrapper from "../../hooks/wrapper";
import Functions from "../../hooks/functions";
import { useHistory } from "react-router";
import FichaOxxo from "../Others/FichasDigitales/FichaOxxo";
import Loader from "../loader";
import PopFactura from "./popFactura";
import ModalTos from "./tos";
import LinkRef from "../Others/LinkRef";
import { useDispatch, useStore } from "../../store/StoreProvider";
import CardDefault from "../methodsBuy/CardDefault";
import {appid} from "../../hooks/url";

export default function MethodsPay(params) {
    const Store = useStore()
    const Dispatch = useDispatch()
    var history = useHistory()
    const [ServiceIdPlan, setServiceIdPlan] = useState('')
    const [Type, setType] = useState(3)
    const [DataFichaPago, setDataFichaPago] = useState('')
    const [TC, setTC] = useState(false)
    const [DataPlan, setDataPlan] = useState(false)
    const [ActiveLoader, setActiveLoader] = useState(false)
    const [ActiveModal, setActiveModal] = useState(false)
    const [TypeModal, setTypeModal] = useState(false)
    const [ActiveFeature, setActiveFeature] = useState(false)
    const [DataRFC, setDataRFC] = useState('')
    const [CarDefault, setCarDefault] = useState(false)
    const [CarDefaultInfo, setCarDefaultInfo] = useState({})
    const [PaymentMethods, setPaymentMethods] = useState([])
    // const [Display, setDisplay] = useState("none")
    const getDataFacture = (dataModal) => {
        // console.log(dataModal);
        setDataRFC(dataModal)
        setActiveModal(false)
        setActiveFeature(true)
    }
    const handdleActiveInvoice = () => {
        if (ActiveModal) {
            switch (TypeModal) {
                case 'factura':
                    if (ActiveFeature) {
                        return (
                            <PopFactura setActiveModal={setActiveModal} setActiveFeature={setActiveFeature} getDataFacture={getDataFacture} data={DataRFC} />
                        )
                    } else {
                        return (
                            <div></div>
                        )
                    }
                case 'tos':

                    return (
                        <ModalTos setActiveModal={setActiveModal} setActiveFeature={setActiveFeature} getDataFacture={getDataFacture} data={DataRFC} />
                    )

                // case 'pdf':
                //     return(
                //         <VisorPDF pdf={SubOrden?.InvoiceInfo?.OSInvoiceFileUrlPDF} activePop={activePop} id={'1'} title="PDF de factura"/>
                //     )
                // case 'xml':
                //     // console.log('xml');
                //     Functions.descargarArchivo(SubOrden?.InvoiceInfo?.OSInvoiceFileUrlXML,'FacturaXML')
                //     break;
                default:
                    break;
            }
        }
    }
    const handdleBuy = async () => {
        // console.log("handdleBuy");
        // setActiveLoader(true)
        let dataBuy = {}
        let obj = {}
        dataBuy.ServiceID = ServiceIdPlan
        dataBuy.Toc = TC ? 1 : 0
        dataBuy.PaymentMethodID = Type
        dataBuy.PlanType = DataPlan.PlanType
        dataBuy.PlanGatewayID = DataPlan?.PaymentMethods[Type].PlanGatewayID
        // console.log(DataPlan?.PaymentMethods[Type].PlanGatewayID);
        if (Object.keys(DataRFC).length > 0 && ActiveFeature) {
            dataBuy.BillingInfo = DataRFC
        }
        if (Type === 3) {
            if (!CarDefault) {
                let valid = Functions.validForm("tarjeta")
                // console.log(valid);
                if (valid.invalid.length === 0) {
                    // console.log("pago de tarjeta");
                    obj = valid.data
                    dataBuy.PaymentDetails = obj
                    Functions.TarjetaCD(dataBuy, obj, DataPlan?.PlanID, DataPlan?.PaymentMethods[3]?.public_key, DataRFC, 'planes', sendBuy, errorTc)
                    // sendBuy(dataBuy)
                } else {
                    // console.log(valid.invalid[0].parentElement.innerText);
                    Dispatch({ type: 'notification', data: { type: "message", open: true, vertical: 'top', horizontal: 'right', data: { name: "El campo " + valid.invalid[0].parentElement.innerText + " es obligatorio", type: "warning" } } })
                }
            } else {
                // console.log("compra con tarjeta default");
                // console.log(CarDefaultInfo);
                dataBuy.PaymentDetails = btoa(CarDefaultInfo.last4 + "/" + CarDefaultInfo.exp_month + "/" + CarDefaultInfo.exp_year)
                dataBuy.PaymentMethodDefault = true
                sendBuy(dataBuy)
            }
        } else {
            // console.log("pago de otros servicios");
            // console.log("pago de otros servicios");
            obj = DataPlan
            // dataBuy.PaymentDetails = obj
            sendBuy(dataBuy)
        }
    }
    const errorTc = (dataerror) => {
        // console.log("no se compro");
        // console.log(dataerror);
        Dispatch({ type: 'notification', data: { type: "message", open: true, vertical: 'top', horizontal: 'right', data: { name: dataerror?.message_to_purchaser, type: "warning" } } })
        setActiveLoader(false)
    }
    const sendBuy = async (databuy, dataconekta) => {
        setActiveLoader(true)
        // console.log("se compro");
        // console.log("sendBuy");
        // console.log(dataconekta);

        let data = databuy
        if (dataconekta !== undefined) {
            data.PaymentDetails = btoa(databuy?.PaymentDetails?.number.substr(-4) + "/" + databuy?.PaymentDetails?.exp_month + "/" + databuy?.PaymentDetails?.exp_year)
            data.ReferenceCode = dataconekta.ReferenceCode
        }
        // console.log(data);
        // console.log(DataPlan?.PlanID);
        let setBuy = await wrapper.postDataN('orders/subscription/' + DataPlan?.PlanID, data)
        // console.log(setBuy);
        // // console.log(setBuy?.data?.data);
        if (setBuy?.status === 200) {
            switch (Type) {
                case 2:
                    setDataFichaPago(setBuy.data.data.stub)
                    break;
                default:
                    history.push('/plans/thanks')
                    break;
            }
        } else {
            // console.log(setBuy);

            let error = ""
            if (setBuy?.data?.error?.message !== undefined) {
                error = setBuy?.data?.error?.message
            } else if (setBuy?.data?.error?.ErrorCode) {
                setActiveFeature(true);
                setTypeModal('factura');
                setActiveModal(true)
                error = Functions.errorCode(setBuy?.data?.error?.ErrorCode)
            } else {
                error = setBuy?.data?.error
            }
            Dispatch({ type: 'notification', data: { type: "message", open: true, vertical: 'top', horizontal: 'right', data: { name: error, type: "warning" } } })
        }
        setActiveLoader(false)
    }

    const getPlan = useCallback(async (id) => {
        let getDataPlan = await wrapper.getDataU('plans/' + id)
        // console.log(getDataPlan?.data?.data);
        // console.log(getDataPlan?.data?.data.PaymentMethods);
        console.log('plans/' + id, getDataPlan.data);
        if (getDataPlan?.data?.PaymentMethods?.PaymentMethods[3].default !== undefined) {
            // console.log("entre");
            setCarDefaultInfo(getDataPlan?.data?.data?.PaymentMethods[3].default)
            setCarDefault(true)
        }
        // setPaymentMethodDefault({ name: "Hector Eduardo González Luna", number: "4242", exp_month: "11", exp_year: "2021", cvc: "123" })
        // setDefaulTarget(true)
        setActiveLoader(false)
        if (getDataPlan?.data?.data?.PaymentMethods !== undefined) {
            var metodos = Functions.convertObjInArr(getDataPlan?.data?.data?.PaymentMethods)
            setType(metodos[0].id)
        }
        // console.log(metodos);
        setPaymentMethods(metodos)
        setDataPlan(getDataPlan?.data?.data)
        setServiceIdPlan(getDataPlan?.data?.data?.ServiceID)
        if (getDataPlan?.data?.data?.BillingInfo) {
            setDataRFC(getDataPlan?.data?.data?.BillingInfo)
        }
        // setTimeout(() => {
        //     setDisplay("block")
        // }, 1000);
    }, [])
    const getContent = (id) => {
        // console.log("id");
        // console.log(id);
        // console.log("Type");
        // console.log(Type);
        switch (id) {
            case 1:
                if (Type === 5) {
                    return (<LeyenPaypal />)
                } else {
                    return (<></>)
                }
            case 2:
                if (Type === 2) {
                    return (<LeyenOxxo />)
                } else {
                    return (<></>)
                }
            case 3:
                if (Type === 3) {
                    return (
                        Object.keys(CarDefaultInfo).length > 0 ?
                            !CarDefault ?
                                <div>
                                    <FormCD />
                                    <h6 onClick={() => setCarDefault(true)} className="blue-text pointer">Usar tarjeta guardada</h6>
                                </div> :
                                <div>
                                    <CardDefault data={CarDefaultInfo} cambio={setCarDefault} />
                                </div>
                            : <FormCD />
                    )
                } else {
                    return (<></>)
                }
            case 5:
                if (Type === 5) {
                    return (<LeyenSpei />)
                } else {
                    return (<></>)
                }
            default:
                break;
        }
    }
    const sesion = () => {
        if (Store?.typeUser?.user_full_name === "Invitado")
        {
            window.location.href = `https://accounts.xpertcad.com/?${appid}&ref=${(window.location.pathname).replace(/^\/|\/$/g, '')}`
        }
    }

    useEffect(() => {
        let idPlan = window.location.pathname.split('/')

        if (idPlan.length > 4) {
            idPlan = idPlan.slice(3, 5).join('/')
        } else {
            idPlan = idPlan[3]
        }
        // console.log(idPlan);
        let tabs = document.querySelector(".tabs")
        M.Tabs.init(tabs);
        getPlan(idPlan)
    }, [getPlan])
    return (
        <div className="">
            {sesion()}
            {ActiveLoader ?
                <div>
                    <Loader />
                    {console.log("Metodos loader")}
                </div> : ""
            }
            <div className="row container">
                {handdleActiveInvoice()}
                {/* {addDataForm()} */}
                <div className="col s12 m12 l7">
                    {Object.keys(DataFichaPago).length > 0 ?
                        <FichaOxxo data={DataFichaPago} /> :
                        <div className="row">
                            <div className="col s12 mb-20">
                                <ul className="tabs transparent">
                                    {PaymentMethods.length > 0 ?
                                        PaymentMethods.map((e, i) => {
                                            // {setType(e.id)}
                                            return (
                                                <li key={"metodos" + i} className="tab col s3" onClick={() => setType(e.id)}>
                                                    <a href={"#test" + e.id} className={i === 0 ? "active logoPago" : "logoPago"}><GetImg class="sm" type={e.id} /></a>
                                                </li>
                                            )
                                        }) :
                                        <li className="tab col s3" onClick={() => setType(3)}><a href="#test1" className="active logoPago"><GetImg class="sm" type="3" /></a></li>
                                    }

                                </ul>
                            </div>
                            {PaymentMethods.length > 0 ?
                                PaymentMethods.map((e, i) => {
                                    return (
                                        // <div style={i === 0?{display:"block"}:{display:"none"}} key={"content"+i} id={"test" + e.id} className={i === 0?"col s12 active":"col s12"}>
                                        <div key={"content" + i} id={"test" + e.id} className={i === 0 ? "content-tab col s12 active" : "content-tab col s12"}>
                                            {getContent(e.id)}
                                        </div>
                                    )
                                }) :
                                <div id="test1" className="col s12"><LeyenPaypal /></div>
                            }
                            {/* <div id="test3" className="col s12">

                                {Object.keys(CarDefaultInfo).length > 0 ?
                                    !CarDefault ?
                                        <div>
                                            <FormCD />
                                            <h6 onClick={() => setCarDefault(true)} className="blue-text pointer">Usar tarjeta guardada</h6>
                                        </div> :
                                        <div>
                                            <CardDefault data={CarDefaultInfo} cambio={setCarDefault} />
                                        </div>
                                    : <FormCD />
                                }
                            </div>
                            <div id="test1" className="col s12"><LeyenPaypal /></div>
                            <div id="test2" className="col s12 pb-40"><LeyenOxxo /></div>
                            <div id="test5" className="col s12"><LeyenSpei /></div> */}

                        </div>
                    }

                </div>
                <div className="col s12 m12 l5">
                    <CardComponent>
                        <div className="text-center mb-20">
                            <h6><strong className="text-grey">Plan Seleccionado</strong></h6>
                            <h5><strong className="dark-text">{DataPlan?.PlanName}</strong></h5>
                            <h6><strong className="dark-text">${DataPlan?.PlanPricePublic} {DataPlan?.PlanPriceCurrency} / al mes </strong></h6>
                        </div>
                        <hr className="sm" />
                        {DataPlan?.PlanDescriptionShort !== "" ?
                            <div>
                                <p><strong>Descripción</strong></p>
                                <p>{DataPlan?.PlanDescriptionShort}</p>
                            </div>
                            : ""}
                    </CardComponent>
                    {/* <CardComponent clas="horizontal" media={<img className="sm" src="https://picsum.photos/300/200?random=1" alt="me" />} clasImg="me-circle">
                        <h6><strong className="dark-text">Conoce <span className="text-orangeMe">Xpertme</span></strong></h6>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus faucibus nisl pulvinar, egestas felis sed, pellentesque justo. Nam at mauris arcu.</p>
                    </CardComponent> */}
                    <div className="card p-20">
                        <div className="ml-0 d-grid mt-20">
                            <div className="centrar">
                                <label>
                                    <input
                                        name="terminos"
                                        type="checkbox"
                                        defaultChecked={TC}
                                        // checked={terminos}
                                        onChange={(e) => setTC(e.target.checked)}
                                        required
                                    />
                                    <span> </span>
                                </label>
                                <span>Acepto los <span className="text-orange pointer terminos " title="Click para ver terminos y condiciones" onClick={() => { setTypeModal('tos'); setActiveModal(true) }}> términos de servicio</span> y <a className="text-orange" href="https://xpertcad.com/aviso-de-privacidad.html" target="_blank">politicas de privacidad</a>
                                </span>
                            </div>
                            <label className=''>
                                <input
                                    name="facturaO"
                                    type="checkbox"
                                    checked={ActiveFeature}
                                    onChange={() => { setActiveFeature(!ActiveFeature); setTypeModal('factura'); setActiveModal(true) }}
                                />
                                {
                                    DataPlan?.PlanPriceCurrency === "MXN" ? <span className="black-text text-black">Necesito factura</span> : ""

                                }

                            </label>
                            <button readOnly={!TC} disabled={!TC} onClick={() => handdleBuy()} className="btn bg-orange mt-10 w-100 m-auto">Pagar ahora</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s6 ">
                            <small className="text-grey">
                                <FontAwesomeIcon icon={faUsers} /> +100K de usuarios capacitándose
                            </small>
                        </div>
                        <div className="col s6">
                            <small className="text-grey">
                                <i className="tiny material-icons">chrome_reader_mode</i> 100+ Expertos impartiendo clases
                            </small>
                        </div>
                    </div>
                    {Object.keys(DataFichaPago).length > 0 ?
                        <div className="mt-20 d-grid">
                            <LinkRef to="/plans">
                                <button className="btn bg-orange w-100">Ver más planes</button>
                            </LinkRef>
                            <LinkRef to="/MyOrders">
                                <button className="mt-20 btn bg-orange w-100" >Ver mis pedidos</button>
                            </LinkRef>
                            <LinkRef to="/subscriptions">
                                <button className="mt-20 btn bg-orange w-100" >Ver mis suscripciones</button>
                            </LinkRef>
                        </div> : ""
                    }
                </div>

            </div>
        </div>
    )
}