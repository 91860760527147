import React from "react";
import Paginador from "./paginador";
import Loader from "./loader";
import Functions from "../hooks/functions";

const headerss = {
    head1:[
        { id: 'OrderID', label: 'ID', minWidth: 100, align: 'center'},
        { id: 'OrderDate', label: 'Fecha', minWidth: 100, align: 'center'},
        // { id: 'OrderType', label: 'Tipo de plan', minWidth: 100, align: 'center'},
        { id: 'Descripcion', label: 'Descripción', minWidth: 200, align: 'center'},
        { id: 'OrderTotalTotal', label: 'Total', minWidth: 100, align: 'center'},
        { id: 'OrderStatusName', label: 'Estado', minWidth: 100, align: 'center'},
    ]
}

class table extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            dataG:[]
        }
        this.dataDescription = this.dataDescription.bind(this);
    }
    componentDidMount(){
        this.getKeys()
    }
    ordenarNumeros(valor1, valor2) {
        return valor2 - valor1;
    }
    getKeys() {
        // const {headers,keyList,data,changeVista,vista} = props
        // console.log(this.props);
        const {data} = this.props
        let llaves = []
        let keys = Object.keys(data)
        keys = keys.sort(this.ordenarNumeros)
        for (let a = 0; a < keys.length; a++) {
            // console.log(data[keys[a]])
            let descrip = Functions.getDescription(data[keys[a]].OrderItems)
            data[keys[a]].Descripcion = descrip
            // console.log(descrip);
            // console.log(data[keys[a]]);
            llaves.push(data[keys[a]])
        }
        this.setState({dataG:llaves})
    }
    dataDescription(vista,id){
        // console.log(this.props);
        this.props.changeVista(vista,id)
    }
    render(){
        const {dataG} = this.state
        // console.log(dataG);
        if (dataG.length === 0) {
            return(
                <Loader/>
            )
        }else{
            // console.log(this.state.dataG);
            return(
                <div>
                    <Paginador headers={headerss.head1} body={dataG} link="OrderID" dataDescription={this.dataDescription} redirect="MyOrders" list="orders"/>
                </div>
            )
        }
    }
}
export default table