import React, { memo, useEffect, useState } from "react";
import Functions from "../../../hooks/functions";
import Wrapper from "../../../hooks/wrapper";

import Oxxo from "../../methodsBuy/oxxo";
import Spei from "../../methodsBuy/spei";
import PayMethod from "../../methodsBuy/payMethod";
import VisorPDF from "../../visorPDF";
import PaypalButtons from "../../../hooks/paypalButtons";
import { messageService } from "../../../hooks/global";
import { Link } from "react-router-dom";
import { useDispatch } from "../../../store/StoreProvider";
import Loader from "../../loader";
import { useHistory } from "react-router-dom";

const GetContent = memo((props) => {
    const Dispatch = useDispatch()
    const history = useHistory()
    // const [terminos, setTerminos] = useState(false)
    const [dataPDF, setDataPDF] = useState({})
    const [activePDF, setActivePDF] = useState(false)
    const [ALoader, setALoader] = useState(false)
    const [FinishBuy, setFinishBuy] = useState({})
    // const [CardDefault, setCardDefault] = useState(false)
    const [CardDefaultInfo, setCardDefaultInfo] = useState({})
    const { selectMB, checkFactura, data } = props
    const recarga = () => {
        window.scroll(0, 0)
        if (props.inicial !== undefined) {
            props.setCompra(false)
            props.inicial()
        }
    }

    const messages = (data) => {
        props.setCompra(false)
        props.activePopFactura(true, true)
        messageService.sendMessage('alert-notificacion', 'typeAlerta');
        messageService.sendMessage(Functions.getError(data), 'alerta');
    }
    const errorTc = (dataerror) => {
        // console.log("no se compro");
        // console.log(dataerror);
        Dispatch({ type: 'notification', data: { type: "message", open: true, vertical: 'top', horizontal: 'right', data: { name: dataerror?.message_to_purchaser, type: "warning" } } })
        // setActiveLoader(false)
        setALoader(false)
    }
    const buySuccess = async (databuy, dataconekta) => {
        // console.log("buySuccess");
        // console.log(props);
        // console.log(props.buy);
        var tarjetaCD = {}
        if (props.buy !== undefined) {
            tarjetaCD = await Wrapper.postDataN('orders/paySubscription/' + props?.data?.OrderID, dataconekta)
        } else {
            tarjetaCD = await Wrapper.postDataN('orders/' + props?.data?.OrderID + '/buying', dataconekta)
        }
        // /paySubscription/{order_id}
        // console.log(tarjetaCD);
        if (tarjetaCD.status === 200) {
            if(props.buy !== undefined){
                Dispatch({ type: 'notification', data: { type: "message", open: true, vertical: 'top', horizontal: 'right', data: { name: 'Metodo de pago actualizado correctamente', type: "success" } } })
                history.push("/subscriptions/"+tarjetaCD.data.data.SSubID)
            }else{
                if (props.selectMB === 3) {
                    history.push("/ThankYou")
                } else {
                    setFinishBuy(tarjetaCD?.data?.data)
                    if (props.inicial !== undefined) {
                        props.inicial()
                    }
                }
                props.setSuccesBuy(true)
            }
        } else {
            // console.log(tarjetaCD);
            let error = ""
            if (tarjetaCD?.data?.error?.message !== undefined) {
                error = tarjetaCD?.data?.error?.message
            } else if (tarjetaCD?.data?.error?.ErrorCode) {
                // setActiveFeature(true); 
                // setActiveModal(true)
                props.activePopFactura(true, true)
                error = Functions.errorCode(tarjetaCD?.data?.error?.ErrorCode)
            } else {
                error = tarjetaCD?.data?.error
            }

            // console.log(error);
            Dispatch({ type: 'notification', data: { type: "message", open: true, vertical: 'top', horizontal: 'right', data: { name: error, type: "warning" } } })
            props.setCompra(false)
            props.setSuccesBuy(false)
            setALoader(false)
            props.inicial()

        }
        setALoader(false)
    }
    const handleCompra = (e) => {
        e.preventDefault()
        setALoader(true)
        // console.log("props.selectMB");
        // console.log(props.selectMB);
        if (props.selectMB !== 3) {
            props.setCompra(true)
        } else {
            let message = document.querySelector('#error-alert')
            message.innerHTML = ''
            let tarjeta = document.querySelector('#tarjeta')
            let data = {}
            // let data = btoa(tarjeta[1].value.substr(-4) + "/" + tarjeta[2].value + "/" + tarjeta[3].value)
            // console.log("getContent>handleCompra>CardDefault");
            // console.log(props.Default);
            if (!props.Default) {
                // console.log("no utilizar card default");
                for (let a = 0; a < tarjeta.length; a++) {
                    data[tarjeta[a].name] = tarjeta[a].value
                }
                if (checkFactura) {
                    Functions.TarjetaCD(props.data, data, props.data.OrderID, props.data.PaymentMethods[3].public_key, props.BillingInfo, "", buySuccess, errorTc)
                    // window.TarjetaCD(props.data,data,props.data.OrderID,props.data.PaymentMethods[3].public_key,props.BillingInfo)
                } else {
                    Functions.TarjetaCD(props.data, data, props.data.OrderID, props.data.PaymentMethods[3].public_key, "", "", buySuccess, errorTc)
                    // window.TarjetaCD(props.data,data,props.data.OrderID,props.data.PaymentMethods[3].public_key)
                }
            } else {
                // console.log("utilizar card default");
                data.Toc = 1
                let defa = CardDefaultInfo
                data.PaymentDetails = btoa(defa?.last4 + "/" + defa?.exp_month + "/" + defa?.exp_year)
                data.PaymentMethodID = "3"
                data.PaymentMethodDefault = true
                if (checkFactura) {
                    data.BillingInfo = props.BillingInfo
                }
                buySuccess(props.data, data)
            }
        }
    }
    const changeVct = (data) => {
        // console.log(data);
        // this.setState({terminos:false})
        // this.setState({vct:data, terminos:true, dateFactura:true})
    }
    const handleActiveFactura = () => {
        props.setCompra(false)
        props.activePopFactura(!props.checkFactura, true)
    }
    const viewPDF = () => {
        if (activePDF) {
            return (
                <VisorPDF pdf={dataPDF} activePop={setActivePDF} id={''} title="Terminos y condiciones" />
            )
        }
    }
    const getTerminos = async () => {
        const temino = await Wrapper.getDataU('toc')
        setDataPDF(temino.data.data.toc)
        setActivePDF(true)
    }
    const compartido = () => {
        return (
            <div>
                {TotalFecha()}
                {props.data.PaymentInfo.Bank !== undefined ?
                    <div>
                        <div className="mt-20"><strong>Banco:</strong></div>
                        <span>{props.data.PaymentInfo.Bank}</span><br />
                        <div className="mt-20"><strong>Clabe:</strong></div>
                        <span>{props.data.PaymentInfo.Clabe}</span><br />
                    </div> :
                    <div>
                        <div className="mt-20"><strong>Referencia:</strong></div>
                        {props.data.PaymentInfo.stub === undefined ?
                            <span>{props.data.PaymentInfo.PaymentReference}</span> :
                            <span>{props.data.PaymentInfo.stub.Reference}</span>
                        }
                    </div>
                }

            </div>
        )
    }
    const TotalFecha = () => {
        return (
            <div>
                <strong>Total:</strong><br />
                <span>{Functions.formatPrice(props.data.OrderTotalTotal)} {props.data.Currency}</span><br />
                <div className="mt-20"><strong>Fecha:</strong></div>
                <span>{Functions.fechaFormat(props.data.OrderDate)}</span><br />
            </div>
        )
    }
    useEffect(() => {
        // console.log('getConten');
        // console.log(props);
        setALoader(false)
        if (props?.data?.PaymentMethods !== undefined) {
            if (props?.data?.PaymentMethods[3]?.default !== undefined) {
                // setCardDefault(true)
                // props.setDefault(true)
                setCardDefaultInfo(props?.data?.PaymentMethods[3]?.default)
                // console.log(CardDefault);
            }
        }
    }, [props])
    switch (props.type) {
        case 'sales':
            return (
                <div className="text-left">
                    <p><strong>Total de la venta:</strong><br /> {Functions.formatPrice(data.OrderSellerTotalTotal)} {data.OrderSellerCurrency}</p>
                    <p><strong>Impuestos:</strong><br /> {Functions.formatPrice(data.OrdersSellerTotalTax)} {data.OrderSellerCurrency}</p>
                    <p><strong>Comisión Xpertcad:</strong><br /> {Functions.formatPrice(data.OrderSellerTotalFee)} {data.OrderSellerCurrency}</p>
                    <p><strong>Ganancia Estimada:</strong><br /> {Functions.formatPrice(data.OrderSellerTotalToPay)} {data.OrderSellerCurrency}</p>
                    <strong>Información del comprador</strong>
                    <hr />
                    <p><strong>Nombre:</strong><br /> {data.BuyingInfo.BuyerInfo.BuyerName}</p>
                    <p><strong>Correo:</strong><br /> {data.BuyingInfo.BuyerInfo.BuyerEmail}</p>
                    {props.user.author_billing !== undefined ?
                        <div>
                            <p><strong>Solicito factura:</strong><br /> {data.BuyingInfo.BuyerInfo.BuyerRFC !== '' ? 'Si' : 'No'}</p>
                            {/* {console.log(data.BuyingInfo)} */}
                            {data.BuyingInfo.BuyerInfo.BuyerRFC !== '' ?
                                <div>
                                    <strong>Facturación</strong>
                                    <hr />
                                    <p><strong>Receptor:</strong><br /> {data.BuyingInfo.BuyerInfo.BuyerRFC}</p>
                                    <p><strong>Total Factura:</strong><br /> {data.InvoiceInfo.OSInvoiceTotalTotal}</p>
                                    <p><strong>Impuestos:</strong><br /> {data.InvoiceInfo.OSInvoiceTotalTax}</p>
                                </div> : <div></div>
                            }
                        </div> :
                        ''
                    }

                </div>
            )
        case 'PayPal':
        case '1':
            return (
                <div>
                    {TotalFecha()}
                    <div className="mt-20"><strong>Correo:</strong></div>
                    <span className="correo">{props.data.OrderContactEmail}</span><br />
                </div>
            )
        case "OxxoPay":
        case '2':
            return (
                <div>
                    {compartido()}
                    <button className="btn outline mt-20 w-100" onClick={() => Functions.imprimir('oxxoP')}>Descargar voucher</button>
                    <div className="oculto d-none">
                        <Oxxo data={props.data.PaymentInfo} />
                    </div>
                </div>
            )
        case "CREDIT":
        case '3':
            return (
                <div>
                    <strong>Total:</strong><br />
                    <span>{Functions.formatPrice(props.data.PaymentInfo.Total)} {props.data.PaymentInfo.Currency}</span><br />
                    <div className="mt-20"><strong>Fecha:</strong></div>
                    <span>{Functions.fechaFormat(props.data.PaymentInfo.Date)}</span><br />
                    <div className="mt-20"><strong>Banco:</strong></div>
                    <span>{props.data.PaymentInfo.CardBank} {props.data.PaymentInfo.CardBrand}</span><br />
                    <div className="mt-20"><strong>Tipo detarjeta:</strong></div>
                    <span>{props.data.PaymentInfo.CardType}</span><br />
                    <div className="mt-20"><strong>Tarjeta:</strong></div>
                    <span>**** **** **** {props.data.PaymentInfo.CardLast4}</span>
                    {/* <span>{PaymentInfo.stub.Reference}</span> */}
                </div>
            )
        case 'spei':
        case '5':
            return (
                <div>
                    {compartido()}
                    <button className="btn outline mt-20 w-100" onClick={() => Functions.imprimir('speiP')}>Descargar voucher</button>
                    <div className="d-none">
                        <Spei data={props.data.PaymentInfo} />
                    </div>
                </div>
            )
        case 'metodos':
            // console.log(props);
            if (!props.vista) {
                return (
                    // <div></div>
                    <PayMethod default={props.Default} keyMetodos={Object.keys(props?.data?.PaymentMethods)} metodos={props?.data?.PaymentMethods} ordens={props.data} selectMB={props.setSelectMB} selectMetodoCompra={props.selectMB} setCardDefault={props.setDefault} />
                )
            } else {
                switch (props.selectMB) {
                    case 2:
                        if (checkFactura) {
                            // console.log("tiene factura");
                            return (
                                !props.SuccesBuy ?
                                    <Oxxo buySuccess={buySuccess} id={data.OrderID} data={FinishBuy} rfc={props.BillingInfo} orden={true} compraOxxo={false} envio={0} messages={messages} recarga={recarga} dataL={data} /> :
                                    <div>
                                        {/* {console.log("mostar ticket")} */}
                                        <Oxxo data={FinishBuy} />
                                    </div>

                            )
                        } else {
                            // console.log("no tiene factura");

                            return (
                                !props.SuccesBuy ?
                                    <Oxxo buySuccess={buySuccess} id={data.OrderID} data={FinishBuy} envio={0} compraOxxo={false} orden={true} messages={messages} recarga={recarga} dataL={data} /> :
                                    <div>
                                        {/* {console.log("mostar ticket")} */}
                                        <Oxxo data={FinishBuy} />
                                    </div>
                            )
                        }
                    case 5:
                        if (checkFactura) {
                            return (
                                !props.SuccesBuy ?
                                    <Spei buySuccess={buySuccess} id={data.OrderID} data={{}} rfc={props.BillingInfo} orden={true} compraOxxo={false} envio={0} messages={messages} recarga={recarga} dataL={data} /> :
                                    <div>
                                        {/* {console.log("mostar ticket")} */}
                                        <Spei data={FinishBuy} />
                                    </div>

                            )
                        } else {
                            return (
                                !props.SuccesBuy ?
                                    <Spei buySuccess={buySuccess} id={data.OrderID} data={{}} orden={true} compraOxxo={false} envio={0} messages={messages} recarga={recarga} dataL={data} /> :
                                    <div>
                                        {/* {console.log("mostar ticket")} */}
                                        <Spei data={FinishBuy} />
                                    </div>
                                // <Spei id={data.OrderID} data={{}} envio={0} compraOxxo={false} orden={true} messages={messages} recarga={recarga} dataL={data} />
                            )
                        }
                    default:
                        return (
                            <div></div>
                        )
                }
            }
        case 'factura':
            // console.log("factura");
            // console.log(props);
            if (!props.vista) {
                return (
                    <form className="text-left">
                        {ALoader ?
                            <div>
                                <Loader />
                                {/* {console.log("Get content loader")} */}
                            </div>
                            : ""}
                        {viewPDF()}
                        <div className="ml-0 centrar">
                            <label>
                                <input
                                    name="terminos"
                                    type="checkbox"
                                    checked={props.terminos}
                                    onChange={(e) => props.setTerminos(!props.terminos)}
                                    required
                                />
                                <span> </span>
                            </label>
                            <span className="pointer" onClick={() => getTerminos()}>Aceptar terminos y condiciones</span>
                            {/* <span className="pointer" >Aceptar terminos y condiciones</span> */}
                        </div>
                        <div className="mb-20 centrar">
                            <label className={selectMB === '' ? 'oculto d-none' : ''}>
                                {/* <label className=''> */}
                                <input
                                    name="facturaO"
                                    checked={checkFactura}
                                    type="checkbox"
                                    onChange={() => handleActiveFactura()}
                                />
                                <span className="black-text text-black">Necesito factura</span>
                            </label>
                            {checkFactura ?
                                // <div onClick={()=>handleActiveFactura()} className="pointer">
                                <div onClick={() => props.activePopFactura(true, true)} className="pointer">
                                    <i className="material-icons-outlined text-orange fz-20 ml-10">edit</i>
                                </div> :
                                <div></div>
                            }
                        </div>
                        {selectMB === 1 && props.terminos ?
                            checkFactura ?
                                <PaypalButtons total={data.OrderTotalTotal} idOrden={data.OrderID} rfc={data.BillingInfo} changeVct={changeVct} data={data} activePopFactura={props.activePopFactura} /> :
                                <PaypalButtons total={data.OrderTotalTotal} idOrden={data.OrderID} changeVct={changeVct} data={data} activePopFactura={props.activePopFactura} /> :
                            // <button className={selectMB === 1 ? 'oculto d-none' : 'btn outline w-100'} disabled={selectMB === '' || !props.terminos} onClick={(e) => { props.buy !== undefined ? props.buy(e) : handleCompra(e) }}>Realizar compra</button>
                            <button className={selectMB === 1 ? 'oculto d-none' : 'btn outline w-100'} disabled={selectMB === '' || !props.terminos} onClick={(e) => handleCompra(e)}>Realizar compra</button>
                            // <button className='btn outline w-100' onClick={(e)=>{handleCompra(e)}}>Realizar compra</button>
                        }
                    </form>
                )
            } else {
                return (
                    <div>
                        <Link className="btn w-100 outline" to='/'>Seguir comprando</Link>
                        <Link className="btn w-100 bg-orange mt-20" to='/MyOrders'>Ir a tus compras</Link>
                    </div>
                )
            }
        default:
            break;
    }
})
export default GetContent