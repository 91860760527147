import React, { useState,useEffect } from 'react';
import Functions from "../hooks/functions";

const Formcard = (props)=>{
    const [token, setToken] = useState('');
    useEffect (() => { 
        let tok = Functions.PassAleatorio(16)
        setToken(tok)
    },[])
    return(
        <div className="card p-20 text-center">
            <form onSubmit={props.handleInputChange} id="tarjeta">
            {/* <form id="tarjeta"> */}
                <span className="card-errors"></span>
                <div>
                    <label>
                        <span>Titular de la tarjeta</span>
                        <input type="text" size="20" name="name" required/>
                    </label>
                </div>
                <div>
                    <label>
                        <span>Numero de tarjeta</span>
                        <input type="number" size="20" name="number" required defaultValue={token} disabled/>
                    </label>
                </div>
                <div className="row">
                    <div className="col s6">
                        <div>
                            <label>
                                <span>Expiration date (MM/YYYY)</span>
                                <div className="row">
                                    <div className="col s6">
                                        <input type="number" size="2" name="exp_month" required/>
                                    </div>
                                    <div className="col s6">
                                        <input type="number" size="4" name="exp_year" required/>
                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className="col s3"></div>
                    <div className="col s3">
                        <div>
                            <label>
                                <span>CVC</span>
                                <input type="text" size="4" name="cvc" maxLength="3" required/>
                            </label>
                        </div>
                    </div>
                </div>
                <button className="btn bg-orange" >Registrar metodo de pago</button>
                <button className="btn outline ml-5" onClick={()=>props.cancelar('lista')}>Cancelar</button>
            </form>
        </div>
    )
}

export default Formcard