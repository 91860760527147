import React from "react";
import { appid } from "../../hooks/url";
import { useStore } from "../../store/StoreProvider";
import LinkRef from "../Others/LinkRef";

export default function ButtonBuyPlan(params) {
    const Store = useStore()
    // console.log(Store);
    return (
        Store.typeUser.user_full_name === "Invitado" || Store.typeUser === "" ?
            process.env.NODE_ENV === "development" ?
                // <LinkRef to={"/plans/checkout/" + DataPlan?.PlanID}>
                <LinkRef to={"/plans/checkout/" + params.id}>
                    {/* {console.log('entro en invitado en desarrollo')} */}
                    <button className="btn bg-orange mt-10 w-100">{params.text}</button>
                </LinkRef> :
                // <a href="https://accounts.xpertcad.com/?APPID=8">
                <a href={"https://accounts.xpertcad.com/?"+appid}>
                    {/* {console.log('entro en produccion sin cuenta')} */}
                    <button className="btn bg-orange mt-10 w-100">{params.text}</button>
                </a> :
            // <LinkRef to={"/plans/checkout/" + DataPlan?.PlanID}>
            <LinkRef to={"/plans/checkout/" + params.id}>
                {/* {console.log('dentro en prouccion con cuenta')} */}
                <button className="btn bg-orange mt-10 w-100">{params.text}</button>
            </LinkRef>
            
            
            
    )
}