import React, { useCallback, useEffect, useState } from "react";
import wrapper from "../../hooks/wrapper";
import Functions from "../../hooks/functions";
import { useDispatch, useStore } from "../../store/StoreProvider";
import { useHistory } from "react-router";
import { appid } from "../../hooks/url";
export default function ButtonBuy(props) {
    const history = useHistory()
    const Store = useStore()
    // console.log(Store);
    const Dispatch = useDispatch()
    // const [DataUser, setDataUser] = useState({})
    const [DataUser, setDataUser] = useState(Store.typeUser)
    const getUsers = useCallback(async () => {
        let users = await wrapper.getDataU('users')
        // console.log("users button buy");
        setDataUser(users?.data?.data)
    }, [])
    const AddCartDirect = async () => {
        let dataD = {}
        dataD.item_id = props?.data?.CourseID
        dataD.item_type = props.type
        let buy = await wrapper.postDataN("orders/buyNow/" + props?.data?.CourseID, dataD)
        console.log(dataD);
        console.log(buy);
        if (buy?.status === 200) {
            localStorage.setItem('orden', JSON.stringify(buy.data.data))
            localStorage.setItem('metodos', JSON.stringify(buy.data.data.PaymentMethods))
            history.push('/checkout')
        }
    }
    const ValidAddCartDirect = () => {
        // console.log("directa");
        if (DataUser?.user_full_name === "Invitado") {
            if (process.env.NODE_ENV === "development") {
                AddCartDirect()
            } else {
                // window.location.href = 'https://accounts.xpertcad.com/?APPID=8'
                window.location.href = 'https://accounts.xpertcad.com/?'+appid
            }
        } else {
            AddCartDirect()
        }

    }
    const AddCart = async () => {
        let cart = await wrapper.getDataU('cart')
        // console.log(cart);
        let url = ""
        if (cart.data.data === null) {
            // console.log('no existe');
            if (DataUser.user_full_name === "Invitado") {
                url = 'cart/' + DataUser.guest_id
            } else {
                url = 'cart/add'
            }
        } else {
            // console.log('existe');
            url = 'cart/' + cart?.data?.data?.id
        }
        let data = {}
        data.item_id = props?.data?.CourseID
        data.item_type = props?.data?.Type === "course" ? "courses" : props?.data?.Type
        // console.log(url);
        // console.log(data);
        let cartAcrion = await wrapper.postDataN(url, data)
        // console.log(cartAcrion);
        if (cartAcrion?.data?.data?.cart?.alert !== undefined) {
            // console.log("tiene un alert");
            // setActiveModal()
            Dispatch({ type: 'notification', data: { exist: true, open: true, vertical: 'top', horizontal: 'right', data: { img: props?.data?.CourseImage, link: props?.ruta, name: props?.data?.CourseName, text: cartAcrion?.data?.data?.cart?.alert } }, exist: true })
            Dispatch({ type: 'addcart', data: cartAcrion?.data?.data?.cart?.total_items })
            Functions.dataLayerAdd(cartAcrion?.data?.data?.cart)
            Functions.facebookAdd(cartAcrion?.data?.data?.cart)
            // setNotication({ open: true, vertical: 'top', horizontal: 'right' })
        } else {
            // console.log("no tiene un alert");
            if (cartAcrion?.data?.error?.repeated !== undefined) {
                // console.log("ya existe en el cart");
                Dispatch({ type: 'notification', data: { exist: true, open: true, vertical: 'top', horizontal: 'right', data: { img: props?.data?.CourseImage, link: props?.ruta, name: props?.data?.CourseName } }, exist: true })

            } else {
                // console.log("no existe en el cart");
                // console.log(cartAcrion?.data?.data?.cart);
                // console.log(cartAcrion?.data?.data?.cart?.total_items);
                Dispatch({ type: 'notification', data: { open: true, vertical: 'top', horizontal: 'right', data: { img: props?.data?.CourseImage, link: props?.ruta, name: props?.data?.CourseName } } })
                Dispatch({ type: 'addcart', data: cartAcrion?.data?.data?.cart?.total_items })
                Functions.dataLayerAdd(cartAcrion?.data?.data?.cart)
                Functions.facebookAdd(cartAcrion?.data?.data?.cart)
            }
            // setNotication({ open: true, vertical: 'top', horizontal: 'right' })
            // setActiveModal(modal)
        }
    }
    useEffect(() => {
        // getUsers()
        // console.log(props); 
    }, [getUsers])
    return (
        <button className={props?.clas} onClick={() => props?.directa === undefined ? AddCart() : ValidAddCartDirect()}>
            <div className="m-auto centrar">
                <i className="material-icons-outlined mr-20 ml-auto">{props?.icon}</i> <span className="mr-auto">{props?.text}</span>
            </div>
        </button>
    )
}