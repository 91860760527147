const APIS = {
  ordenBuy: {
    data: {
      data: {
        "OrderID": "2522",
        "CartID": "1dd03a24-3cb3-4db3-b19d-14e0cb33a053",
        "UserID": "11653",
        "AccountID": "-1",
        "OrderDate": "2021-10-17 18:45:23",
        "OrderStatus": "0",
        "OrderContactFirstName": "Hector Eduardo ",
        "OrderContactLastName": "González Luna",
        "OrderContactFullName": "Hector Eduardo  González Luna",
        "OrderContactEmail": "hgonzalez@novut.com",
        "OrderContactPhone": "0",
        "OrderTotalItems": "1",
        "OrderPaymentTransaction": "",
        "OrderPaymentDetails": "",
        "OrderPaymentMethod": "0",
        "OrderPaymentGateway": "0",
        "OrderPaymentGatewayFee": "0.00",
        "OrderPaymentOrder": "",
        "OrderCurrency": "MXN",
        "OrderTotalTax": "31.84",
        "OrderTotalAmount": "199.00",
        "OrderTotalTotal": "230.84",
        "OrderTotalPayments": "0.00",
        "OrderTotalpaymentDebt": "230.84",
        "Toc": "",
        "OrderInvoiceRequired": "0",
        "BillingID": "0",
        "OrderInvoiceRequesterRFC": "",
        "OrderType": "",
        "PlanID": "0",
        "ServiceID": "0",
        "SSubID": "0",
        "PlanGatewayID": "",
        "SubscriptionGatewayID": "",
        "ODeploymentStatus": "0",
        "SSubTotalCycles": "0",
        "Cancelled": "0",
        "CancelledInfo": "",
        "OrderItems": {
          "3250": {
            "OItemID": "3250",
            "OrderID": "2522",
            "CartID": "0",
            "OItemType": "",
            "OItemDescription": "Croquizado 2D con SOLIDWORKS",
            "OItemQty": "1",
            "OItemPrice": "199.00",
            "OItemDiscount": "0.00",
            "OItemPromoCode": "",
            "OItemPromoDiscount": "",
            "OItemTotalAmount": "199.00",
            "OItemTotalDiscount": "0.00",
            "OItemTotalTax": "31.84",
            "OItemTotalTotal": "230.84",
            "OItemCurrency": "MXN",
            "OItemRefID": "1",
            "OItemRefModule": "courses",
            "OItemRefPrice": "0.00",
            "OItemRefCurrency": "",
            "OItemRefExchange": "0.00",
            "OItemAuthorID": "1",
            "Cancelled": "0",
            "CancelledInfo": "",
            "OItemImage": "https://academy.xpertcad.com/files/4c1e3e04ce0d85bce5719e6d4599f640/144f2dd508785f42c556ccd644047905/5f80e874dfd69.gif"
          }
        },
        "SubOrders": {
          "1966": {
            "OrderID": "2522",
            "AuthorID": "1",
            "OrderSellerID": "1966",
            "OrderSellerDate": "2021-10-17 18:45:23",
            "OrderSellerTotalAmount": "199.00",
            "OrdersSellerTax": "0.00",
            "OrdersSellerTotalTax": "31.84",
            "OrderSellerTotalTotal": "230.84",
            "OrderSellerFeeRate": "0.25",
            "OrderSellerTotalFee": "57.71",
            "OrderSellerGatewayFee": "0.00",
            "OrderSellerTotalToPay": "173.13",
            "OrderSellerTotalItems": "1",
            "OrderSellerCurrency": "MXN",
            "OrderSellerStatus": "0",
            "SPaymentID": "0",
            "BSalesID": "0",
            "OrderInvoiceRequired": "0",
            "OrderInvoiceRequesterRFC": "",
            "BillingID": "0",
            "Cancelled": "0",
            "CancelledInfo": "",
            "OSellerItems": {
              "2534": {
                "OItemID": "3250",
                "OrderID": "2522",
                "OrderSellerID": "1966",
                "OSellerItemID": "2534",
                "OSellerItemRefModule": "courses",
                "OSellerItemDescription": "Croquizado 2D con SOLIDWORKS",
                "OSellerItemQty": "1",
                "OSellerItemPrice": "199.00",
                "OSellerItemDiscount": "0.00",
                "OSellerItemTotalAmount": "199.00",
                "OSellerItemTotalTotal": "230.84",
                "OSellerItemTotalTax": "31.84",
                "OSellerItemCurrency": "MXN",
                "OSellerItemPriceRef": "0.00",
                "OSellerItemCurrencyRef": "",
                "OItemRefExchange": "0.00",
                "Cancelled": "0",
                "CancelledInfo": "",
                "OSellerItemInfo": {
                  "OSItemID": 1,
                  "OSItemName": "Croquizado 2D con SOLIDWORKS",
                  "OSItemDescription": [
                    "Aprende a utilizar las herramientas de croquizado de SOLIDWORKS para crear croquis 2D con diferentes entidades geometricas, como lineas, circulos, cuadrados, etc. Los croquis describen perfiles en dos dimensiones, y se utilizan en muchas de las operaciones necesarias para obtener un modelo 3D."
                  ],
                  "OSItemImage": "https://academy.xpertcad.com/files/4c1e3e04ce0d85bce5719e6d4599f640/144f2dd508785f42c556ccd644047905/5f80e874dfd69.gif",
                  "OSItemThumbnail": "https://academy.xpertcad.com/files/4c1e3e04ce0d85bce5719e6d4599f640/144f2dd508785f42c556ccd644047905/5f80e874dfd69.gif"
                }
              }
            },
            "OrderSellerStatusName": "New",
            "AuthorName": "XpertCAD",
            "InvoiceInfo": []
          }
        },
        "OrderStatusName": "New",
        "PaymentList": [],
        "PaymentMethods": {
          "1": {
            "id": 1,
            "name": "PayPal",
            "code": "paypal",
            "public_key": "Adj-b8RXD0tHNeE6hgFXuSPZoRKeNbq7GRym5XFhBkCuPn4IePA_V4TJN6-VQbwg0kM8vRoKWA7MJe-e",
            "class": "App\\Marketplace\\Orders\\Payments\\Methods\\Paypal",
            "client_id": "Adj-b8RXD0tHNeE6hgFXuSPZoRKeNbq7GRym5XFhBkCuPn4IePA_V4TJN6-VQbwg0kM8vRoKWA7MJe-e",
            "currencies": [
              "USD",
              "MXN"
            ],
            "fee_percent": 3.95,
            "iva_included": true,
            "plus_mxn": 0
          },
          "2": {
            "id": 2,
            "name": "Oxxo",
            "code": "oxxo",
            "class": "App\\Marketplace\\Orders\\Payments\\Methods\\ConektaOxxo",
            "public_key": "key_Pzqbyx3NTCvZduX5Xg5PMUA",
            "currencies": [
              "USD",
              "MXN"
            ],
            "fee_percent": 4.52,
            "iva_included": true,
            "plus_mxn": 12
          },
          "3": {
            "id": 3,
            "name": "Tarjeta de Crédito/Débito",
            "public_key": "key_Pzqbyx3NTCvZduX5Xg5PMUA",
            "code": "card",
            "class": "App\\Marketplace\\Orders\\Payments\\Methods\\ConektaCard",
            "currencies": [
              "USD",
              "MXN"
            ],
            "fee_percent": 2.9,
            "iva_included": false,
            "plus_mxn": 2.5,
            "default": {
              "name": "Hector Eduardo González Luna",
              "last4": "0002",
              "card_type": "credit",
              "exp_month": "11",
              "exp_year": "21",
              "brand": "visa"
            }
          },
          "4": {
            "id": 4,
            "name": "Transferencia",
            "code": "transfer",
            "class": "App\\Marketplace\\Orders\\Payments\\Methods\\Transfer",
            "currencies": [
              "MXN"
            ],
            "fee_percent": 0,
            "iva_included": true,
            "plus_mxn": 0
          },
          "5": {
            "id": 5,
            "name": "SPEI",
            "code": "spei",
            "class": "App\\Marketplace\\Orders\\Payments\\Methods\\ConektaSpei",
            "currencies": [
              "MXN"
            ],
            "fee_percent": 0,
            "iva_included": true,
            "plus_mxn": 12.5
          }
        }
      }
    }
  },
  especializacion: {
    idGroupPlan: "2",
    type: "complete",
    title: "Plan de especializaciones",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla luctus non diam ut condimentum. Fusce in nisi quis diam consequat mollis suscipit vel tellus.",
    share: "",
    subtitle: "¿Qué especializaciones obtendré",
    plans: [
      {
        id: "0",
        title: 'Plan de industria 4.0',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla luctus non diam ut condimentum. Fusce in nisi quis diam consequat mollis suscipit vel tellus.',
        objective: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla luctus non diam ut condimentum. Fusce in nisi quis diam consequat mollis suscipit vel tellus.",
        time: "10hr",
        price: '199',
        currency: "MX",
        monthlyPayment: "mes",
        link: "/planes/industria-1",
        cursos: [
          "Croquizado 2D con SOLIDWORKS",
          "básicas de diseño mecánico con SOLIDWORKS",
          "Matrices y simetría en ensambles",
          "Visualización y apariencia de ensambles.",
          "Croquizado 2D con SOLIDWORKS",
          "básicas de diseño mecánico con SOLIDWORKS",
          "Matrices y simetría en ensambles",
        ]
      },
      {
        id: "1",
        title: 'Plan de certificación',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla luctus non diam ut condimentum. Fusce in nisi quis diam consequat mollis suscipit vel tellus.',
        objective: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla luctus non diam ut condimentum. Fusce in nisi quis diam consequat mollis suscipit vel tellus.",
        time: "10hr",
        price: '266',
        currency: "MX",
        monthlyPayment: "mes",
        link: "/planes/certificaciones",
        cursos: [
          "Croquizado 2D con SOLIDWORKS",
          "básicas de diseño mecánico con SOLIDWORKS",
          "Matrices y simetría en ensambles",
          "Visualización y apariencia de ensambles."
        ]
      },
      {
        id: "2",
        title: 'Plan de especialización',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla luctus non diam ut condimentum. Fusce in nisi quis diam consequat mollis suscipit vel tellus.',
        objective: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla luctus non diam ut condimentum. Fusce in nisi quis diam consequat mollis suscipit vel tellus.",
        time: "10hr",
        price: '699',
        currency: "MX",
        monthlyPayment: "mes",
        link: "",
        cursos: [
          "Croquizado 2D con SOLIDWORKS",
          "básicas de diseño mecánico con SOLIDWORKS",
          "Matrices y simetría en ensambles",
          "Visualización y apariencia de ensambles."
        ]
      },
      {
        id: "3",
        title: 'Plan de especialización',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla luctus non diam ut condimentum. Fusce in nisi quis diam consequat mollis suscipit vel tellus.',
        objective: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla luctus non diam ut condimentum. Fusce in nisi quis diam consequat mollis suscipit vel tellus.",
        time: "10hr",
        price: '699',
        currency: "MX",
        monthlyPayment: "mes",
        link: "",
        cursos: [
          "Croquizado 2D con SOLIDWORKS",
          "básicas de diseño mecánico con SOLIDWORKS",
          "Matrices y simetría en ensambles",
          "Visualización y apariencia de ensambles."
        ]
      },
      {
        id: "4",
        title: 'lot',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla luctus non diam ut condimentum. Fusce in nisi quis diam consequat mollis suscipit vel tellus.',
        objective: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla luctus non diam ut condimentum. Fusce in nisi quis diam consequat mollis suscipit vel tellus.",
        time: "10hr",
        price: '699',
        currency: "MX",
        monthlyPayment: "mes",
        link: "",
        cursos: [
          "Croquizado 2D con SOLIDWORKS",
          "básicas de diseño mecánico con SOLIDWORKS",
          "Matrices y simetría en ensambles",
          "Visualización y apariencia de ensambles."
        ]
      },
      {
        id: "5",
        title: 'RA / RV',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla luctus non diam ut condimentum. Fusce in nisi quis diam consequat mollis suscipit vel tellus.',
        objective: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla luctus non diam ut condimentum. Fusce in nisi quis diam consequat mollis suscipit vel tellus.",
        time: "10hr",
        price: '699',
        currency: "MX",
        monthlyPayment: "mes",
        link: "",
        cursos: [
          "Croquizado 2D con SOLIDWORKS",
          "básicas de diseño mecánico con SOLIDWORKS",
          "Matrices y simetría en ensambles"
        ]
      }
    ]
  },
  certificaciones: {
    idGroupPlan: "1",
    type: "basic",
    title: "Plan de certificación",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla luctus non diam ut condimentum. Fusce in nisi quis diam consequat mollis suscipit vel tellus.",
    share: "",
    subtitle: "¿Qué certificaciones obtendré",
    plans: [
      {
        id: "0",
        title: 'Plan de industria 4.0',
        price: '199',
        currency: "MX",
        monthlyPayment: "mes",
        link: "/planes/industria-1",
        cursos: [
          "Croquizado 2D con SOLIDWORKS",
          "básicas de diseño mecánico con SOLIDWORKS",
          "Matrices y simetría en ensambles",
          "Visualización y apariencia de ensambles.",
          "Croquizado 2D con SOLIDWORKS",
          "básicas de diseño mecánico con SOLIDWORKS",
          "Matrices y simetría en ensambles",
        ]
      },
      {
        id: "1",
        title: 'Plan de certificación',
        price: '266',
        currency: "MX",
        monthlyPayment: "mes",
        link: "/planes/certificaciones",
        cursos: [
          "Croquizado 2D con SOLIDWORKS",
          "básicas de diseño mecánico con SOLIDWORKS",
          "Matrices y simetría en ensambles",
          "Visualización y apariencia de ensambles."
        ]
      },
      {
        id: "2",
        title: 'Plan de especialización',
        price: '699',
        currency: "MX",
        monthlyPayment: "mes",
        link: "",
        cursos: [
          "Croquizado 2D con SOLIDWORKS",
          "básicas de diseño mecánico con SOLIDWORKS",
          "Matrices y simetría en ensambles",
          "Visualización y apariencia de ensambles."
        ]
      },
      {
        id: "3",
        title: 'Plan de especialización',
        price: '699',
        currency: "MX",
        monthlyPayment: "mes",
        link: "",
        cursos: [
          "Croquizado 2D con SOLIDWORKS",
          "básicas de diseño mecánico con SOLIDWORKS",
          "Matrices y simetría en ensambles",
          "Visualización y apariencia de ensambles."
        ]
      },
      {
        id: "4",
        title: 'lot',
        price: '699',
        currency: "MX",
        monthlyPayment: "mes",
        link: "",
        cursos: [
          "Croquizado 2D con SOLIDWORKS",
          "básicas de diseño mecánico con SOLIDWORKS",
          "Matrices y simetría en ensambles",
          "Visualización y apariencia de ensambles."
        ]
      },
      {
        id: "5",
        title: 'RA / RV',
        price: '699',
        currency: "MX",
        monthlyPayment: "mes",
        link: "",
        cursos: [
          "Croquizado 2D con SOLIDWORKS",
          "básicas de diseño mecánico con SOLIDWORKS",
          "Matrices y simetría en ensambles"
        ]
      }
    ]
  },
  industria: {
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus faucibus nisl pulvinar, egestas felis sed, pellentesque justo. Nam at mauris arcu.',
    objective: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus faucibus nisl pulvinar, egestas felis sed, pellentesque justo. Nam at mauris arcu.',
    data: [
      {
        img: "https://picsum.photos/300/200?random=1",
        title: 'Croquizado 2D con SOLIDWORKS',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus faucibus nisl pulvinar, egestas felis sed, pellentesque justo. Nam at mauris arcu.',
        hr: "10hr",
        nivel: "A",
        calif: "4.7",
        total: "453",
      },
      {
        img: "https://picsum.photos/300/200?random=2",
        title: 'Matrices y simetría en ensambles',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus faucibus nisl pulvinar, egestas felis sed, pellentesque justo. Nam at mauris arcu.',
        hr: "10hr",
        nivel: "B",
        calif: "4.7",
        total: "453",
      },
      {
        img: "https://picsum.photos/300/200?random=3",
        title: 'Visualización y apariencia de ensambles.',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus faucibus nisl pulvinar, egestas felis sed, pellentesque justo. Nam at mauris arcu.',
        hr: "10hr",
        nivel: "C",
        calif: "4.7",
        total: "453",
      },
      {
        img: "https://picsum.photos/300/200?random=1",
        title: 'Croquizado 2D con SOLIDWORKS',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus faucibus nisl pulvinar, egestas felis sed, pellentesque justo. Nam at mauris arcu.',
        hr: "10hr",
        nivel: "A",
        calif: "4.7",
        total: "453",
      },
      {
        img: "https://picsum.photos/300/200?random=2",
        title: 'Matrices y simetría en ensambles',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus faucibus nisl pulvinar, egestas felis sed, pellentesque justo. Nam at mauris arcu.',
        hr: "10hr",
        nivel: "B",
        calif: "4.7",
        total: "453",
      },
      {
        img: "https://picsum.photos/300/200?random=3",
        title: 'Visualización y apariencia de ensambles.',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus faucibus nisl pulvinar, egestas felis sed, pellentesque justo. Nam at mauris arcu.',
        hr: "10hr",
        nivel: "C",
        calif: "4.7",
        total: "453",
      }
    ]
  },
  plans: {
    data: [
      {
        title: 'Plan de industria 4.0',
        price: '199',
        currency: "MX",
        monthlyPayment: "mes",
        link: "/planes/industria-1",
        cursos: [
          "Croquizado 2D con SOLIDWORKS",
          "básicas de diseño mecánico con SOLIDWORKS",
          "Matrices y simetría en ensambles",
          "Visualización y apariencia de ensambles."
        ]
      },
      {
        title: 'Plan de certificación',
        price: '266',
        currency: "MX",
        monthlyPayment: "mes",
        link: "/planes/certificaciones",
        cursos: [
          "Croquizado 2D con SOLIDWORKS",
          "básicas de diseño mecánico con SOLIDWORKS",
          "Matrices y simetría en ensambles",
          "Visualización y apariencia de ensambles."
        ]
      },
      {
        title: 'Plan de especialización',
        price: '699',
        currency: "MX",
        monthlyPayment: "mes",
        link: "/planes/especializacion",
        cursos: [
          "Croquizado 2D con SOLIDWORKS",
          "básicas de diseño mecánico con SOLIDWORKS",
          "Matrices y simetría en ensambles",
          "Visualización y apariencia de ensambles."
        ]
      }
    ]
  },
  navbar: {
    "logo": "https://image.flaticon.com/icons/png/512/2000/2000887.png",
    "navlist": [
      {
        "link": "/",
        "text": "Aprende",
        "sub": [
          {
            "link": "https://academy.xpertcad.com/dashboard/gettingStart",
            "text": "Como comenzar",
            'title': 'Guías de uso de la plataforma'
          },
          {
            "link": "https://academy.xpertcad.com/courses",
            "text": "Mis cursos",
            'title': 'Cursos asignados por tu institución'
          },
          {
            "link": "https://academy.xpertcad.com/courses/forums",
            "text": "Foros",
            'title': 'Participa en los foros activos'
          },
          {
            "link": "https://academy.xpertcad.com/feedback/feedbackList",
            "text": "Mis calificaciones",
            'title': 'Ver avances de cursos asignados y resultados de tus actividades realizadas'
          }
        ]
      },
      {
        "link": "#/",
        "text": "Crea",
        "sub": [
          {
            "link": "https://academy.xpertcad.com/courses/allCoursesCreated",
            "text": "Cursos",
            'title': 'Lista de cursos donde eres autor ó colaborador'
          },
          {
            "link": "https://academy.xpertcad.com/courses/course",
            "text": "Crear cursos",
            'title': 'Crea un nuevo curso'
          },
          {
            "link": "https://academy.xpertcad.com/forum/getTeacherForums",
            "text": "Foros creados",
            'title': 'Participa en los foros de los cursos donde eres autor ó colaborador'
          }
        ]
      }
    ],
    "user": {
      "avatar": "https://academy.xpertcad.com/assets/img/navbar/user.png",
      "name": "Héctor",
      "dropdown": [
        {
          "link": "#/",
          "text": "Perfil"
        },
        {
          "link": "#/",
          "text": "cursos"
        }
      ]
    },
    "cantProduct": 3
  },
  featured: {
    "featured": [
      {
        "img": "https://eleternoestudiante.com/wp-content/uploads/2020/04/cropped-plataformas-de-cursos-online-gratis.jpg",
        "title": "Curso 1",
        "hours": "35 min",
        "description": "Descricpción de curso 1",
        "price": 100,
        "discount": {
          "percent": 57,
          "price": 99.99
        },
        "sonud": "Español",
        "subtitle": "Español, Inglés, Portugués",
        "featuredContent": []
      },
      {
        "img": "https://www.trecebits.com/wp-content/uploads/2019/12/cursos-gratis-800x445.jpg",
        "title": "Curso 2",
        "hours": "25 min",
        "description": "Descricpción de curso 2",
        "price": 150,
        "discount": {
          "percent": 57,
          "price": 99.99
        },
        "sonud": "Español",
        "subtitle": "Español, Inglés, Portugués",
        "featuredContent": []
      }
    ]
  },
  curses: {
    "curses": [
      {
        "idAutor": 0,
        "img": "https://www.trecebits.com/wp-content/uploads/2019/12/cursos-gratis-800x445.jpg",
        "title": "Curso 1",
        "hour": "30 min",
        "description": "Descricion de curso 1",
        "price": 50,
        "discount": {
          "percent": 57,
          "price": 99.99
        },
        "sonud": "Español",
        "subtitle": "Español, Inglés, Portugués",
        "featuredContent": []
      },
      {
        "idAutor": 1,
        "img": "https://eleternoestudiante.com/wp-content/uploads/2020/04/cropped-plataformas-de-cursos-online-gratis.jpg",
        "title": "Curso 2",
        "hour": "35 min",
        "description": "Descricion de curso 2",
        "price": 100,
        "discount": {
          "percent": 57,
          "price": 99.99
        },
        "sonud": "Español",
        "subtitle": "Español, Inglés, Portugués",
        "featuredContent": []
      },
      {
        "idAutor": 2,
        "img": "https://www.trecebits.com/wp-content/uploads/2019/12/cursos-gratis-800x445.jpg",
        "title": "Curso 3",
        "hour": "30 min",
        "description": "Descricion de curso 3",
        "price": 100,
        "discount": {
          "percent": 57,
          "price": 99.99
        },
        "sonud": "Español",
        "subtitle": "Español, Inglés, Portugués",
        "featuredContent": []
      },
      {
        "idAutor": 3,
        "img": "https://eleternoestudiante.com/wp-content/uploads/2020/04/cropped-plataformas-de-cursos-online-gratis.jpg",
        "title": "Curso 4",
        "hour": "35 min",
        "description": "Descricion de curso 4",
        "price": 150,
        "discount": {
          "percent": 57,
          "price": 99.99
        },
        "sonud": "Español",
        "subtitle": "Español, Inglés, Portugués",
        "featuredContent": []
      },
      {
        "idAutor": 4,
        "img": "https://www.trecebits.com/wp-content/uploads/2019/12/cursos-gratis-800x445.jpg",
        "title": "Curso 5",
        "hour": "30 min",
        "description": "Descricion de curso 5",
        "price": 50,
        "discount": {
          "percent": 57,
          "price": 99.99
        },
        "sonud": "Español",
        "subtitle": "Español, Inglés, Portugués",
        "featuredContent": []
      },
      {
        "idAutor": 5,
        "img": "https://eleternoestudiante.com/wp-content/uploads/2020/04/cropped-plataformas-de-cursos-online-gratis.jpg",
        "title": "Curso 6",
        "hour": "30 min",
        "description": "Descricion de curso 6",
        "price": 50,
        "discount": {
          "percent": 57,
          "price": 99.99
        },
        "sonud": "Español",
        "subtitle": "Español, Inglés, Portugués",
        "featuredContent": []
      },
      {
        "idAutor": 6,
        "img": "https://www.trecebits.com/wp-content/uploads/2019/12/cursos-gratis-800x445.jpg",
        "title": "Curso 7",
        "hour": "35 min",
        "description": "Descricion de curso 7",
        "price": 150,
        "discount": {
          "percent": 57,
          "price": 99.99
        },
        "sonud": "Español",
        "subtitle": "Español, Inglés, Portugués",
        "featuredContent": []
      },
      {
        "idAutor": 7,
        "img": "https://eleternoestudiante.com/wp-content/uploads/2020/04/cropped-plataformas-de-cursos-online-gratis.jpg",
        "title": "Curso 8",
        "hour": "30 min",
        "description": "Descricion de curso 8",
        "price": 50,
        "discount": {
          "percent": 57,
          "price": 99.99
        },
        "sonud": "Español",
        "subtitle": "Español, Inglés, Portugués",
        "featuredContent": []
      }
    ]
  },
  orden: {
    "orden": [
      {
        "course": "curso 1",
        "author": "Juan Alberto Lopez Ramirez",
        "price": 58.52
      },
      {
        "course": "curso 2",
        "author": "Maria alejandra Torres Hernandez",
        "price": 58.52
      },
      {
        "course": "curso 3",
        "author": "Maria alejandra Torres Hernandez",
        "price": 58.52
      }
    ],
    "subtotal": 218.87,
    "iva": 100,
    "total": 318.87
  },
  car: {
    "listCard": [
      {
        "id": 0,
        "img": "https://eleternoestudiante.com/wp-content/uploads/2020/04/cropped-plataformas-de-cursos-online-gratis.jpg",
        "title": "Curso",
        "description": "lorem epsom",
        "discount": {
          "percent": 57,
          "price": 99.99
        },
        "sonud": "Español",
        "subtitle": "Español, Inglés, Portugués",
        "price": 79.99,
        "featuredContent": [
          {
            "id": 0,
            "img": "https://eleternoestudiante.com/wp-content/uploads/2020/04/cropped-plataformas-de-cursos-online-gratis.jpg",
            "title": "titulo 1",
            "description": "lorem epsom",
            "discount": {
              "percent": 57,
              "price": 99.99
            },
            "sonud": "Español",
            "subtitle": "Español, Inglés, Portugués",
            "price": 79.99
          },
          {
            "id": 1,
            "img": "https://eleternoestudiante.com/wp-content/uploads/2020/04/cropped-plataformas-de-cursos-online-gratis.jpg",
            "title": "titulo 2",
            "description": "lorem epsom",
            "discount": {
              "percent": 57,
              "price": 99.99
            },
            "sonud": "Español",
            "subtitle": "Español, Inglés, Portugués",
            "price": 79.99
          }
        ]
      },
      {
        "id": 1,
        "img": "https://www.trecebits.com/wp-content/uploads/2019/12/cursos-gratis-800x445.jpg",
        "title": "titulo",
        "description": "lorem epsom",
        "discount": {
          "percent": 57,
          "price": 99.99
        },
        "sonud": "Español",
        "subtitle": "Español, Inglés, Portugués",
        "price": 79.99,
        "featuredContent": []
      }
    ],
    "subtotal": 55,
    "discount": -10,
    "iva": 25,
    "total": 70
  }
}
export default APIS