import React from "react";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class visorPDF extends React.Component{
    render(){
        return(
            <div className="pop centrar">
                <div className="close closePOp" onClick={()=>this.props.activePop(false,this.props.id)}>
                    <FontAwesomeIcon icon={faTimesCircle} className="fz-40"/>
                </div>
                <div className="card w-60 m-auto p-20">
                    <div className="bg-orange white-text text-white p-20">{this.props.title}</div>
                    <iframe src={this.props.pdf} className="w-100 hvh-70" title="visorPDF"/>
                    <div className="text-right">
                        <button className="btn bg-orange" onClick={()=>this.props.activePop(false,this.props.id)}>Cerrar</button>
                    </div>
                </div>
            </div>
        )
    }
}
export default visorPDF