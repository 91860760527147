import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
// import Pagination from '@material-ui/lab/Pagination';
import TableRow from '@material-ui/core/TableRow';
import Loader from "../components/loader";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faEye } from "@fortawesome/free-solid-svg-icons";
import Functions from "../hooks/functions";
import Wrapper from "../hooks/wrapper";
import Url from "../hooks/url";
import { messageService } from "../hooks/global";
import Axios from "axios";

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});


export default function StickyHeadTable(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  var [cartItems, setCartItems] = React.useState({});
  var [curse, setCurse] = React.useState('');
  var [contador, setContador] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  async function getcart(Event) {
    // console.log(Event);
    // console.log(Event.nativeEvent.path[4].cells[0].outerText);
    setCurse(curse = Event.nativeEvent.path[4].cells[0].outerText)
    const cart = await Wrapper.getData(Url + 'cart')
    // console.log('cart paginador');
    // console.log(cart);
    if (cart !== undefined) {
      // this.setState({cartItems:cart.data.data})
      setCartItems(cartItems = cart.data.data)
      setContador(contador = 0)
      if (contador === 0) {
        setContador(contador = 1)
        addCar()
      }
    }
  }
  function addCar() {
    let invitado = localStorage.getItem('invitado')
    if (cartItems === null) {
      // console.log('no existe');
      if (invitado !== null) {
        buyCart('cart/' + invitado)
      } else {
        buyCart('cart/add')
      }
    } else {
      // console.log('existe');
      buyCart('cart/' + cartItems.id)
    }
  }
  async function buyCart(url) {
    let data = {}
    data.item_id = curse
    data.item_type = ''
    Axios.post(Url + url, data, { withCredentials: true })
      .then(res => {
        localStorage.setItem('invitado', res.data.data.cart.guest_id)
        localStorage.setItem('myCart', res.data.data.cart.id)
        localStorage.setItem('compra', curse)

        messageService.sendMessage(res.data.data.cart.items, 'Cproducto');
        if (res.data.data.cart.alert !== undefined) {
          messageService.sendMessage('repeat', 'typeAlerta');
          messageService.sendMessage(res.data.data.cart.alert, 'alerta');
        } else {
          let key = Object.keys(res.data.data.cart.items)
          key.forEach(e => {
            if (res.data.data.cart.items[e].item_id === curse) {
              messageService.sendMessage('buy', 'typeAlerta');
              messageService.sendMessage(res.data.data.cart.items[e], 'alerta');
            }
          });
        }
        Functions.facebookAdd(res.data.data.cart)
        Functions.dataLayerAdd(res.data.data.cart)
        // localStorage.setItem()
      })
      .catch(err => {
        // console.log(err.response);
        if (err.response !== undefined) {
          if (err.response.data.error.repeated) {
            // console.log(err.response);
            // this.setState({alerta:true})
            messageService.sendMessage('repeat', 'typeAlerta');
            messageService.sendMessage('El curso ya esta agregado en el carrito', 'alerta');
          } else {
            messageService.sendMessage('repeat', 'typeAlerta');
            messageService.sendMessage(err.response.data.error, 'alerta');

          }
        }
      })
  }

  const { headers, body, link, link2, dataDescription, activePop, redirect, redirect2, list, idName } = props

  if (body.length > 0) {
    return (
      <div className="w-100">

        <Paper className={'mt-20' + classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {headers.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                      className="bg-success text-orange"
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {body.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                  // console.log(row);
                  var currency = ''
                  switch (list) {
                    case 'invoice':
                      currency = row.OSInvoiceCurrency
                      break;
                    case 'orders':
                      currency = row.OrderCurrency
                      break;
                    case 'ventas':
                      currency = row.OrderSellerCurrency
                      break;
                    case 'pagos':
                      currency = row.OrderSellerCurrency
                      break;
                    case 'cursos':
                      currency = row.CourseCurrency
                      break;
                    case 'suscrip':
                      currency = row.SSubCurrency
                      break;
                    default:
                      break;
                  }
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={'row' + index}>
                      {headers.map((column, indice) => {
                        // const value = row[column.id];
                        var value;
                        var busca = column.id.split('.')
                        // console.log(busca);
                        switch (busca.length) {
                          case 1:
                            value = row[column.id];
                            break;
                          case 2:
                            value = row[busca[0]][busca[1]];
                            break;
                          case 3:
                            value = row[busca[0]][busca[1]][busca[2]];
                            break;
                          default:
                            break;
                        }
                        // console.log(row);
                        if (row.CourseName !== undefined) {
                          var liga = row.CourseName.replace(/ /g, '-')
                        }
                        return (
                          <TableCell key={column.id} align={column.align} className={column.id}>
                            {/* {column.format && typeof value === 'number' ? column.format(value) : value} */}
                            {/* {console.log("prueba")}
                            {console.log(idName)} */}
                            {/* {console.log(link)} */}
                            {link === column.id && idName !== undefined ?
                              <Link to={'/' + redirect + '/' + row[idName]}>
                                {value}
                              </Link> :
                              column.id === 'SSubID' ?

                                <Link to={'/' + redirect + '/' + value} onClick={() => dataDescription('singleOrden', value)}>{value}</Link> :
                                link === column.id ?
                                  <Link to={row.CourseID !== undefined ?
                                    '/' + (row.Type === 'xpertclass' ?
                                      // 'Class' : redirect) + '/' + liga : '/' + (row.OrderType === "" ?
                                      //   redirect + '/' + value : redirect + '/plans/' + value)} onClick={() => dataDescription('singleOrden', value)}>{value}</Link> :
                                      'Class' : redirect) + '/' + liga : '/' + redirect + '/' + value } onClick={() => dataDescription('singleOrden', value)}>{value}</Link> :
                                  link2 === column.id ?
                                    <Link to={'/' + redirect2 + '/' + value} >{value}</Link> :
                                    column.id === 'FileURL' ?
                                      <div>
                                        <a href={value} target="blank" className="btn btn-danger">
                                          <FontAwesomeIcon icon={faFilePdf} /></a>
                                        <button className="btn btn-success ml-2" onClick={() => activePop(value)}>
                                          <FontAwesomeIcon icon={faEye} /></button>
                                      </div> :
                                      <span className={value === 'Pending' ?
                                        'Pending-inter' : value === 'Done' ?
                                          'Done-inter' : value === 'New' ?
                                            'New-inter' : value === 'Checking' ?
                                              'Checking-inter' : value === 'Active' ?
                                                'Active-inter' : value === 'Paused' ?
                                                  'Paused-inter' : value === 'Expired' ?
                                                    'Expired-inter' : value === 'Finished' ?
                                                      'Finished-inter' : ''}>
                                        {column.id === 'OrderTotalTotal' || column.id === 'OSInvoiceTotalTotal' || column.id === 'OrderSellerTotalTotal' || column.id === 'SPaymentTotal' || column.id === 'CoursePrice' || column.id === 'ServiceInfo.PlanInfo.PlanPrice' || column.id === 'SSubValue' ?
                                          Functions.formatPriceC(value, currency) + ' ' + currency :
                                          column.id === 'OrderDate' || column.id === 'OrderSellerDate' || column.id === 'SPaymentDate' || column.id === 'OSInvoiceRequestDate' || column.id === 'SSubStartDate' || column.id === 'SSubNextRenew' ?
                                            Functions.fechaFormat(value) :
                                            // value : 
                                            column.id === 'Descripcion' ?
                                              <span className={"lista" + index}>
                                                {Functions.renderHTML("lista" + index, value)}
                                              </span> :
                                              column.id === 'carrito' ?
                                                <div className="pointer" onClick={getcart}>
                                                  <i className="material-icons">shopping_cart</i>
                                                </div> :
                                                value}</span>}
                            {/* {btn1 === undefined && btn1 === true && value === '' ?'boton':'no boton'} */}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={body.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          className="ml-auto w-100"
        />
      </div >
    )
  }
  else {
    return (
      <Loader />
    )
  }
}
