import React, { useCallback, useEffect, useState } from "react";
import CardComponent from "../../../components/Others/CardComponet";
import BannerPlans from "../../../components/Plans/BannerPlans";
// import APIS from "../../../hooks/apis";
import BreadcrumsClass from "../../../js/BreadcrumsClass";
import Functions from "../../../hooks/functions";
import LinkRef from "../../../components/Others/LinkRef";
import wrapper from "../../../hooks/wrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignal } from "@fortawesome/free-solid-svg-icons";
import ButtonBuyPlan from "../../../components/Plans/ButtonBuyPlan";
import $ from "jquery"

export default function GroupPlans(params) {
    const [ListBreads, setListBreads] = useState([])
    const [Data, setData] = useState({})
    const [Cursos, setCursos] = useState([])
    const [Name, setName] = useState('')
    const [Type, setType] = useState('')
    const handdleCollapse = (position) => {
        var arow = document.querySelector(".expand" + position);
        var coll = document.getElementsByClassName("collapsible-body");
        if (coll[position].classList.contains('d-block')) {
            coll[position].classList.remove('d-block')
            arow.innerHTML = "expand_more"
        } else {
            for (let a = 0; a < coll.length; a++) {
                coll[a].classList.remove('d-block')
            }
            coll[position].classList.toggle('d-block')
            arow.innerHTML = "expand_less"
        }
    }
    const getGroupPlans = useCallback(async (id) => {
        // console.log(id.split('/'));
        let dataGroupPlans = await wrapper.getDataU('plans/' + id.split('/')[3])
        // console.log(dataGroupPlans?.data?.data);
        setData(dataGroupPlans?.data?.data)
        setType(id.split('/')[3])
        let bread = new BreadcrumsClass()
        bread.addBreadcrum('Store', '/plans')
        bread.addBreadcrum(dataGroupPlans?.data?.data?.PGroupName, '')
        setListBreads(bread)
        if (dataGroupPlans?.data?.data !== undefined) {
            if (dataGroupPlans?.data?.data?.Plans === undefined) {
                // console.log('cas');
                dataGroupPlans.data.data.Plans = dataGroupPlans?.data?.data?.PlanCourses
                setCursos(Object.keys(dataGroupPlans?.data?.data?.Plans))
            } else {
                setCursos(Object.keys(dataGroupPlans?.data?.data?.Plans))
            }
        }
    }, [])
    const seeMore = (e, i) => {
        var self = $("." + e.target.classList[2]);
        self.next().toggle("slow");
        self.addClass("d-none");
    }
    useEffect(() => {
        var name = decodeURI(window.location.pathname.split('/')[3])
        getGroupPlans(window.location.pathname)
        setName(name + '' + window.location.search)
        document.cookie = "UrlPlans=" + window.location.pathname;
        $(".oculto").hide();

    }, [getGroupPlans])
    return (
        <div className="white pb-40">
            <BannerPlans breads={ListBreads}>
                <div className="row sm">
                    <div className="col s12 m12">
                        <div className="white-text center-align">
                            <h1 className="white-text left-align">{Data?.PGroupName}</h1>
                            <p className="left-align">{Data?.PGroupDescription}</p>
                            {/* <div className="mb-30">
                                <span className="circle p-5 white"> <i className="material-icons md-36  dark-text p-relative top-7">share</i></span>
                                <span className="ml-10">Compartir</span>
                            </div> */}
                        </div>
                    </div>
                </div>
            </BannerPlans>
            <div className="container">
                {Type === "certifications" ?
                    <h5 className="dark-text">Selecciona la formación para la certificación de tu interés <p className="subtitle3"> <span class="material-icons-outlined note-icon">
sticky_note_2
</span>No se incluye examen de certificación del fabricante.</p></h5>
                    :
                    <h5 className="dark-text">Suscribete a la especialización de tu interés </h5>
                }
                
                <div className="row">
                    {Cursos?.map((e, i) => {
                        // console.log(Data.Plans[e]);
                        return (
                            <div className="col s12 m6 l4 xl4 sm" key={"plans" + i}>
                                <CardComponent clas="pl-10 pr-10 pt-10 expand-card hoverable card-store-sell">
                                    <LinkRef to={'/plans/' + Name + '/' + Data?.Plans[e]?.PlanSlug}>
                                        <p title={Data?.Plans[e]?.PlanName} className="dark-text hpx-55 subtitle1 truncate-2 ">{Data?.Plans[e]?.PlanName}</p>
                                    </LinkRef>
                                    {Type === "certifications" ?
                                        <div className="row mt-20">
                                        
                                            <div className="col mt-10 s6 centrar">
                                                <i className="material-icons md-36 mr-10">access_time</i>
                                                <p className="time-icon">{Data?.Plans[e].PlanDuration} hr</p>
                                            </div>
                                            {Data?.Plans[e].PlanLevel !== 0 ?
                                                <div className="col mt-10 s6 centrar">
                                                     {/*aqui va el segmento de nivel*/}
                                                    <p className="time-icon">Nivel</p>
                                                </div>
                                                : ""}
                                        </div> : ""
                                    }
                                    {Type !== "certifications" ?
                                        <div>
                                            <p><strong>Descripción</strong></p>
                                            {/* <p className="text-overflow" title={Data?.Plans[e].PlanDescriptionShort}>{Data?.Plans[e].PlanDescriptionShort}</p> */}
                                            <span title={Data?.Plans[e].PlanDescriptionShort} className="" >{Functions.seeMoreText(Data?.Plans[e].PlanDescriptionShort).content}
                                            </span>
                                            <p  onClick={(e) => seeMore(e, i)} className={"pointer truncate-3 " + i}> </p>
                                            <span className="oculto" style={{ display: "none" }}>
                                                {Functions.seeMoreText(Data?.Plans[e].PlanDescriptionShort).more}
                                            </span>
                                        </div>
                                        : ''}
                                    {Type !== "certifications" ?
                                        <div className="">
                                            <p className="truncate"><strong>Objetivo</strong></p>
                                            {/* <p className="text-overflow" title={Data?.Plans[e].PlanObjective}>{Data?.Plans[e].PlanObjective}</p> */}
                                            <div className=" truncate-2 cont-card" dangerouslySetInnerHTML={{ __html: '<ul>' + Functions.seeMoreText(Data?.Plans[e].PlanObjective).content + '</ul>' }}></div>
                                            <span onClick={(e) => seeMore(e, i)} className={"red-text truncate-2  " + i}> </span>
                                            <div className="oculto" style={{ display: "none" }} dangerouslySetInnerHTML={{ __html: '<ul>' + Functions.seeMoreText(Data?.Plans[e].PlanObjective).more + '</ul>' }}></div>
                                            {/* <span className="oculto" style={{ display: "none" }}>
                                                {Functions.seeMoreText(Data?.Plans[e].PlanObjective).more}
                                            </span> */}
                                        </div>
                                        : ''}
                                    {Type !== "certifications" ?
                                        <div className="row mt-20">
                                            <div className="col s5 section-hr-lv">
                                                <i className="material-icons md-36 mr-10 left">access_time</i>
                                                <p className="time-icon">{Data?.Plans[e].PlanDuration} hr</p>
                                            </div>
                                           <div className="col s5 ">
                                                
                                                <p className="time-icon">Nivel</p>
                                            </div>
                                        </div> : ""
                                    }
                                    <strong>Microcursos</strong>
                                    <div className="pt-20">
                                        {Functions.seeMore(Data?.Plans[e].PlanCourses, 2)?.view?.map((f, z) => {
                                            return (
                                                <div className="row" key={"cursos" + z}>
                                                    <div className="col s12 d-flex">
                                                        <small className="pt-2 pb-2 p-12 bg-orange white-text etiq">{z + 1}</small>
                                                        <small className="ml-10 body2 truncate" title={f.PCourseName} >{f.PCourseName}</small>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                   
                                    <div className="row mt-20">

                                        <div className="col s12 mt-10">
                                          
                                                    <LinkRef to={'/plans/' + Name + '/' + Data?.Plans[e]?.PlanSlug}>
                                                        <button className="btn orange-btn-h btn-text-h w-100">
                                                            <small>Ver completo</small>
                                                        </button>
                                                    </LinkRef>
                                               
                                            <ButtonBuyPlan id={Type + '/' + Data?.Plans[e]?.PlanSlug} text={"Suscribirse " + Data?.Plans[e]?.PlanPricePublic +" "+ Data?.Plans[e]?.PlanPriceCurrency + "/Mes"} />
                                        </div>
                                    </div>
                                </CardComponent>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}