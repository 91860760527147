import React, { useCallback, useEffect, useState } from "react";
import wrapper from "../../hooks/wrapper";
import BreadcrumsClass from "../../js/BreadcrumsClass";
import { useDispatch } from "../../store/StoreProvider";
import Paginador from "../../components/paginador";
import Funciones from "../../hooks/functions";
import { useHistory } from "react-router";

const headerss = {
    head1: [
        // { id: 'SSubID', label: 'ID', minWidth: 100, align: 'center' },
        { id: 'ServiceInfo.PlanInfo.PlanName', label: 'Nombre del plan', minWidth: 200, align: 'center' },
        { id: 'SSubStartDate', label: 'Fecha de incio', minWidth: 100, align: 'center' },
        { id: 'SSubNextRenew', label: 'Fecha de renovación', minWidth: 100, align: 'center' },
        { id: 'SSubGatewayCode', label: 'Metodo de pago', minWidth: 100, align: 'center' },
        { id: 'SSubPaymentFrequency', label: 'Periodo de pago', minWidth: 100, align: 'center' },
        { id: 'SSubValue', label: 'Total', minWidth: 100, align: 'center' },
        { id: 'SSubStatusName', label: 'Estado', minWidth: 100, align: 'center' },
    ]
}
export default function Subscriptions(params) {
    const history = useHistory()
    const Dispatch = useDispatch()
    // const [DataSuscriptions, setDataSuscriptions] = useState({})
    const [Items, setItems] = useState([])
    // console.log(Store.typeUser.user_id);
    const getDataSuscriptions = useCallback(async () => {
        let users = await wrapper.getDataU('users')
        console.log(process.env.NODE_ENV);
        // console.log(users?.data?.data.user_full_name);
        if (process.env.NODE_ENV !== "development") {
            if (users?.data?.data?.user_full_name === "Invitado") {
                history.push("/404")
            }    
        }
        // console.log();
        // let dataSuscriptions = await wrapper.getDataU(process.env.NODE_ENV === "development"?"subscriptions/users/11653":'subscriptions/users/'+users?.data?.data?.user_id)
        let dataSuscriptions = await wrapper.getDataU('subscriptions/users/'+users?.data?.data?.user_id)
        // setDataSuscriptions(dataSuscriptions?.data?.data?.Subscriptions)
        if (dataSuscriptions?.data?.data?.Subscriptions !== undefined) {
            if (Funciones.TypeData(dataSuscriptions?.data?.data?.Subscriptions) === "array") {
                history.push("/")
            }else{
                setItems(Funciones.convertObjInArr(dataSuscriptions?.data?.data?.Subscriptions))
            }
            // console.log(dataSuscriptions?.data?.data?.Subscriptions);
        }else{
            history.push("/")
        }
        // console.log(dataSuscriptions);
    }, [history])
    const dataDescription = (data) => {
        console.log(data);
    }
    useEffect(() => {
        let bread = new BreadcrumsClass()
        bread.addBreadcrum('Tienda', "/")
        bread.addBreadcrum('Mis Suscripciones', "")
        Dispatch({ type: 'addBread', data: bread })
        getDataSuscriptions()
    }, [Dispatch, getDataSuscriptions])
    return (
        <div className="p-5 w-80 m-auto">
            <h5>Subscriptions</h5>
            <Paginador headers={headerss.head1} body={Items} link="ServiceInfo.PlanInfo.PlanName" dataDescription={dataDescription} redirect="subscriptions" list="suscrip" idName="SSubID"/>
        </div>
    )
}