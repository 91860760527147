import React from "react";
import Url from "../../hooks/url";
import axios from "axios";
import Paginador from "../paginador";
// import Apis from "../../hooks/apis";
import Wrapper from "../../hooks/wrapper";
import {messageService} from "../../hooks/global";
import Loader from "../loader";
import NavAuthor from "./navAuthor";

const headerss = {
    head1:[
        { id: 'OSInvoiceID', label: 'ID', minWidth: 170, align: 'center'},
        { id: 'OrderSellerID', label: 'ID Venta', minWidth: 100, align: 'center'},
        { id: 'OSInvoiceRequestDate', label: 'Fecha', minWidth: 170, align: 'center'},
        { id: 'OSInvoiceTotalTotal', label: 'Precio', minWidth: 170, align: 'center'},
        { id: 'OSInvoiceStatusName', label: 'Estatus', minWidth: 170, align: 'center'},
    ]
}
class listInvoice extends React.Component{
    constructor(){
        super();
        this.state={
            data:{},
            dataG:{},
            nav:{}
        }
        this.dataDescription = this.dataDescription.bind(this);
    }
    componentDidMount(){
        messageService.sendMessage('Tienda,/ > Autor > Facturas','bread')
        // this.setState({nav:Apis.navbar})
        this.getUser()
    }
    async getUser(){
        const user = await Wrapper.getData(Url+'users')
        // console.log(user);
        this.getData(Url+'orders_sellers/'+user.data.data.author_id+'/author/invoices','data')
    }
    async getData(data,st){
        const orders = await Wrapper.getData(data)
        // console.log('orders');
        // console.log(orders);
        if (orders !== undefined) {
            this.setState({[st]:orders.data.data})
            this.getKeys()
        }else{
            window.location = '404'
        }
    }
    getKeys() {
        // const {headers,keyList,data,changeVista,vista} = props
        const {data} = this.state
        let llaves = []
        let keys = Object.keys(data)
        keys = keys.sort(this.ordenarNumeros)
        for (let a = 0; a < keys.length; a++) {
            llaves.push(data[keys[a]])
        }
        this.setState({dataG:llaves})
    }
    ordenarNumeros(valor1, valor2) {
        return valor2 - valor1;
    }
    dataDescription(datos,id){
        let data = this.state.dataG
        data.forEach(e => {
            if (e.OrderSellerID === id) {
                localStorage.setItem('OrderF',e.OrderID)
            }
        });
    }
    solicitar(){
        axios.post(Url+'orders_sellers/1/author/payment',{withCredentials:true})
        .then(res=>{
            console.log(res);
        })
        .catch(error=>{
            console.log(error.reponse);
        })
    }
    render(){
        if (Object.keys(this.state.data).length === 0) {
            return (
                <div>
                    {/* {console.log('home')} */}
                    <Loader />
                </div>
            )
        }else{
            const {dataG} = this.state
            return(
                <div>
                    <div className="row2 row w-100 sm white-text text-white">
                        <NavAuthor/>
                        <div className="col l10">
                            <div className="w-90 hminvh-75 m-auto black-text text-black" >
                                <div className="row text-left">
                                    <div className="col s12 l12"><h5 className="sm mt-20">Mis facturas</h5></div>
                                </div>
                                <Paginador headers={headerss.head1} body={dataG} link="OSInvoiceID" link2="OrderSellerID" redirect="author/invoices" redirect2="author/sales" dataDescription={this.dataDescription} list="invoice"/>
                            </div>
                        </div>
                    </div>
            
                    {/* <Foter /> */}
                </div>
            )
        }
    }
}

export default listInvoice