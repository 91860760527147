import React, { useCallback, useEffect, useState } from "react";
import BannerPlans from "../../../components/Plans/BannerPlans";
import CardComponent from "../../../components/Others/CardComponet";
import BreadcrumsClass from "../../../js/BreadcrumsClass";
import * as $ from "jquery";
import M from "materialize-css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faSignal } from "@fortawesome/free-solid-svg-icons";
import Apis from "../../../hooks/apis";
// import LinkRef from "../../../components/Others/LinkRef";
import wrapper from "../../../hooks/wrapper";
import { useStore } from "../../../store/StoreProvider";
import Loader from "../../../components/loader";
import ButtonBuyPlan from "../../../components/Plans/ButtonBuyPlan";
import funciones from "../../../hooks/functions";
// import { event } from "jquery";
export default function Plan(params) {
    const Store = useStore()
    const [ListBreads, setListBreads] = useState([])
    const [DataPlan, setDataPlan] = useState({})
    const [vista, setVista] = useState("1")
    const [Cursos, setCursos] = useState([])
    const [IdPlan, setIdPlan] = useState([])
    const [TypePlan, setTypePlan] = useState("")
    const [Message, setMessage] = useState("")
    const [Curses, setCurses] = useState("")
    const [Origen, setOrigen] = useState("")
    const [CoursesLevels, setCoursesLevels] = useState([]);
    const getDataPlan = useCallback(async (id) => {
        // console.log(id);
        if (id === "a-tu-ritmo-8") {
            id = "industry"
            setTypePlan("industry")
        }
        let plan = await wrapper.getDataU('plans/' + id)

        let levelCourses = await wrapper.getDataU('courses/catalog/levels/all/');

        setCoursesLevels(levelCourses?.data?.data);

        // console.log(plan);
        let breads = window.location.href.split('/')
        let bread = new BreadcrumsClass()
        bread.addBreadcrum('Store', '/plans')
        if (breads.length > 5) {
            bread.addBreadcrum(decodeURI(breads[4]).split('?')[0], '/plans/grupo/' + breads[4])
            bread.addBreadcrum(plan.data.data.PlanTitle, '')
        } else {
            bread.addBreadcrum(plan?.data?.data?.PlanTitle, '')
        }
        setIdPlan(id)
        setListBreads(bread)
        setDataPlan(plan?.data?.data)
        if (plan?.data?.data !== undefined) {
            if (plan?.data?.data?.PlanCourses !== undefined) {
                setOrigen(plan?.data?.data?.PlanCourses)
                setCurses(plan?.data?.data?.PlanCourses)
                setCursos(Object.keys(plan?.data?.data?.PlanCourses))
            } else {
                plan.data.data.PlanCourses = plan?.data?.data?.Plans
                setOrigen(plan?.data?.data?.PlanCourses)
                setCurses(plan?.data?.data?.PlanCourses)
                setCursos(Object.keys(plan?.data?.data?.PlanCourses))
            }
        }
    }, [])


    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearch()
        }
    }
    const handleSearch = (buscar) => {
        window.event.preventDefault();
        let val = document.querySelector("#search")
        // console.log(buscar);
        var search = []
        if (buscar !== undefined) {
            search = funciones.searchInObject(Origen, buscar, ["PCourseLevel"])
        } else {
            search = funciones.searchInObject(Origen, val.value, ["PCourseDescription", "PCourseDuration", "PCourseLevel", "PCourseName"])
        }
        setCurses(search)
        setCursos(Object.keys(search))

        let courses = Object.keys(search);

        if(courses.length === 0)
        {
            setMessage("No hay resultados que coincidan con la busqueda.")
        }
        else
        {
            setMessage("")
        }

    }
    useEffect(() => {
        // console.log("Store.typeUser");
        // console.log(Store);
        // console.log(Store.typeUser);
        // console.log(Store.typeUser === "");
        // console.log(process.env.NODE_ENV);
        // console.log("//////////");
        document.cookie = "UrlPlans=" + window.location.pathname;
        $(document).ready(function () {
            M.updateTextFields();
        });
        let id = window.location.pathname.split('/').slice(2, 4).join('/')
        getDataPlan(id)
    }, [getDataPlan])

    return (
        Store.typeUser === "" ?
            <Loader /> :
            <div className="white">
                <BannerPlans breads={ListBreads}>
               
                    <div className="row sm">
                        <div className="col s12 m8 l8 xl8 pad-import">
                            <div className="white-text">
                                <h1 className="white-text">{DataPlan?.PlanName}</h1>
                                <p>{DataPlan?.PlanDescription}</p>
                                {/* <div className="mb-20">
                                    <span className="circle p-5 white"> <i className="material-icons dark-text p-relative top-7">share</i></span>
                                    <span className="ml-10">Compartir</span>
                                </div> */}
                            </div>
                        </div>
                        <div className="col s12 m4 l4 xl4">
                            <CardComponent>
                                
                                <div className="row">
                                    <div className="col s12 m12 l6 mt-10">
                                        {/* {console.log('********')}
                                    {console.log(Store.typeUser)} */}
                                        <ButtonBuyPlan id={IdPlan} text="Suscríbete" />
                                    </div>
                                    <div className="col s12 m12 l6 mt-10 centrar">
                                        {
                                            console.warn(DataPlan)
                                        }

                                        <strong className="text-orange">${DataPlan?.PlanPricePublic} {DataPlan?.PlanPriceCurrency} /mes</strong>
                                    </div>
                                </div>
                            </CardComponent>
                        </div>
                    </div>
                    
                </BannerPlans>
                
                <CardComponent clas="sm height-nav">
                    <form className="w-80 m-auto w-190">
                        <div className="row sm">
                        
                            <div className="input-field input-outlined col s12 m7">
                                <input onKeyPress={handleKeyPress}  type="text" id="search"  defaultValue={""} />
                                <i onClick={() => handleSearch()} className="material-icons pointer prefix icon-search ">search</i>
                                <label htmlFor="search">Buscar curso</label>
                            </div>
                            <div className="input-field input-outlined col s12 m2 ">
                              {/*  <i className="material-icons prefix icon-search">view_list</i>*/}
                                <select value={vista} onChange={(e) => setVista(e.target.value)}>
                                    <option value={1}>Mosaico</option>
                                    <option value={2}>Lista</option>
                                </select>
                            </div>
                            <div className="input-field input-outlined col s12 m2">
                                {/* <div className="icon-float">
                                <FontAwesomeIcon icon={faFilter} />
                            </div> */}
                                {/*  <i className="material-icons prefix icon-search  small top-1 left-17">
                                  <FontAwesomeIcon className="fa-1x" icon={faFilter} />
                                </i >*/}
                                {/* <i className="material-icons prefix">view_list</i> */}

                                {
                                    //Object.keys(CoursesLevels).map((key) => [Number(key), CoursesLevels[key]])
                                }

                                <select defaultValue={""} onChange={(e) => handleSearch(e.target.value)}>
                                    <option value=""  >Selecciona un nivel</option>
                                    {
                                        Object.keys(CoursesLevels).map(function(key, index)
                                        {
                          
                                            return(<option value={key}>Nivel {key+" ("+CoursesLevels[key]+")"}</option>)
                                            
                                        })
                                    }

                                    {/*<option value=""  >Selecciona un nivel</option>*/}
                                    {/*<option value="A">Nivel A</option>*/}
                                    {/*<option value="B">Nivel B</option>*/}
                                    {/*<option value="E">Nivel E</option>*/}
                                    {/*<option value="I">Nivel I</option>*/}

                                </select>
                            </div>
                        </div>
                    </form>
                </CardComponent>
                <div className="container">
                <h2 className="dark-text uppercase-letters"> Este plan contiene:</h2>


                        <div>
                            {DataPlan?.PlanDescription  ?
                                <div>
                                    <p><strong>Descripción</strong></p>
                                    <p>{DataPlan?.PlanDescription}</p>
                                </div>
                                : ''}
                            {DataPlan?.PlanObjective ?
                                <div className="">
                                    <p className="mt-40"><strong>Objetivo</strong></p>
                                    <div dangerouslySetInnerHTML={{ __html: '<ul>' + DataPlan?.PlanObjective?.split('*')?.join('<li> *') + '</ul>' }}></div>
                                    {/* <p>{DataPlan?.PlanObjective.split('*').join('<br/>*')}</p> */}
                                    <p className="mt-40"><strong>Cursos</strong></p>
                                </div>
                                : ''}
                        </div>
                    <div className="row">

                        {Message ?
                        <p className="text-center">
                            <h6>
                                {Message}
                            </h6>
                            <br/>
                        </p> : ""}

                        {Cursos?.map((e, i) => {
                            // console.log(e);
                            // console.log(Curses);
                            return (
                                // <div></div>
                                vista === "1" ?
                                    <div key={"listIndusti" + i} className="col s12 m6 l4 sm">
                                    
                                        <CardComponent clas="min-h-card-planes-cursos hoverable" media={<img src={Curses[e].PCourseImage} alt={"item" + i} />} >
                                            <p className="dark-text hpx-55 subtitle1 truncate" title={Curses[e].PCourseName}><strong className="dark-text">{Curses[e].PCourseName}</strong></p>
                                            <p className="truncate-3 cardtext" title={Curses[e].PCourseDescription}>{Curses[e].PCourseDescription}</p>
                                            <p className="centrar mt-10 time-icon"><i className="material-icons prefix mr-10 md-36">access_time</i>{Curses[e].PCourseDuration} hr</p>


                                            <div className="row mt-10">
                                                <div className="col s6">
                                                    <div className="centrar time-icon">
                                                        Nivel {Curses[e].PCourseLevel}
                                                    </div>
                                                </div>

                                                {Curses[e].PCourseRatingPersons ?

                                                <div className="col s6 right-align">
                                                        <div className="centrar time-icon">
                                                            <i className="material-icons prefix mr-10 ml-auto md-36">grade</i>
                                                            {Curses[e].PCourseRating} ({Curses[e].PCourseRatingPersons})
                                                        </div>
                                                    </div>

                                                :

                                                <div className="col s6 right-align">
                                                </div>

                                                }

                                            </div>
                                        </CardComponent>
                                    </div> :
                                    <div className="col s12" key={"listIndusti" + i}>
                                        <CardComponent clas="pl-20 pr-20 hoverable">
                                            <div className="row sm">
                                                <div className="col s6">
                                                    <h6><strong className="dark-text">{Curses[e].PCourseName}</strong></h6>
                                                </div>
                                                <div className="col s6 right-align">
                                                    <div className="centrar mt-10 ">
                                                        <span className="ml-auto centrar time-icon">
                                                            <i className="material-icons prefix mr-10 ml-auto md-36">access_time</i>{Curses[e].PCourseDuration} hr
                                                        </span>
                                                        <span className="ml-20 time-icon">
                                                          
                                                            Nivel {Curses[e].nivel}
                                                        </span>
                                                        <span className="ml-20 centrar time-icon">
                                                            <i className="material-icons prefix mr-10 ml-auto md-36">grade</i>
                                                            {Curses[e].calif} ({Curses[e]?.PCourseRatingPersons})
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardComponent>
                                    </div>

                            )
                        })}
                    </div>
                </div>
            </div>
    )
}