import React from "react";
// import Router from '../router/router';
import StoreProvider from "../../store/StoreProvider";
import RouterApp from "../../router/router";
export default function Principal(params) {
    return (
        <div className="App">
            <StoreProvider>
                <RouterApp />
            </StoreProvider>
        </div>
    );
}