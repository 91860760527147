import Axios from "axios";
import url from "./url";
const wrapper = {
    configAxios(cookie) {
        // console.log(cookie);
        Axios.defaults.headers.common['Authorization'] = 'Bearer ' + cookie;
    },
    getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        // console.log(ca);
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    },
    async getData(request) {
        try {
            var res = {}
            if (window.location.host === 'localhost:3000') {
                // console.log(request);
                res = await Axios.get(request)
                    .catch(error => {
                        console.log(error.reponse);
                    })
            } else {
                res = await Axios.get(request, {withCredentials: true})
            }
            // console.log(res);
            return await res
        } catch (error) {
            console.log('error.reponse');
            console.log(error);
            if (error === undefined) {
                console.log('wraper');
                window.location.href = '/404'
            }
            if (error.reponse === undefined) {
                window.location.href = '/404'
            }
        }
    },
    async getDataU(request){ 
        try {
            var res = {}
            res = await Axios.get(url + request, {withCredentials: true})
            // console.log(res);
            return await res
        } catch (error) {
            // console.log(error.response);
            return await error.response
        }
    },
    async postDataN(request, params) {
        try {
            var res = {}
            res = await Axios.post(url + request, params, {withCredentials: true})
            // console.log(res);
            return await res
        } catch (error) {
            // console.log(error.response);
            return await error.response
        }
    },
    async postData(request, params) {
        try {
            var res = await Axios.post(request, params, {withCredentials: true})
                .catch(error => {
                    console.log("error.response");
                    console.log(error.response);
                    res = error.response
                })
            return await res
        } catch (error) {
            // console.log(error.reponse);
            // return await error.reponse
        }
    },
    async deleteDataN(request, params) {
        try {
            var res = {}
            res = await Axios.delete(url + request, params, {withCredentials: true})
            // console.log(res);
            return await res
        } catch (error) {
            // console.log(error.response);
            return await error.response
        }
    },
    async deleteData(request, data) {
        try {
            const res = await Axios.delete(request, {withCredentials: true, data})
                .catch(error => {
                    console.log(error.reponse);
                })
            return await res
        } catch (error) {
            console.log(error.reponse);
        }
    },
}

export default wrapper