import { Radio } from "@material-ui/core";
import React from "react";
import GetImg from "../getImgs";

export default function CardDefault(params) {
    return (
        <div>
            <h5>Escoja su método de pago</h5>
            <div className="card">
                <div className="row">
                    <div className="col s3 centrar">
                        <GetImg type="3" class="w-px-80 m-auto" />
                    </div>
                    <div className="col s6">
                        <p>{params?.data?.name}</p>
                        <p>**** {params?.data?.last4}</p>
                        <p>Fecha de inspiración: {params?.data?.exp_month}/{params?.data?.exp_year}</p>
                    </div>
                    <div className="col s3 centrar">
                        <Radio
                            checked={true}
                            onClick={() => params?.cambio(false)}
                            value="b"
                            name="radio-button-demo"
                            inputProps={{ 'aria-label': 'B' }}
                        />
                    </div>
                </div>
            </div>
            <h6 onClick={() => params?.cambio(false)} className="blue-text pointer">Añadir otro metodo de pago</h6>
        </div>
    )
}