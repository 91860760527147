import React, { useEffect, useState } from "react";
import Functions from "../hooks/functions";
import LinkRef from "./Others/LinkRef";
import ButtonBuy from "./methodsBuy/ButtonBuy";
export default function Card(props) {
    const [Ruta, setRuta] = useState('')    
    useEffect(() => {
        let rut
        if (props?.data?.Type === "xpertclass") {
            rut = '/Class/' + props?.data?.CourseName.replace(/ /g, '-') + '?' + props?.data?.CourseID
        } else {
            rut = '/Courses/' + props?.data?.CourseName.replace(/ /g, '-') + '?' + props?.data?.CourseID
        }
        // console.log(rut);
        setRuta(rut)
        // console.log(props);
    }, [props])
    return (
        <div>
            {/* {this.alerta()} */}
            <div className={"card " + props?.position}>
                <LinkRef to={Ruta} clas="card-image pointer">
                    {props?.data?.AuthorStick !== undefined ?
                        <div className="logo-autor"><embed className="h-100" src={props?.data?.AuthorStick} title="curso" /></div> :
                        <div></div>
                    }
                    {props?.data?.CourseThumbnail !== undefined ?
                        <img className="h-100" src={props?.data?.CourseThumbnail} alt="curso" /> :
                        <img className="h-100" src={props?.data?.CourseImage} alt="curso" />
                    }

                </LinkRef>

                <div className="card-stacked">
                    <div className="card-content">
                        <div className="row sm">
                            <small className="col s8 l8 left-align sp text-overflow">
                                <strong className="resalta" title={props?.data?.CourseName}>{props?.data?.CourseName}</strong>
                            </small>
                            <small className="col s4 l4 text-orange">
                                <strong>{Functions.formatPrice(props?.data?.CoursePrice)} {props?.data?.CourseCurrency}</strong>
                            </small>
                        </div>
                        {props?.data?.CourseDescriptionShort === '' ?
                            <p className="left-align mt-20 truncate">{props?.data?.CourseDescriptionShort}</p>
                            : <div></div>}
                        <p className="left-align"><small className="mt-20">Creado por <strong>{props?.data?.AuthorName}</strong></small></p>
                        <div className="row mt-20 mb-0 categ-card hpx-50">
                            {props?.data?.CourseCategory !== null && props?.data?.CourseCategory !== '' ?
                                <small className="col s6 l6 left-align centrar">
                                    <i className="material-icons-outlined text-orange">category</i> {props?.data?.CourseCategory}
                                </small>
                                : <div className=""></div>}
                            {props?.data?.CourseDuration !== null ?
                                <small className="col s4 l4 left-align centrar sm">
                                    <i className="material-icons-outlined md-36 text-orange ">access_time</i> Horas: {props?.data?.CourseDuration}
                                </small>
                                : <div className="col s4 l4"></div>}
                            <small className="col s2 l2 right-align centrar">
                                <LinkRef to={Ruta} className="waves-effect waves-light-blue ml-auto">
                                    <i className="material-icons-outlined md-36 text-orange ml-auto">arrow_forward</i>
                                </LinkRef>
                            </small>
                        </div>
                        {props?.data?.CourseLevel !== "" ?
                            <div className="text-left">
                                <i className="material-icons-outlined text-orange">trending_up</i>
                                Nivel: {props?.data?.CourseLevel}
                            </div>
                            : ''}
                    </div>
                    <div className="card-action">
                        {/* <button className="waves-effect btn bg-orange w-100 centrar carrito" > */}
                        {/* <ButtonBuy data={props?.data} ruta={Ruta}/> */}
                        <ButtonBuy text="AGREGAR AL CARRITO" icon="shopping_cart" clas={"waves-effect btn bg-orange w-100"} data={props?.data} ruta={Ruta}/>
                    </div>
                </div>
            </div>
            {/* {this.popup()} */}
        </div >
    )
}

