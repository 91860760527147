import React, { useEffect, useCallback, useState } from 'react';
import Functions from "../hooks/functions";
import wrapper from "../hooks/wrapper";

import Product from '../components/product';
import VisorPDF from '../components/visorPDF';
import GetImgs from '../components/getImgs';
import GetContent from '../components/Others/checkout/getContent';
import { Link } from 'react-router-dom';
import PopFactura from '../components/Plans/popFactura';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import GetImg from '../components/getImgs';
import BreadcrumsClass from '../js/BreadcrumsClass';
import { useDispatch } from '../store/StoreProvider';
import Loader from '../components/loader';
import NewSubscriptions from '../components/subscriptions/NewSubscriptions';
// import PopFactura from '../components/Author/popFactura';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

const id = '1847'

const SingleOrden = () => {
    const Dispatch = useDispatch()
    const [ordenData, setOrdenData] = useState({})
    const [subOrdens, setSubOrdens] = useState({})
    const [subOrdensKeys, setSubOrdensKeys] = useState([])
    const [orderSellerId, setOrderSellerId] = useState('')
    const [checkFactura, setCheckFactura] = useState(false)
    const [activarFactura, setActivarFactura] = useState(false)
    const [Float, setFloat] = useState(false)
    const [popVisorPdf, setPopVisorPdf] = useState(false)
    const [selectMB, setSelectMB] = useState('')
    const [compra, setCompra] = useState(false)
    const [cardError, setCardError] = useState(false)
    const [SuccesBuy, setSuccesBuy] = useState(false)
    const [BillingInfo, setBillingInfo] = useState({})
    const [Default, setDefault] = useState(false)
    const [Terminos, setTerminos] = useState(false)
    // const [LoaderActive, setLoaderActive] = useState(false)

    // const [cardError, setCardError] = useState(false)
    const getSingleOrden = useCallback(async (id) => {
        setSubOrdensKeys([])
        const orders = await wrapper.getDataU('orders/' + id)
        // console.log(orders.data.data);
        if (orders !== undefined) {
            orders.data.data.RequestInvoice = 1
            setOrdenData(orders.data.data)
            if (orders?.data?.data?.PaymentList !== undefined) {
                if (Functions.TypeData(orders?.data?.data?.PaymentList) !== "array") {
                    setCardError(Object.keys(orders?.data?.data?.PaymentList[3]))
                }
            }
            if (orders.data.data.BillingInfo !== undefined) {
                setBillingInfo(orders.data.data.BillingInfo)
            }
            // console.log(orders);
            if (orders?.data?.data?.PaymentMethods !== undefined) {
                if (orders?.data?.data?.PaymentMethods[3]?.default !== undefined) {
                    // console.log("si hay default");
                    setDefault(true)
                }
            }
            let keys = Object.keys(orders.data.data.SubOrders)
            let subs = {}
            keys.forEach(e => {
                subs[e] = Functions.bodyDefault(orders.data.data, 'SubOrders-' + [e], ['InvoiceInfo', 'AuthorName'])
            })
            // console.log("subs");
            // console.log(subs);
            setSubOrdens(subs)
            if (orders.data.data.OrderType === "Service") {
                // console.log("single orden");
                setSubOrdensKeys([orders.data.data])
            }else{
                setSubOrdensKeys(Object.keys(subs))
            }
        } else {
            window.location.href = '/404'
        }
    }, [])
    const inicial = useCallback(() => {
        let url = window.location.pathname
        url = url.split('/')
        if (url[2] === undefined) {
            url[2] = id
        }
        let bread = new BreadcrumsClass()
        bread.addBreadcrum('Tienda', "/")
        bread.addBreadcrum('Mis ordenes', "/MyOrders")
        bread.addBreadcrum('Orden #' + url[2], "")
        Dispatch({ type: 'addBread', data: bread })
        // messageService.sendMessage('Tienda,/ > Mis pedidos,/MyOrders > Pedido #' + url[2], 'bread')
        getSingleOrden(url[2])
    }, [getSingleOrden, Dispatch])
    useEffect(() => {
        inicial()
    }, [inicial])
    const activePopFactura = (data, fac) => {
        // console.log("activePopFactura");
        // console.log(data);
        // console.log(fac);
        setCheckFactura(data)
        if (fac !== undefined) {
            setActivarFactura(fac)
        }
    }

    const getDataFacture = (data, type) => {
        // console.log("datos de la factura");
        // console.log(data);
        // console.log(type);
        switch (type) {
            case 'add':
                // ordenData.BillingInfo = data
                setBillingInfo(data)
                // setDataFacture(data)
                setActivarFactura(false)
                if (ordenData.OrderStatusName !== 'New') {
                    solicitarFactura(data)
                }
                break;
            case 'cancel':
                setCheckFactura(data)
                break;
            default:
                break;
        }
    }
    const popFacturaF = () => {
        if (checkFactura && activarFactura) {
            // console.log("popFacturaF");
            // console.log(ordenData);
            if (Object.keys(BillingInfo).length > 0) {
                // console.log('aca');
                return (
                    <PopFactura getDataFacture={getDataFacture} setActiveModal={activePopFactura} setActiveFeature={getDataFacture} data={BillingInfo} />
                )
            } else {
                // console.log('acas');
                return (
                    <PopFactura getDataFacture={getDataFacture} setActiveModal={activePopFactura} setActiveFeature={getDataFacture} />
                )
            }
        }
    }
    const solicitarFactura = async (dataInvoice) => {
        let envio = {}
        envio.OrderID = ordenData.OrderID
        envio.BillingInfo = dataInvoice
        // console.log("envio");
        // console.log(envio);

        // messageService.sendMessage('loader', 'typeAlerta');
        // messageService.sendMessage('Procesando datos...', 'alerta');
        const solicitud = await wrapper.postDataN('orders/' + orderSellerId + '/invoice_request', envio)
        // console.log(solicitud);
        if (solicitud !== undefined) {
            if (solicitud.data.error !== undefined) {
                setActivarFactura(true)
                setCheckFactura(true)
                Dispatch({ type: 'notification', data: { type: "message", open: true, vertical: 'top', horizontal: 'right', data: { name: Functions.errorCode(solicitud?.data?.error?.ErrorCode,solicitud?.data?.error?.ErrorType), type: "warning" } } })
                // messageService.sendMessage('alert-notificacion', 'typeAlerta');
                // messageService.sendMessage(Functions.getError(solicitud.data.error), 'alerta');

            } else {
                // messageService.sendMessage('alert-succes', 'typeAlerta');
                if (ordenData.OrderStatusName === 'Done') {
                    Dispatch({ type: 'notification', data: { type: "message", open: true, vertical: 'top', horizontal: 'right', data: { name: 'Factura solicitada correctamente', type: "success" } } })
                    // messageService.sendMessage('Factura solicitada correctamente', 'alerta')
                    // setBotonSolicitudF(false)
                } else {
                    Dispatch({ type: 'notification', data: { type: "message", open: true, vertical: 'top', horizontal: 'right', data: { name: 'Factura solicitada correctamente, la factura sera procesada una vez realizado el pago', type: "success" } } })
                    // messageService.sendMessage('Factura solicitada correctamente, la factura sera procesada una vez realizado el pago', 'alerta')
                }
                inicial()
            }
        } else {
            Dispatch({ type: 'notification', data: { type: "message", open: true, vertical: 'top', horizontal: 'right', data: { name: Functions.errorCode(solicitud?.data?.error?.ErrorCode), type: "warning" } } })
            // messageService.sendMessage('alert-notificacion', 'typeAlerta');
            // messageService.sendMessage('Hubo un error al procesar los datos', 'alerta');
            inicial()
        }

    }
    const viewPop = () => {
        if (popVisorPdf) {
            if (subOrdens[orderSellerId].InvoiceInfo !== undefined) {
                let dataPdf = subOrdens[orderSellerId].InvoiceInfo.OSInvoiceFileUrlPDF
                return (
                    <VisorPDF pdf={dataPdf} activePop={activePop} id={orderSellerId} title="PDF de factura" />
                )

            }
        }
    }
    const activePop = (data, sub) => {
        setPopVisorPdf(data)
        setOrderSellerId(sub)
    }
    // const handleGetFactura = (e)=>{
    //     activePopFactura(true,true) 
    //     setOrderSellerId(e)
    // }
    if (subOrdensKeys.length > 0) {
        if (ordenData?.OrderType === "Service") {
            return <NewSubscriptions Default={Default} setDefault={setDefault} BillingInfo={BillingInfo} setBillingInfo={setBillingInfo} SuccesBuy={SuccesBuy} setSuccesBuy={setSuccesBuy} compra={compra} setCompra={setCompra} selectMB={selectMB} setSelectMB={setSelectMB} checkFactura={checkFactura} setCheckFactura={setCheckFactura} activePopFactura={activePopFactura} inicial={inicial} data={ordenData} popFacturaF={popFacturaF} cardError={cardError} terminos={Terminos} setTerminos={setTerminos} setOrderSellerId={setOrderSellerId} />
        }else{
            return (
                <div className="grey lighten-4" onClick={() => Float ? setFloat(false) : ''}>
                    {popFacturaF()}
                    {viewPop()}
                    <div className="w-80 m-auto hminvh-75">
                        <div className="row d-block">
                            <div className="col s12 m12 l8 stiky-f">
                                <div className="row">
                                    <div className="col s6">
                                        <h5 className="mt-20">Pedido #{ordenData.OrderID}</h5>
                                    </div>
                                    <div className="col s6 text-right mt-12">
                                        <div>
                                            <div><span className={ordenData.OrderStatusName + '-inter p-2'}>{ordenData.OrderStatusName}</span></div>
                                            <div><small>Realizada el: {Functions.fechaFormat(ordenData.OrderDate)}</small></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card p-20">
                                    {subOrdensKeys.map((e, index) => {
                                        let keys = Object.keys(subOrdens[e].OSellerItems)
                                        const { AuthorName } = subOrdens[e]
                                        // const {AuthorName,RequestInvoice} = subOrdens[e]
                                        return (
                                            <div key={"listc" + index}>
                                                {/* {console.log(subOrdens[e])} */}
                                                {subOrdens[e].InvoiceInfo.StampError !== undefined ?
                                                    subOrdens[e].InvoiceInfo.StampError.ErrorCode === "ExternalAPI" ?
                                                        <div className="Pending-bg p-10 text-center">
                                                            <div>No fue posible generar tu factura vuelve a intentarlo</div>
                                                            <button className="btn bg-orange ml-auto mt-10" onClick={() => { activePopFactura(true, true); setOrderSellerId(e) }}>Vuelve a intentarlo</button>
                                                        </div> :
                                                        <div className="Pending-bg p-10 text-center">
                                                            <div>{subOrdens[e].InvoiceInfo.StampError.RequesterRFC}</div>
                                                            <button className="btn bg-orange ml-auto mt-10" onClick={() => { activePopFactura(true, true); setOrderSellerId(e) }}>Validar datos</button>
                                                        </div> : <div></div>}
                                                <div className="row bg-orange white-text text-white p-20 w-100 m-auto mt-10">
                                                    <div className="col s12 l6 text-left centrar">
                                                        <h6 className="white-text text-white">{AuthorName}</h6>
                                                    </div>
                                                    {subOrdens[e]?.RequestInvoice ?
                                                        <div className="col s12 l6 text-left centrar">
                                                            <button onClick={() => { setCheckFactura(true); setActivarFactura(true); setOrderSellerId(subOrdens[e].OrderSellerID) }} className="btn white text-orange ml-auto">Solicitar Factura</button>
                                                        </div>
                                                        : ""}
                                                </div>
    
                                                {keys.map((f, indice) => {
                                                    return (
                                                        <div key={"courses" + indice} className="">
                                                            <Product type={ordenData.OrderType} data={subOrdens[e].OSellerItems[f]} />
                                                            <hr />
                                                        </div>
                                                    )
                                                })}
                                                {ordenData.OrderStatusName !== 'New' ?
                                                    <div className="text-right">
                                                        <Link to={'/receipt/' + ordenData.OrderID + '/' + e} className="btn bg-orange ml-auto" onClick={window.scroll(0, 0)}>Ver recibo</Link>
                                                    </div> :
                                                    ''
                                                }
    
                                            </div>
                                        )
                                    })}
                                </div>
                                {ordenData.OrderStatusName === 'New' ?
                                    <div className="card p-20">
                                        <GetContent Default={Default} setDefault={setDefault} BillingInfo={BillingInfo} SuccesBuy={SuccesBuy} setSuccesBuy={setSuccesBuy} data={ordenData} type={'metodos'} activePopFactura={activePopFactura} selectMB={selectMB} checkFactura={checkFactura} setSelectMB={setSelectMB} vista={compra} setCompra={setCompra} inicial={inicial} />
    
                                    </div> :
                                    <div></div>
                                }
                            </div>
                            <div className="col s12 m12 l4 stiky-f" id="terminosF">
                                <div className="card p-20">
                                    <div className="text-center">
                                        <strong>Detalles del pedido</strong>
                                    </div>
                                    <hr />
                                    <div>
                                        {ordenData.OrderStatusName === 'New' ?
                                            <div>
                                                <GetContent terminos={Terminos} setTerminos={setTerminos} Default={Default} setDefault={setDefault} BillingInfo={BillingInfo} SuccesBuy={SuccesBuy} setSuccesBuy={setSuccesBuy} data={ordenData} type={'factura'} activePopFactura={activePopFactura} selectMB={selectMB} checkFactura={checkFactura} setCompra={setCompra} inicial={inicial} />
                                            </div> :
                                            <div>
                                                <div className="logoPago text-right">
                                                    {/* {console.log(ordenData)} */}
                                                    {ordenData.PaymentInfo.PaymentService !== undefined ?
                                                        <GetImgs type={ordenData.PaymentInfo.PaymentService} class='' /> :
                                                        <GetImgs type={ordenData.OrderPaymentMethod} class='' />
                                                    }
                                                    <div className="text-left">
                                                        {ordenData.PaymentInfo.PaymentService !== undefined ?
                                                            <GetContent inicial={inicial} Default={Default} setDefault={setDefault} BillingInfo={BillingInfo} SuccesBuy={SuccesBuy} setSuccesBuy={setSuccesBuy} data={ordenData} type={ordenData.PaymentInfo.PaymentService} setCompra={setCompra} /> :
                                                            <GetContent inicial={inicial} Default={Default} setDefault={setDefault} BillingInfo={BillingInfo} SuccesBuy={SuccesBuy} setSuccesBuy={setSuccesBuy} data={ordenData} type={ordenData.OrderPaymentMethod} setCompra={setCompra} />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <Link to='/MyOrders' className="btn bg-orange mt-20 w-100">Regresar</Link>
                                    </div>
                                </div>
                                {ordenData.OrderStatusName === "New" && cardError.length > 0 ?
                                    <div className="card p-20">
                                        <div className="text-center">
                                            <strong className="text-center">Intentos de Pago</strong>
                                            <hr />
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Tipo</th>
                                                        <th>Fecha</th>
                                                        <th>Detalle</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {cardError?.map((e, i) => {
                                                        return (
                                                            <tr key={'fila' + i} title={ordenData?.PaymentList[3][e]?.Message} className="pointer">
                                                                <td><FontAwesomeIcon icon={faQuestionCircle} /> <GetImg type="3" class="w-px-50" /></td>
                                                                <td>{ordenData?.PaymentList[3][e]?.Card.month + '/' + ordenData?.PaymentList[3][e]?.Card.year}</td>
                                                                <td>**** {ordenData?.PaymentList[3][e]?.Card?.number}</td>
                                                                <td>{ordenData?.PaymentList[3][e]?.Status === 'error' ? 'declinado' : ''}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div> : ''
                                }
                            </div>
                        </div>
                    </div>
    
                </div>
            )
        }
    } else {
        return (<Loader />)
    }
}
export default SingleOrden
