import React, { useEffect } from "react";
import M from "materialize-css";
import logo from "../../img/xpertcad-logo.png";
export default function SideNav(params) {
    useEffect(() => {
        var elems = document.querySelectorAll('.sidenav');
        M.Sidenav.init(elems, { draggable: true });
    }, [])
    return (
        <div>
            <ul id="slide-out" className="sidenav sidenav-nav">
                <li><a href="#?" className="sidenav-close"><i className="material-icons">dehaze</i></a></li>
                {params?.data?.left_menu?.map((e, i) => {
                    return (
                        <li className="cont-list hpx-30" key={"sideNav" + i}>
                            <a className="w-100 text-gray" href={e?.ModuleClass}>{e?.Icon !== "" ? <i className="material-icons">{e?.Icon}</i> : ''}
                                {e?.Module}
                            </a>
                            <div className="tooltip grey darken-4">{e?.Module}</div>
                        </li>
                    )
                })}

                <li className="pl-20">
                    <strong className="text-gray tileNav">Mi rol: {params?.data?.user_full_name}</strong>
                    <div className="centrar power tileNav mt-12">
                        <div className="fz-14">Powered By</div>
                        <img className="w-51" src={logo} alt="Logo" />
                    </div>
                </li>
            </ul>
            <a href="#!" data-target="slide-out" className="sidenav-trigger d-block">
                <i className="material-icons">dehaze</i>
            </a>
        </div>
    )
}