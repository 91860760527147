import React from "react";
import ReactDOM from "react-dom";
import scriptLoader from "react-async-script-loader";
// import Car from "../assets/img/car.jpg";
// import Spinner from "./Spinner";
import Url from "../hooks/url";
import axios from "axios";
import Loader from "../components/loader";

//  const CLIENT = {
//    sandbox:
//      "Adj-b8RXD0tHNeE6hgFXuSPZoRKeNbq7GRym5XFhBkCuPn4IePA_V4TJN6-VQbwg0kM8vRoKWA7MJe-e",
//    production:
//      "AUoLxHG704tGabNFODhozmZYLWfmRciFV-xxO0HuQ0zIBP7eUxlRdisUZvg4-D380DtwMpXHub1ZD7tO"
//  };

//  const CLIENT_ID =
//  window.location.host === 'mplace-dev-h.xpertcad.com' ? CLIENT.sandbox : CLIENT.production;

// console.log(process.env);

let PayPalButton = null;
class PaypalButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showButtons: false,
      loading: true,
      paid: false
    };

    window.React = React;
    window.ReactDOM = ReactDOM;
  }

  componentDidMount() {
    const { isScriptLoaded, isScriptLoadSucceed } = this.props;

    if (isScriptLoaded && isScriptLoadSucceed) {
      PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });
      this.setState({ loading: false, showButtons: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isScriptLoaded, isScriptLoadSucceed } = nextProps;

    const scriptJustLoaded =
      !this.state.showButtons && !this.props.isScriptLoaded && isScriptLoaded;

    if (scriptJustLoaded) {
      if (isScriptLoadSucceed) {
        PayPalButton = window.paypal.Buttons.driver("react", {
          React,
          ReactDOM
        });
        this.setState({ loading: false, showButtons: true });
      }
    }
  }
  createOrder = (data, actions) => {
    console.log('creacion');
    return actions.order.create({
      purchase_units: [
        {
        //   description: +"Mercedes G-Wagon",
          amount: {
            currency: "MXN",
            value: this.props.total
          }
        }
      ]
    });
  };

  onApprove = (data, actions) => {
    actions.order.capture().then(details => {
    //   const paymentData = {
    //     payerID: data.payerID,
    //     orderID: data.orderID
    //   };
        var dataM = {}
      // console.log(details);
      if (this.props.rfc !== undefined) {
        dataM = {
          Toc:1,
          PaymentMethodID: "1",
          ReferenceCode: details.id,
          InvoiceRequired : 1,
          RequesterRFC : this.props.rfc
        }
      }else{
        dataM = {
          Toc:1,
          PaymentMethodID: "1",
          ReferenceCode: details.id
        }
      }
      // console.log(data);
      // console.log('paypalB');
      axios.post(Url+'orders/'+this.props.idOrden+'/buying',dataM,{withCredentials:true})
      .then(res=>{
        // alert('Transaction completada');
        // console.log(res.data.data);
        window.location.href = '/ThankYou'
        localStorage.clear()
      })
      .catch(err=>{
        console.log(err.response);
      })
    //   console.log("Payment Approved: ", paymentData);
      this.setState({ showButtons: false, paid: true });
    });
  };

  render() {
    const { showButtons,loading } = this.state;
    // console.log(this.state);
    return (
      <div className="main">
        {loading && (
            <div>
                <strong>Revisando datos</strong>
                <Loader/>
            </div>
        )}
        {/* <Loader/> */}
        {showButtons && (
          <div>
            <PayPalButton
              createOrder={(data, actions) => this.createOrder(data, actions)}
              onApprove={(data, actions) => this.onApprove(data, actions)}
            />
          </div>
        )}

      </div>
    );
  }
}

export default scriptLoader(`https://www.paypal.com/sdk/js?client-id=Adj-b8RXD0tHNeE6hgFXuSPZoRKeNbq7GRym5XFhBkCuPn4IePA_V4TJN6-VQbwg0kM8vRoKWA7MJe-e&vault=true&disable-funding=credit,card`)(PaypalButton);

// export default scriptLoader(`https://www.paypal.com/sdk/js?client-id=AUoLxHG704tGabNFODhozmZYLWfmRciFV-xxO0HuQ0zIBP7eUxlRdisUZvg4-D380DtwMpXHub1ZD7tO&currency=MXN&disable-funding=credit,card`)(PaypalButton);