import React from "react";
// import Oxxo from "../../img/pagooxxo.png";
import Oxxopay from "../../img/oxxopay_brand.png";
// import Url from "../../hooks/url";
// import Functions from "../../hooks/functions";
// import Axios from "axios";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPrint } from "@fortawesome/free-solid-svg-icons";
// import funciones from "../../hooks/functions";

class oxxo extends React.Component {
    constructor() {
        super();
        this.state = {
            oxxo: {}
        }
        // this.buyOxxo = this.buyOxxo.bind(this);
    }
    componentDidMount() {
        // console.log('creo oxxxo');
        // const { data } = this.props
        const { data } = this.props
        // this.setState({ oxxo: data })
        if (this.props.buySuccess !== undefined) {
            // console.log("compre oxxo");
            // console.log(this.props);
            this.buyOxxo()
        } else {
            // console.log("muestro tiket");
            // console.log(this.props);
            this.setState({ oxxo: data })
        }
    }
    buyOxxo() {
        // const {id}=this.props
        let data = {
            Toc: 1,
            PaymentMethodID: "2",
            ReferenceCode: ""
        }
        if (this.props.rfc !== undefined) {
            data.InvoiceRequired = 1
            data.BillingInfo = this.props.rfc
        }
        this.props.buySuccess({}, data)
        // console.log('oxxo');
        // Axios.post(Url+'orders/'+id+'/buying',data,{withCredentials:true})
        // .then(res=>{
        //     // console.log(res);
        //     Functions.facebookBuy(this.props.dataL)
        //     Functions.dataLayerBuy(this.props.dataL)
        //     this.setState({oxxo:res.data.data.stub})
        // })
        // .catch(err=>{
        //     console.log(err.response);
        // })
    }
    render() {
        const { oxxo } = this.state
        const { data } = this.props
        // console.log('oxxo');
        // console.log(oxxo);
        if (Object.keys(oxxo).length === 0) {
            return (
                <div className="centrar">
                    {/* {this.buyOxxo()} */}
                </div>
            )
        }
        else {
            return (
                <div className="tarjeta text-center">
                    <div id="oxxoP">
                        <div className="opps" id="oxxo">
                            <div className="opps-header">
                                <div className="opps-reminder">Ficha digital. No es necesario imprimir.</div>
                                <div className="opps-info row centrar dblock">
                                    <div className="col s12 l5">
                                        <div className="opps-brand"><img src={Oxxopay} alt="OXXOPay" /></div>
                                    </div>
                                    <div className="col s12 l7">
                                        <div className="opps-ammount">
                                            <h4>Monto a pagar</h4>

                                            {/* {console.log('data')}
                                            {console.log(data)} */}
                                            {Object.keys(data).length > 0 ?
                                                oxxo.stub === undefined ?
                                                    <h5>$ {oxxo.Total} <sup>{oxxo.Currency}</sup></h5> :
                                                    <h5>$ {oxxo.stub.Amount} <sup>{oxxo.Currency}</sup></h5> :
                                                <h5>$ {oxxo.Amount} <sup>{oxxo.Currency}</sup></h5>
                                            }
                                            <p>OXXO cobrará una comisión adicional al momento de realizar el pago.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="opps-reference">
                                    <h3>Referencia</h3>
                                    {/* {console.log(oxxo)} */}
                                    {Object.keys(data).length > 0 ?
                                        oxxo.stub === undefined ?
                                            <div></div> :
                                            <img src={oxxo.stub.BarUrl} alt="barras" className="barras" /> :
                                        <img src={oxxo.BarUrl} alt="barras" className="barras" />
                                    }
                                    {/* <h4 className="text-center code-spei dark-text">{oxxo.Reference}</h4> */}
                                    {Object.keys(data).length > 0 ?
                                        oxxo.stub === undefined ?
                                            <h4 className="text-center code-spei dark-text">{oxxo.PaymentReference}</h4> :
                                            <h4 className="text-center code-spei dark-text">{oxxo.stub.Reference}</h4> :
                                        <h4 className="text-center code-spei dark-text">{oxxo.Reference}</h4>
                                    }
                                </div>
                            </div>
                            <div className="opps-instructions">
                                <h4>Instrucciones</h4>
                                <ol>
                                    <li>Acude al OXXO y muestra al cajero el No. de referencia de <strong>OXXOPay</strong> y paga el monto.<a href="https://www.google.com.mx/maps/search/oxxo/">Encuéntrala aquí</a>.</li>
                                    <li>Conserva el comprobante de pago para futuras aclaraciones.</li>
                                    <li>En un lapso máximo de 48 hrs. posteriores al pago, recibirás un correo de soporte@mailing.xpertcad.com, confirmando la activación de tu plan.</li>
                                </ol>
                                <div className="opps-footnote">Al completar estos pasos recibirás un correo de <strong>Nombre del negocio</strong> confirmando tu pago.</div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}
export default oxxo