import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faShoppingCart,faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import Url from "../hooks/url";
import Wrapper from "../hooks/wrapper";

function checkIn() {
    console.log('chekin');
    let servios = localStorage.getItem('servicios')
    if (servios !== null) {
        // window.location.href = servios
        localStorage.clear()
    }
    async function handleClick() {
        var lasCookies = document.cookie;
        lasCookies = lasCookies.split(';')
        let cookie = ""
        lasCookies.forEach(e => {
            // console.log(e);
            if (e.split("=")[0] === " UrlPlans") {
                // console.log(e);
                cookie = e.split("=")[1]
                // window.location.href = e.split("=")[1]
                // document.cookie = "UrlPlans=;";
            }
        });
        if (cookie !== "") {
            console.log(cookie);
            window.location.href = cookie
            document.cookie = "UrlPlans=; max-age=0";
            document.cookie = "UrlPlans=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            
        } else {
            // console.log(lasCookies.split(';')[2].split("=")[0]);
            const cartId = await Wrapper.getData(Url + 'cart')
            if (cartId.data.data !== null) {
                const cart = await Wrapper.getData(Url + 'cart/' + cartId.data.data.id)
                Axios.post(Url + 'check-in', { withCredentials: true })
                    .then(res => {
                        // console.log('servios');
                        // console.log(servios);
                        if (cart.data.data !== null) {
                            if (cart.data.data.cart.items !== undefined) {
                                window.location.href = '/cart'
                            } else {
                                window.location.href = '/'
                            }
                        } else {
                            window.location.href = '/'
                        }
                        localStorage.clear()
                    })
                    .catch(err => {
                        console.log(err.response);
                        window.location.href = '/'   
                    })
            } else {
                window.location.href = '/'
            }
        }
    }

    handleClick()
    return (
        <div>
        </div>
    );
}

export default checkIn