import React from "react";
import Url from "../../hooks/url";
import axios from "axios";
import Paginador from "../paginador";
import Apis from "../../hooks/apis";
import Wrapper from "../../hooks/wrapper";
import Functions from "../../hooks/functions";
import {messageService} from "../../hooks/global";
import Loader from "../loader";
import NavAuthor from "./navAuthor";
import Redireccionar from "../redireccionar";

const headerss = {
    head1:[
        { id: 'OrderSellerID', label: 'ID', minWidth: 100, align: 'center'},
        { id: 'OrderSellerDate', label: 'Fecha', minWidth: 100, align: 'center'},
        { id: 'OrderSellerTotalTotal', label: 'Precio', minWidth: 100, align: 'center'},
        { id: 'Descripcion', label: 'Descripcion', minWidth: 200, align: 'center'},
        { id: 'OrderSellerStatusName', label: 'Estado', minWidth: 100, align: 'center'},
    ]
}
class listBuy extends React.Component{
    constructor(){
        super();
        this.state={
            data:{},
            dataG:{},
            nav:{},
            redi:false
        }
        this.dataDescription = this.dataDescription.bind(this);
    }
    componentDidMount(){
        messageService.sendMessage('Tienda,/ > Autor > Ventas','bread')
        this.setState({nav:Apis.navbar})
        setTimeout(() => {
            let id = localStorage.getItem("autor")
            this.getData(Url+'orders_sellers/'+id+'/author','data')
        }, 400);
        // console.log(id);
        // this.getData(Url+'orders_sellers/1/author','data')
    }
    redirec(){
        if (this.state.redi) {
            return(
                <Redireccionar link="/author"/>
            )
        }
    }
    async getData(data,st){
        const orders = await Wrapper.getData(data)
        // console.log('orders');
        // console.log(orders);
        if (orders === undefined) {
            this.setState({redi:true})
        }else{
            this.setState({[st]:orders.data.data.orders_sellers})
            this.getKeys()
        }
    }
    getKeys() {
        // const {headers,keyList,data,changeVista,vista} = props
        const {data} = this.state
        // console.log(data);
        let llaves = []
        let keys = Object.keys(data)
        keys = keys.sort(this.ordenarNumeros)
        for (let a = 0; a < keys.length; a++) {
            let descrip = Functions.getDescription(data[keys[a]].OSellerItems)
            data[keys[a]].Descripcion = descrip
            llaves.push(data[keys[a]])
        }
        this.setState({dataG:llaves})
    }
    ordenarNumeros(valor1, valor2) {
        return valor2 - valor1;
    }
    dataDescription(datos,id){
        let data = this.state.dataG
        data.forEach(e => {
            if (e.OrderSellerID === id) {
                localStorage.setItem('OrderF',e.OrderID)
            }
        });
    }
    solicitar(){
        axios.post(Url+'orders_sellers/2/author/payment',{withCredentials:true})
        .then(res=>{
            alert('Los pagos se procesan automaticamente semanalmente')
            console.log(res);
        })
        .catch(error=>{
            alert('Los pagos se procesan automaticamente semanalmente')
            console.log(error.reponse);
        })
    }
    render(){
        if (Object.keys(this.state.nav).length === 0) {
            return (
                <div>
                    {/* {console.log('home')} */}
                    <Loader />
                </div>
            )
        }else{
            const {dataG} = this.state
            return(
                <div>
                    {this.redirec()}
                    {/* <Navbar menu={this.state.nav}/> */}
                    <div className="row2 row w-100 sm white-text text-white">
                        <NavAuthor/>
                        <div className="col l10">
                            <div className="w-90 hminvh-75 m-auto black-text text-black" >
                                <div className="row">
                                    <div className="col s6 l6"><h5 className="sm">Mis ventas</h5></div>
                                    <div className="col s6 l6 text-right">
                                        {/* <button className="btn grey darken-4" onClick={this.solicitar}>Solicitar Pago</button> */}
                                    </div>
                                </div>
                                {/* {console.log(dataG)} */}
                                <Paginador headers={headerss.head1} body={dataG} link="OrderSellerID" redirect="author/sales" dataDescription={this.dataDescription} list="ventas"/>
                            </div>
                        </div>
                    </div>
            
                    {/* <Foter /> */}
                </div>
            )
        }
    }
}

export default listBuy