import React from "react";
import Baner from "../../components/baner";
// import Loader from "../../components/loader";
import Count from "../../components/count";
import Plan from "../../components/plan";
// import ServicePlan from "./servicePlan";
import Wrapper from "../../hooks/wrapper";
// import Url from "../../hooks/url";
import { messageService } from "../../hooks/global";


class service extends React.Component {
    constructor() {
        super();
        this.state = {
            service: {}
        }
    }
    componentDidMount() {
        this.getService()
    }
    async getService() {
        messageService.sendMessage('Tienda,/ > Servicios', 'bread')
        const service = await Wrapper.getDataU('services')
        console.log(service);
        if (service !== undefined) {
            this.setState({ service: service?.data?.data?.services })
        }
    }
    render() {
        // const services = this.state.service

        return (
            <div>
                <Baner titulo="Servicios" descrip="Descripcion de prueba para servicios" type="service" />
                {/* <ServicePlan planes={services} /> */}
                <div className="w-90 m-auto">
                    <div className="text-center">
                        <h1>Conoce nuestros planes de servicio</h1>
                    </div>
                    <div className="row">
                        <div className="col s4 l4">
                            <Plan title="Servicio mensual" descrip="$200 MX" color="purple" icon="directions_walk" />
                        </div>
                        <div className="col s4 l4">
                            <Plan title="Servicio semestral" descrip="$500 MX" color="blue" icon="directions_run" />
                        </div>
                        <div className="col s4 l4">
                            <Plan title="Servicio Anual" descrip="$700 MX" color="teal" icon="directions_bike" />
                        </div>
                    </div>
                </div>
                <div className="row orange p-20">
                    <div className="col s18 l8">
                        <h3 className="white-text">Compra el mejor servicio para tus cursos</h3>
                    </div>
                    <div className="col s4 l4 centrar">
                        <a href="#/checkout" className="waves-effect grey darken-2 btn m-auto mt-30">Comprar</a>
                    </div>
                </div>
                <div className="row w-90 m-auto p-40">
                    <div className="col s12 l4">
                        <Count title="servicio1" descrip="descripcion 1" />
                    </div>
                    <div className="col s12 l4">
                        <Count title="servicio2" descrip="descripcion 2" />
                    </div>
                    <div className="col s12 l4">
                        <Count title="servicio3" descrip="descripcion 3" />
                    </div>
                    <div className="text-center">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum molestie sem mauris, commodo dictum dolor varius sed. Mauris et ornare ligula. Suspendisse eget dignissim ex, quis auctor risus. Pellentesque ut consectetur sapien
                    </div>
                </div>
            </div>
        )
    }
}


export default service