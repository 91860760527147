import React, { useState,useEffect } from 'react';
import Paginador from "../../components/paginador";
import Loader from "../../components/loader";
import { messageService } from "../../hooks/global";
import Url from "../../hooks/url";
import Wrapper from "../../hooks/wrapper";
const headerss = {
    head1:[
        { id: 'SSubID', label: 'ID', minWidth: 100, align: 'center'},
        { id: 'SSubStartDate', label: 'Fecha suscripción', minWidth: 100, align: 'center'},
        { id: 'ServiceInfo.ServiceName', label: 'Tipo de suscripción', minWidth: 200, align: 'center'},
        { id: 'ServiceInfo.PlanInfo.PlanName', label: 'Plan', minWidth: 100, align: 'center'},
        { id: 'ServiceInfo.PlanInfo.PlanPrice', label: 'Precio', minWidth: 100, align: 'center'},
        { id: 'SSubStatusName', label: 'Estatus', minWidth: 100, align: 'center'},
    ]
}
// const prueba = {
//     objeto:[
//         {id:'0',fecha:'04/01/2021',tipo:'Consultor',dias:'350',estado:'Activo'},
//         {id:'1',fecha:'04/01/2021',tipo:'Institucional',dias:'0',estado:'Desactivado'},
//         {id:'2',fecha:'04/01/2021',tipo:'Experto',dias:'520',estado:'Activo'},
//     ]
// }
const Suscripton = ()=>{
    const [dataSus, setDataSus] = useState({});
    useEffect (() => { 
        messageService.sendMessage('Tienda,/ > Servicios,/service > Suscripciones','bread');
        getData();
    },[])
    async function getData() {
        const user = await Wrapper.getData(Url+'users')
        if (user.data.data.user_id !== null) {
            let idU = user.data.data.user_id
            const susc = await Wrapper.getData(Url+'subscriptions/users/'+idU)
            console.log(susc);
            setDataSus(susc.data.data.Subscriptions)
        }
        else{
            window.location.href = '404'
        }
    }
    function getKeys() {
        // const {headers,keyList,data,changeVista,vista} = props
        let llaves = []
        let keys = Object.keys(dataSus)
        for (let a = 0; a < keys.length; a++) {
            llaves.push(dataSus[keys[a]])
        }
        // console.log(llaves);
        // this.setState({dataG:llaves})
        return llaves
    }
    function dataDescription(var1,var2){
        console.log(var1);
        console.log(var2);
    }
    if (Object.keys(dataSus).length > 0) {
        let key = getKeys()
        return(
            <div className="w-80 m-auto pt-20 pb-20">
                <h5>Susripciones</h5>
                <Paginador headers={headerss.head1} body={key} link="SSubID" redirect="service/suscription" list="suscrip" dataDescription={dataDescription}/>
            </div>
        )
    }else{
        return(<Loader/>)
    }
}

export default Suscripton