import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import Principal from './pages/principal';
import Principal from './components/main/Principal';
import 'jquery/dist/jquery';
import './styles/store.css';
ReactDOM.render(
  <Principal />,
  document.getElementById('root')
);
