import React from "react";

class loader extends React.Component {
    render(){
        return(
            <div className="loader-bg centrar">
                <div className="loader loader4">
                    <div>
                        <div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
            </div>
        )
    }
}

export default loader