import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Functions from "../../hooks/functions";

const useStyles = makeStyles({
  table: {
    minWidth: 550,
  },
});

export default function DenseTable(props) {
  const classes = useStyles();
  
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {props.headers.map((e,index)=>{
              return(
                <TableCell key={'head'+index}><strong>{e}</strong></TableCell> 
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.keys.map((row,indice) => (
            <TableRow key={'row'+indice}>
              {props.info.map((e,a)=>(
                <TableCell align="left" key={"tablecell"+a}>{a===0?props.data[row][props.info[a]]:Functions.formatPriceC(props.data[row][props.info[a]],props.curren)}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
