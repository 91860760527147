import React, { useEffect } from "react";
import BannerPlan from "../../components/Plans/BannerPlan";
import OurPlans from "../../components/Plans/OurPlans";
import BreadcrumsClass from "../../js/BreadcrumsClass";
import { useDispatch } from "../../store/StoreProvider";
export default function HomePlans(params) {
    const Dispatch = useDispatch()
    useEffect(()=>{
        let bread = new BreadcrumsClass()
        bread.addBreadcrum('', "")
        Dispatch({ type: 'addBread', data: bread })
    },[Dispatch])
    return (
        <div>
            <BannerPlan/>
            <OurPlans/>
        </div>
    )
}