import React from "react";
// import Wrapper from "../../hooks/wrapper";
import Url from "../../hooks/url";
import Axios from "axios";
import { Link } from "react-router-dom";
// import Simg from "../../img/sinImagen.png";
class detallesO extends React.Component{
    constructor() {
        super();
        this.state= {
            itemsD:[],
            productos:[],
            orden:{},
            newOrden:[]
        }
        this.handleInputCheck = this.handleInputCheck.bind(this);
        this.deleteItems = this.deleteItems.bind(this);
    }
    componentDidMount(){
        // console.log('this.props')
        // console.log(this.props)
        this.setState({productos:this.props.productos})
    }
    handleInputCheck(event) {
        const {itemsD} = this.state
        let items = itemsD
        const target = event.target;
        const value = target.value;
        // const name = target.name;
        items.push(parseInt(value))
    
        this.setState({
          itemsD: items
        });
    }
    async deleteItems(){
        const {OrderID} = this.props.orden
        const {itemsD} = this.state
        let data = {}
        data.items = itemsD
        this.props.editarP()
        Axios.delete(Url+'orders/'+OrderID+'/items',{withCredentials:true,data})
        .then(res=>{
            let key = Object.keys(res.data.data.OrderItems)
            // console.log('res');
            // console.log(res.data.data);
            // console.log(key);
            if (key.length === 1) {
                this.props.singleProductoF(1)
            }
            this.setState({orden:res.data.data})
            this.setState({newOrden:key})
        })
        .catch(error=>{
            console.log(error);
        })
        // const itesDelete = await Wrapper.deleteData(Url+'orders/'+OrderID+'/items',data)
        // console.log(itesDelete);
    }

    render(){
        const { orden,edit } = this.props
        var produc = {}
        if (Object.keys(this.state.orden).length > 0) {
            produc = this.state.newOrden
        }else{
            produc = this.state.productos
        }
        // console.log(this.props.productos);
        return(
            <div>
                {produc.map((e,index)=>{
                    // console.log('this.state');
                    // console.log(this.state);
                    if (Object.keys(this.state.orden).length > 0) {
                        // console.log("this.state.orden.OrderItems[e]");
                        // console.log(this.state.orden.OrderItems[e]);
                        var {OItemDescription,OItemTotalTotal,OItemCurrency,OItemImage,OItemRefID} = this.state.orden.OrderItems[e]
                    }else{
                        // console.log('orden.OSellerItems[e]');
                        // console.log(orden.OSellerItems[e]);
                        var {OSellerItemDescription,OSellerItemTotalTotal,OSellerItemCurrency,OSellerItemRefModule} = orden.OSellerItems[e]
                        if (orden.OSellerItems[e].OSellerItemInfo !== undefined) {
                            var {OSellerItemInfo} = orden.OSellerItems[e]
                            var {OSItemThumbnail,OSItemID} = OSellerItemInfo
                        }
                    }
                    return(
                        <div className="row centrar" key={"produntos"+index}>
                            <div className="col s3 sp sm">
                                {Object.keys(this.state.orden).length > 0?
                                <img src={OItemImage} alt="curso" className="w-100"/>
                                :<img src={OSItemThumbnail} alt="curso" className="w-100"/>}
                            </div>
                            {Object.keys(this.state.orden).length > 0?
                            <div className="col s7 centrar row sm">
                                {OSellerItemRefModule === "courses"?
                                <Link to={'/Courses/'+OItemDescription.replace(/ /g, '-') + '?' + OItemRefID}><small className="col s6 centrar text-overflow">{OItemDescription}</small></Link>:
                                <Link to={'/Class/'+OItemDescription.replace(/ /g, '-') + '?' + OItemRefID}><small className="col s6 centrar text-overflow">{OItemDescription}</small></Link>
                                }
                                <div className="col s6 centrar">{OItemTotalTotal} {OItemCurrency}</div>
                            </div>:
                            <div className="col s7 centrar row sm">
                                <div className="col s6 centrar text-overflow">
                                    {OSellerItemRefModule === "courses"?
                                    <Link to={'/Courses/'+OSellerItemDescription.replace(/ /g, '-') + '?' + OSItemID}><small className="col s6 centrar text-overflow">{OSellerItemDescription}</small></Link>:
                                    <Link to={'/Class/'+OSellerItemDescription.replace(/ /g, '-') + '?' + OSItemID}><small className="col s6 centrar text-overflow">{OSellerItemDescription}</small></Link>
                                    }
                                </div>
                                {/* <small className="col s6 centrar text-overflow">{OSellerItemDescription}</small> */}
                                <div className="col s6 centrar">{OSellerItemTotalTotal} {OSellerItemCurrency}</div>
                            </div>
                            }
                            <div className="col s2 centrar sm">
                                {edit?
                                <label>
                                    <input
                                        name={"item"+index}
                                        value={Object.keys(this.state.orden).length>0?this.state.orden.OrderItems[e].OItemID:orden.OSellerItems[e].OItemID}
                                        type="checkbox"
                                        onChange={this.handleInputCheck} 
                                        />
                                    <span></span>
                                </label>:<div></div>}
                            </div>
                        </div>
                    )
                })}
                {edit?
                <div>
                    <button className="btn bg-orange" onClick={this.deleteItems}>Eliminar</button>
                    <button className="btn outline ml-20" onClick={()=>this.props.editarP()}>Cancelar</button>
                </div>
                :<div></div>}
                <hr/>
                <div className="row">
                    <div className="col s6">
                        {/* <p>Subtotal</p> */}
                        {/* <p>Descuento</p> */}
                        <p></p>
                        <p><strong>Total</strong></p>
                    </div>
                    <div className="col s6 text-right">
                        {/* <p>{orden.OrderSellerTotalAmount}</p> */}
                        {/* <p>{orden.OrderTotalPayments}</p> */}
                        {Object.keys(this.state.orden).length > 0?
                        <p><strong>{this.state.orden.OrderTotalTotal}</strong></p>:
                        <p><strong>{orden.OrderSellerTotalTotal}</strong></p>
                        }
                        <p>IVA incluido</p>
                    </div>
                </div>
            </div>
        )
    }
}
export default detallesO