import React from "react";
import imgPaypal from "../../img/paypal-logo-png-transparent.png";
import imgOxxo from "../../img/oxxo.png";
import imgVisa from "../../img/visa.png";
// import imgTrans from "../../img/deposito.PNG";
import imgSpei from "../../img/spei.png";
import FormCD from "./formCD";
import Wrapper from "../../hooks/wrapper";
import Url from "../../hooks/url";
import CardDefault from "./CardDefault";
// formsurvey,metodos
class payMethod extends React.Component {
    constructor() {
        super();
        this.state = {
            formsurvey: '',
            paypal: 0,
            terminos: false,
            users: {},
            conectado: false,
            changeTarjet: false,
            changeTarjetD: false
        }
        this.changeTarjetF = this.changeTarjetF.bind(this);
        this.changeTarjetDefault = this.changeTarjetDefault.bind(this);
    }
    buyTDC(ordenData, rfc) {
        this.child.showAlert(ordenData, rfc);
    }
    formaPago(data) {
        this.setState({ formsurvey: data })
        this.props.selectMB(data)
        if (this.props.btnBuy !== undefined) {
            if (data === 1) {
                this.props.btnBuy('0')
            } else {
                this.props.btnBuy('1')
            }
        }
    }
    realizarPago() {
        console.log('fin');
    }
    async conectaCuenta(data) {
        await Wrapper.getData(Url + 'users/preferences/' + data)
        // console.log(conect);
        this.setState({ conectado: true })
        this.props.changeConect()
    }
    changeTarjetF(value) {
        this.setState({ changeTarjet: value })
    }
    changeTarjetDefault(value) {
        this.props.setCardDefault(value)
        this.setState({ changeTarjetD: value })
    }
    async componentDidMount() {
        const user = await Wrapper.getData(Url + 'users')
        // console.log(this.props.default);
        this.setState({ users: user.data.data })
        this.setState({ formsurvey: this.props.selectMetodoCompra })

    }
    pago() {
        // const {formsurvey,users,conectado} = this.state
        const { formsurvey } = this.state
        const { metodos, ordens, extra } = this.props
        switch (formsurvey) {
            case 1:
            case 'paypal':
                // console.log(metodos);
                // console.log(metodos[formsurvey].link_account);
                if (metodos[1] !== undefined) {
                    this.getPaypal(metodos[1].client_id, ordens.OrderCurrency, ordens.OrderTotalTotal)
                }
                return (
                    <div className="text-center">
                        {/* {metodos[formsurvey].link_account && (users.linked_accounts !== undefined? users.linked_accounts.paypal === undefined:true) && !conectado?
                        <button className="btn bg-orange" onClick={()=>this.conectaCuenta('paypal')}>Conectar cuenta</button>
                        :<div></div>} */}
                        <ol className="text-left">
                            <li>Regístrate en PayPal. Abre tu cuenta PayPal gratis y asocia tus tarjetas de crédito.</li>
                            <li>Paga con PayPal. Busca el botón de PayPal a la hora de pagar y compra fácilmente ingresando tu correo electrónico y contraseña</li>
                            <li>¡Listo!</li>
                        </ol>

                    </div>
                )
            case 2:
                return (
                    <div>
                        <ol>
                            <li>Haz click en el botón verde de "Generar Orden".</li>
                            <li>El sistema te generará un número de referencia</li>
                            <li>Ve al OXXO que gustes y dale el número de referencia al cajero de forma verbal (como recargar saldo en tu cel) y realiza el pago en efectivo. (OXXO te cobrará una comisión al momento, aproximadamente $10 MXN.). NOTA: El cajero de OXXO debe ingresar la referencia en su pantalla inicial, como si fuera el código de cualquier artículo que venda OXXO, no tiene que buscar ningún servicio en su sistema.</li>
                            <li>Listo!</li>
                        </ol>
                    </div>

                )
            case 3:
            case 'credit':
                // console.log();
                // if (this.props?.metodos[3]?.default !== undefined) {
                //     this.changeTarjetDefault(true)
                // }
                return (
                    <div>
                        {/* {this.props?.metodos[3]?.default !== undefined?this.changeTarjetDefault(true):this.changeTarjetDefault(false)} */}
                        {this.props?.metodos[3]?.default !== undefined ?
                            !this.props?.default ?
                                <div>
                                    <FormCD id={this.props.ordens.OrderID} ref={element => { this.child = element }} getUrl={this.props.getUrl} />
                                    <h6 onClick={() => this.props.setCardDefault(true)} className="blue-text pointer">Usar tarjeta guardada</h6>
                                </div> :
                                <CardDefault data={this.props.metodos[3].default} cambio={this.props.setCardDefault} /> :
                            <FormCD id={this.props.ordens.OrderID} ref={element => { this.child = element }} getUrl={this.props.getUrl} />
                        }
                    </div>
                )
            case 4:
                return (
                    <ol>
                        <li>Imprime o apunta el numero de refencia y el banco</li>
                        <li>Acude a tu sucursal bancaria de preferencia o realiza el pago desde tu app</li>
                        <li>Descarga o guarda tuS instrucciones de pago de pago</li>
                        <li>Ve al apartado de Mis pedidos</li>
                        <li>Selecciona tu orden de transferencia </li>
                        <li>Llena el formulario y sube tuS instrucciones de pago y espera respuesta</li>
                        <li>!Listo¡ realizaste tu compra por transferencia</li>
                    </ol>

                )
            case 5:
            case 'spei':
                return (
                    <ol>
                        <li>Accede a tu banca en línea.</li>
                        <li>Da de alta la CLABE en esta ficha. <strong>El banco deberá de ser STP</strong>.</li>
                        <li>Realiza la transferencia correspondiente por la cantidad exacta en esta ficha, <strong>de lo contrario se rechazará el cargo</strong>.</li>
                        <li>Al confirmar tu pago, el portal de tu banco generará un comprobante digital. <strong>En el podrás verificar que se haya realizado correctamente.</strong> Conserva este comprobante de pago.</li>
                    </ol>
                )
            case 'payment_method.card':
                let mpagos = Object.keys(this.props.tarjetas)
                const tarjetas = this.props.tarjetas
                // console.log(tarjetas);
                const { changeTarjet } = this.state
                return (
                    <div>
                        {changeTarjet ?
                            <div>
                                {mpagos.map((e, index) => {
                                    // console.log("payMethod>mpagos.map");
                                    // console.log(e);
                                    return (
                                        <div className="row" key={"metodos" + index}>
                                            <div className="col s12 l4 centrar">
                                                <label>
                                                    {/* <input type="radio" name="inlineRadioOptions" id={"inlineRadio"+index} value={tarjetas[e].UPID} onChange={()=>setSelectP(tarjetas[e].UPID)}/> */}
                                                    <input type="radio" defaultChecked={formsurvey } name="inlineRadioOptions" id={"inlineRadio" + index} value={tarjetas[e].UPID} onChange={() => this.tarjetaSF(tarjetas[e])} />
                                                    <span>
                                                        <img src={imgVisa} alt="visa" width="100px" />
                                                    </span>
                                                </label>
                                            </div>
                                            <div className="col s12 l4">
                                                <p>Banco: {tarjetas[e].Info.CardBank}</p>
                                                <p>Expiracion: {tarjetas[e].Info.CardExp}</p>
                                            </div>
                                            <div className="col s12 l4 centrar">
                                                <strong>Tarjeta con terminacion: {tarjetas[e].Info.CardLast4} <small>{tarjetas[e].UPOptionValue}</small></strong>
                                            </div>
                                        </div>
                                    )
                                })}
                                {/* <button className="btn bg-orange" onClick={()=>this.props.selectDefault(true)}>Aceptar</button> */}
                                <button className="btn bg-orange" onClick={() => this.props.setMpago(true)}>Agregar nueva tarjeta</button>
                            </div>
                            :
                            <div>
                                <div>
                                    <strong>Banco: </strong>
                                    {extra.Info.CardBank}
                                </div>
                                <div>
                                    <strong>Fecha de expiración: </strong>
                                    {extra.Info.CardExp}
                                </div>
                                <div>
                                    <strong>Tarjeta con terminacion: </strong>
                                    {extra.Info.CardLast4}
                                    <div>
                                        {extra.UPOptionValue}
                                    </div>
                                </div>
                                <button className="btn bg-orange" onClick={() => this.changeTarjetF(true)}>Cambiar tarjeta de pago</button>
                            </div>
                        }
                    </div>
                )
            default:
                break;
        }
    }
    tarjetaSF(data) {
        // console.log(data);
        this.props.selectDefault(data.UPID)
        this.changeTarjetF(false)
        this.props.setDefaultM(data)
    }
    logo(data) {
        // const {terminos} = this.state
        // console.log('data');
        // console.log(data);
        switch (data) {
            case 'PayPal':
            case 'Paypal':
                return (
                    <span className="logoPago mt-40">
                        <img src={imgPaypal} alt="Paypal" className='' />
                    </span>
                )
            case 'Oxxo':
                return (
                    <span className="logoPago mt-40">
                        <img src={imgOxxo} alt="Oxxo" className='' />
                    </span>
                )
            case 'Tarjeta de Crédito/Débito':
            case 'Tarjeta Crédito/Débito':
                return (
                    <span className="logoPago mt-40">
                        <img src={imgVisa} alt="Visa" className='' />
                    </span>
                )
            // case 'Transferencia':
            //     return(
            //         <span className="logoPago mt-40">
            //             <img src={imgTrans} alt="Transaccion"/>
            //         </span>
            //     )
            case 'SPEI':
            case 'Transferencia SPEI':
                return (
                    <span className="logoPago mt-40">
                        <img src={imgSpei} alt="Transaccion" className='' />
                    </span>
                )
            default:
                break;
        }
    }
    changeVista(id) {
        this.props.formaPago(id)
    }
    getPaypal(idClient, curren, cant) {
        document.getElementsByTagName('script')[1].attributes[0].value = 'https://www.paypal.com/sdk/js?client-id=' + idClient + '&currency=' + curren + '&disable-funding=credit,card'
        // console.log(document.getElementsByTagName('script')[1].attributes[0].value);
        setTimeout(() => {
            this.createButton(cant)
        }, 1000);
    }
    createButton(cant) {
        const { ordens } = this.props
        const { paypal } = this.state
        let pay = document.querySelector('#paypal-button-container')
        if (pay !== null && paypal === 0) {
            window.globalMia(cant, ordens.OrderID)
            this.setState({ paypal: 1 })
        }
    }

    render() {
        const { keyMetodos, metodos, extra } = this.props
        // const {terminos} = this.state
        // console.log('extra');
        // console.log(extra);
        // console.log(this.props);
        // console.log(keyMetodos);
        return (
            <div>
                <div id="error-alert" className="oculto"></div>
                <h6 className="dark-text"><strong>Formas de Pago</strong></h6>
                <div className="row">
                    <div className="col s12 l4 brs">
                        <form className="form-check form-check-inline center-align">
                            {
                                keyMetodos.map((e, index) => {
                                    // console.log('metodos[e]')
                                    // console.log(metodos[e])
                                    return (
                                        <div key={'metodo' + index}>
                                            <label>
                                                <input defaultChecked={metodos[e].id === this.state.formsurvey} type="radio" name="inlineRadioOptions" id={"inlineRadio" + index} value={metodos[e].name} onClick={() => this.formaPago(metodos[e].id !== undefined ? metodos[e].id : metodos[e].code)}></input>
                                                {this.logo(metodos[e].name)}
                                                {/* <span className="ml-2">{metodos[e].name}</span> */}
                                            </label>
                                        </div>
                                    )
                                })
                            }
                            {extra !== undefined ?
                                <label>
                                    <input type="radio" name="inlineRadioOptions" id={"inlineRadio"} value={''} onClick={() => this.formaPago(extra.UPOptionType)} />
                                    {this.logo('Tarjeta Crédito/Débito')}
                                    {/* <span className="ml-2">prueba</span> */}
                                </label> : <div></div>
                            }
                        </form>
                    </div>
                    <div className="col s12 l8">
                        <div className="buy">
                            {this.pago()}
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
export default payMethod