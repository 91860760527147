import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import BreadcrumsClass from '../js/BreadcrumsClass';
import { useDispatch } from '../store/StoreProvider';
// import React, { useState } from 'react';
// import logo from "../img/xpertcad-logo.png";

function ErrorP() {
  const Dispatch = useDispatch()
  // Declare a new state variable, which we'll call "count"
  //   const [count, setCount] = useState(0);
  useEffect(() => {
    let bread = new BreadcrumsClass()
    bread.addBreadcrum('Tienda', "/")
    bread.addBreadcrum('404', "")
    Dispatch({ type: 'addBread', data: bread })
  }, [Dispatch])
  return (
    <div className="hminvh-76 text-center centrar">
      {/* <img src={logo} alt="logo" width="20%"/> */}
      <div className="m-auto">
        <h1 className="sm">Error 404</h1>
        <h6 className="dark-text">Lo sentimos la direccion url que deseas consultar no existe</h6>
        <Link to="/" className="btn bg-orange">IR a la tienda</Link>
      </div>
    </div>
  );
}
export default ErrorP