import React from "react";
import Breadcrums from "../Others/Breadcrums";
export default function BannerPlans(params) {
    return (
        <div className="bg-orange pad-none">
            <div className="container">
                <Breadcrums breads={params.breads} clas="transparent" />
                {params.children}
            </div>
        </div>
    )
}