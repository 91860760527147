import React, { useState,useEffect } from 'react';
import { messageService } from "../../hooks/global";
import Wrapper from "../../hooks/wrapper";
import Url, { appid } from "../../hooks/url";
import Functions from "../../hooks/functions";
import PayMethod from "../../components/methodsBuy/payMethod";
import VisorPDF from "../../components/visorPDF";
import Loader from "../../components/loader";

const SingleSerive = (props)=>{
    const [planS, setPlanS] = useState({});
    const [data, setData] = useState({});
    const [plan, setPlan] = useState('');
    const [mpago, setMpago] = useState(false);
    const [methods, setMethods] = useState({});
    const [customized, setCustomized] = useState({});
    const [selectP, setSelectP] = useState('');
    const [defaultM, setDefaultM] = useState({});
    const [pop, setPop] = useState(false);
    const [dataPdf, setDataPdf] = useState({});
    const [terminos, setTerminos] = useState(false);
    const [factura, setFactura] = useState(false);
    const [planId, setPlanId] = useState(false);
    const [rfc, setRfc] = useState('');
    const [load, setLoad] = useState('');
    const [renov, setRenov] = useState(false);
    const [renovtype, setRenovtype] = useState('');

    useEffect (() => { 
        messageService.sendMessage('Tienda,/ > Servicios,/service > Plan','bread');
        let url = window.location.pathname;
        url = url.split('/');
        getData(url[3]);
    },[])
    function addRFC() {
        setRfc(document.querySelector('#rfc').value)
        return Functions.FormatRFC('rfc')
    }
    async function getData(id){
        const service = await Wrapper.getData(Url+'services/'+id)
        // console.log('service');
        // console.log(service);
        if (service !== undefined) {
            setData(service.data.data)
            setPlanS(service.data.data.plans[0])
        }
    }
    
    async function handleChange() {
        let valor = document.querySelector('#plan').value
        let dataO = valor.split('-')
        const service = await Wrapper.postData(Url+'services/'+dataO[1]+'/plans/'+dataO[0])
        // console.log(service);
        // console.log(service.data.data.auto_renew);
        if (service.data.data.auto_renew === 'required') {
            setRenov(true)
            setRenovtype(service.data.data.auto_renew)
        }else{
            setRenov(false)
            setRenovtype(service.data.data.auto_renew)
        }
        setPlanId(dataO[0])
        setPlan(valor);
        setMethods(service.data.data.payment_methods)
        setCustomized(service.data.data.payment_methods.customized)
        setPlanS(data.plans[dataO[2]])
        let tarjetas = Object.keys(service.data.data.payment_methods.customized)
        tarjetas.map(e=>{
            if (service.data.data.payment_methods.customized[e].Info.CardDefault !== undefined) {
                // console.log('service.data.data.payment_methods.customized[e]');
                // console.log(service.data.data.payment_methods.customized[e]);
                setDefaultM(service.data.data.payment_methods.customized[e])
            }
            return false
        })
    }
    function planSelect() {
        if (Object.keys(planS).length > 0) {
            // let keysPlans = Object.keys(planS.PlanOptions)
            // console.log(planS);
            return(
                <div>
                    <h3>{planS.PlanName}</h3>
                    <p>{planS.PlanDescription}</p>
                    <h4>{Functions.formatPrice(planS.PlanMonthPrice)} {planS.PlanCurrency} / Mes</h4>
                    <h6>Total a pagar: {Functions.formatPrice(planS.PlanPrice)} {planS.PlanCurrency}</h6>
                </div>
            )
        }
    }
    async function buyService(event) {
        event.preventDefault()
        setLoad(true)
        if (localStorage.getItem('autor') === 'null') {
            iniciarService()
        }else{
            // console.log('validaciones');
            let dataSend = {}
            let PaymentMethod = {}
            let rfc = document.querySelector('#rfc')
            dataSend.ServiceID = data.ServiceID
            dataSend.PlanID = planId
            PaymentMethod.Type = (selectP === 'payment_method.card'?'customized':'default')
            PaymentMethod.ID = (selectP === 'payment_method.card'?defaultM.UPID:selectP)
            dataSend.PaymentMethod = PaymentMethod
            dataSend.Toc = terminos
            dataSend.InvoiceRequired = factura
            dataSend.RequesterRFC = (rfc === null?'':rfc.value)
            dataSend.Autorenew = renov
            console.log(dataSend);
            const comprar = await Wrapper.postData(Url+'subscriptions',dataSend)
            console.log(comprar);
            if (comprar !== undefined) {
                window.location.href = '/ThankYou'
            }else{
                setLoad(false)
            }
            // console.log(rfc);
            // console.log(defaultM.UPID);
        }
    }
    function selectMB(params) {
        setSelectP(params)
    }
    // function btnBuy(params) {
    //     console.log('btnBuy');
    //     console.log(params);
    // }
    async function getTerminos(){
        const temino = await Wrapper.getData(Url+'toc')
        setPop(true)
        setDataPdf(temino.data.data.toc)
    }
    function activePop(data){
        setPop(data)
    }
    async function handleInputChange(event) {
        event.preventDefault()
        let tarjeta = document.querySelector('#tarjeta')
        let data = {}
        data.titular = tarjeta[0].value
        data.PaymentMethod = 'card'
        data.Token = tarjeta[1].value
        data.caducidad = tarjeta[2].value+'/'+tarjeta[3].value
        data.code = tarjeta[4].value
        await Wrapper.postData(Url+'users/preferences/payment_method',data)
        handleChange()
        setMpago(false)
    }
    function iniciarService() {
        localStorage.setItem('servicios',window.location.href)
        // window.location.href = 'https://accounts.xpertcad.com/?APPID=8'
        window.location.href = 'https://accounts.xpertcad.com/?'+appid
    }
    async function selectDefault(id) {
        let data = {
            default: true
        }
        // console.log(id);
        const defaul = await Wrapper.postData(Url+'users/preference/'+id,data)
        console.log(defaul);
    }
    function metodos() {
        if (Object.keys(methods).length > 0) {
            // console.log('methods');
            // console.log(methods);
            // console.log(Object.keys(customized).length);
            let token = Functions.PassAleatorio(16)
            // console.log('token');
            // console.log(token);
            // let mpagos = Object.keys(customized)
            return(
                <div>
                    {/* {console.log(localStorage.getItem('autor') === 'null')} */}
                    {Object.keys(customized).length === 0?
                    mpago?
                    <div></div>:
                        localStorage.getItem('autor') === 'null'?
                        // <a href="https://accounts.xpertcad.com/?APPID=8" className="btn bg-orange">Agregar metodo de pago</a>:
                        <button className="btn bg-orange" onClick={iniciarService}>Agregar metodo de pago</button>:
                        <button className="btn bg-orange" onClick={()=>setMpago(true)}>Agregar metodo de pago</button>
                    :<div></div>}
                    {mpago?
                        <div className="card p-20 text-center">
                            <form onSubmit={handleInputChange} id="tarjeta">
                                <span className="card-errors"></span>
                                <div>
                                    <label>
                                        <span>Titular de la tarjeta</span>
                                        <input type="text" size="20" name="name" required/>
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        <span>Numero de tarjeta</span>
                                        <input type="number" size="20" name="number" required defaultValue={token} disabled/>
                                    </label>
                                </div>
                                <div className="row">
                                    <div className="col s6">
                                        <div>
                                            <label>
                                                <span>Expiration date (MM/YYYY)</span>
                                                <div className="row">
                                                    <div className="col s6">
                                                        <input type="text" size="2" name="exp_month" required maxLength="2" onKeyPress={()=>Functions.soloNumeros()}/>
                                                    </div>
                                                    <div className="col s6">
                                                        <input type="text" size="4" name="exp_year" required maxLength="4" onKeyPress={()=>Functions.soloNumeros()}/>
                                                    </div>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col s3"></div>
                                    <div className="col s3">
                                        <div>
                                            <label>
                                                <span>CVC</span>
                                                <input type="text" size="4" name="cvc" maxLength="3" required onKeyPress={()=>Functions.soloNumeros()}/>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <button className="btn bg-orange" >Registrar metodo de pago</button>
                                <button className="btn outline ml-5" onClick={()=>setMpago(false)}>Cancelar</button>
                            </form>
                        </div>:
                        <div>
                            {Object.keys(methods.default).length > 0?
                                <div className="card p-20 mt-20">
                                    {/* <button className="btn bg-orange" onClick={selectDefault}>Crear</button> */}
                                    {Object.keys(defaultM).length === 0?
                                    <PayMethod keyMetodos={Object.keys(methods.default)} metodos={methods.default} ordens={methods} selectMB={selectMB}/>:
                                    <PayMethod keyMetodos={Object.keys(methods.default)} metodos={methods.default} ordens={methods} selectMB={selectMB} extra={defaultM} tarjetas={customized} setMpago={setMpago} setDefaultM={setDefaultM} selectDefault={selectDefault}/>
                                    }
                                </div>:
                                <div className="card p-20 mt-20">
                                    <PayMethod keyMetodos={Object.keys(methods.default)} metodos={methods.default} ordens={methods} selectMB={selectMB} extra={defaultM} tarjetas={customized} setMpago={setMpago} setDefaultM={setDefaultM} selectDefault={selectDefault}/>
                                </div>
                            }
                        </div>
                    }
                </div>
            )
        }
    }
    if(Object.keys(data).length !== 0){
        // console.log('data');
        // console.log('data');
        // console.log(data);
        return (
            <div className="w-80 m-auto">
                {pop?
                <VisorPDF pdf={dataPdf} activePop={activePop} title="Terminos y condiones"/>:
                <div></div>
                }
                {load?
                <Loader/>:
                <div></div>
                }
                <div className="row mt-20">
                    <div className="col s12 l8 p-20">
                        <h2>{data.ServiceName}</h2>
                        <p>{data.ServiceDescription}</p>
                        {metodos()}
                    </div>
                    <div className="col s12 l4">
                        <div className="card text-center p-20">
                            {planSelect()}
                            <form className="mt-20">
                                <label>
                                    Selecciona tu plan:
                                    <select value={plan} onChange={handleChange} id="plan">
                                        <option disabled defaultValue value=''>Selecciona plan</option>
                                        {data.plans.map((e,index)=>{
                                            // console.log(e);
                                            return(
                                                // <option value={index+'-'+e.PlanName} key={"plan"+index}>{e.PlanName}</option>
                                                <option value={e.PlanID+'-'+e.ServiceID+'-'+index} key={"plan"+index}>{e.PlanName}</option>
                                            )
                                        })}
                                    </select>
                                </label>
                            </form>
                            {Object.keys(methods).length>0?
                            <form className="text-left" onSubmit={selectP === '' && !terminos?()=>Event.preventDefault():buyService} id="pruebas">
                                <div className="centrar">
                                    <label className="centrar">
                                        <input name="terminos" type="checkbox" required onChange={()=>setTerminos(!terminos)}/>
                                        <span></span>
                                    </label>
                                    <span className="pointer" onClick={getTerminos}>Aceptar terminos y condiciones</span>
                                </div>
                                <div>
                                    <label>
                                        <input name="factura" type="checkbox" onChange={()=>setFactura(!factura)}/>
                                        <span className="dark-text text-dark">Necesito factura</span>
                                    </label>
                                </div>
                                {factura?
                                <label>
                                    Escribe tu RFC:
                                    <input type="text" placeholder="RFC" minLength="12" maxLength="13" onChange={()=>addRFC()} id="rfc" required/>
                                </label>:
                                <div></div>
                                }
                                <label>
                                    <input name="renovacion" type="checkbox" checked={renov?true:false} onChange={()=>setRenov(!renov)} disabled={renovtype === 'required'?true:false} readOnly={renovtype === 'required'?true:false}/>
                                    <span className="dark-text text-dark">Renovacion automatica</span>
                                </label>
                                <button className="btn bg-orange w-100 mt-20" disabled={selectP === '' || !terminos || (factura && rfc.length<12)}>Comprar suscripcion</button>
                            </form>:
                            <div></div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }else{
        return(<div>
            <Loader/>
        </div>)
    }
}

export default SingleSerive