import React from "react";
import logoOxxo from "../../../img/oxxopay_brand.png";
export default function FichaOxxo(params) {
    // console.log(params);
    return(
        <div className="tarjeta text-center">
            <div id="oxxoP">
                <div className="opps" id="oxxo">
                    <div className="opps-header">
                        <div className="opps-reminder">Ficha digital. No es necesario imprimir.</div>
                        <div className="opps-info row centrar dblock">
                            <div className="col s12 l5">
                                <div className="opps-brand"><img src={logoOxxo} alt="OXXOPay"/></div>
                            </div>
                            <div className="col s12 l7">
                                <div className="opps-ammount">
                                    <h4>Monto a pagar</h4>
                                    <h5>$ {params?.data?.Amount}{params?.data?.Total} <sup>{params?.data?.Currency}</sup></h5>:
                                    <p>OXXO cobrará una comisión adicional al momento de realizar el pago.</p>
                                </div>
                            </div>
                        </div>
                        <div className="opps-reference">
                            <h3>Referencia</h3>
                            {params?.data?.BarUrl?
                            <img src={params?.data?.BarUrl} alt="barras" className="barras"/>
                            :''}
                            <h5 className="dark-text">{params?.data?.Reference}{params?.data?.PaymentReference}</h5>
                        </div>
                    </div>
                    <div className="opps-instructions">
                        <h4>Instrucciones</h4>
                        <ol>
                            <li>Acude a la tienda OXXO más cercana. <a href="https://www.google.com.mx/maps/search/oxxo/">Encuéntrala aquí</a>.</li>
                            <li>Indica en caja que quieres realizar un pago de <strong>OXXOPay</strong>.</li>
                            <li>Dicta al cajero el número de referencia en esta ficha para que tecleé directamete en la pantalla de venta.</li>
                            <li>Realiza el pago correspondiente con dinero en efectivo.</li>
                            <li>Al confirmar tu pago, el cajero te entregará un comprobante impreso. <strong>En el podrás verificar que se haya realizado correctamente.</strong> Conserva este comprobante de pago.</li>
                        </ol>
                        <div className="opps-footnote">Al completar estos pasos recibirás un correo de <strong>Nombre del negocio</strong> confirmando tu pago.</div>
                    </div>
                </div>
            </div>	
        </div>
    )
}