import React from "react";
import { useHistory } from "react-router-dom";

function HomeButton(props) {
  let history = useHistory();

  function handleClick() {
    history.push(props.link);
  }

  return (
    <button type="button" onClick={handleClick()}>
      Go home
    </button>
  );
}
export default HomeButton