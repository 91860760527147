import React from "react";
export default function LeyenSpei(params) {
    return (
        <div>
            <ol>
                <li>Accede a tu banca en línea.</li>
                <li>Da de alta la CLABE en esta ficha. <strong>El banco deberá de ser STP</strong>.</li>
                <li>Realiza la transferencia correspondiente por la cantidad exacta en esta ficha, <strong>de lo contrario se rechazará el cargo</strong>.</li>
                <li>Al confirmar tu pago, el portal de tu banco generará un comprobante digital. <strong>En el podrás verificar que se haya realizado correctamente.</strong> Conserva este comprobante de pago.</li>
            </ol>
            <div className="opps-footnote">Nota: Tu pago podría tardar hasta 2 días hábiles en verse reflejado en la plataforma.</div>
        </div>
    )
}