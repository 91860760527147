import React from "react";
import "../styles/styles.scss";
import { Link } from "react-router-dom";

class tab extends React.Component{
    constructor() {
        super();
        this.state= {
            data:{}
        }
    }
    navTabs(li){
        let navLink = document.querySelectorAll('.nav-link')
        let tabPane = document.querySelectorAll('.tab-pane')
        navLink.forEach((e,index)=>{
            e.classList.remove('active')
        })
        tabPane.forEach((e,index)=>{
            e.classList.remove('active')
        })
        navLink[li].classList.add('active')
        tabPane[li].classList.add('show')
        tabPane[li].classList.add('active')
    }
    renderHTML(data,contenido){
        // console.log(contenido);
        setTimeout(() => {
            let div = document.querySelector('.'+data)
            if (div !== null) {
                div.innerHTML = contenido
            }
            
        }, 500);
    }
    render(){
        // console.log(this.props.data);
        const {AuthorName,AuthorDescription,CourseContents,CourseLevel,AuthorID,CourseGoals,CourseGallery,CourseLanguage,CourseDuration,CourseRequirements,sections } = this.props.data
        if (CourseGallery !== '') {
            var gale = JSON.parse(CourseGallery)
            var galeKeys = Object.keys(gale)
        }
        return(
            <div>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item sm">
                        <div className="nav-link active" id="curso-tab" onClick={()=>this.navTabs(0)}>Curso</div>
                    </li>

                    {AuthorName !== undefined?
                    <li className="nav-item sm">
                        <div className="nav-link" id="instructores-tab" onClick={()=>this.navTabs(1)}>Instructor</div>
                    </li>
                    :<div></div>}
                    <li className="nav-item sm">
                        <div className="nav-link" id="contenidos-tab" onClick={()=>this.navTabs(AuthorName === undefined?1:2)}>Contenidos</div>
                    </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div className="row">
                            <div className="col s4 text-center">
                                <p><strong className="grey-text text-darken-2">Nivel</strong></p>
                                <p><span>{CourseLevel}</span></p>
                            </div>
                            <div className="col s4 text-center bsl">
                                <p><strong className="grey-text text-darken-2 ">Lenguaje</strong></p>
                                <p><span>{CourseLanguage}</span></p>
                            </div>
                            <div className="col s4 text-center">
                                <p><strong className="grey-text text-darken-2">Duración</strong></p>
                                <p><span>Horas: {CourseDuration}</span></p>
                            </div>
                        </div>
                        {/* <h6><strong className="grey-text text-darken-2">Requerimientos del curso:</strong></h6> */}
                        {CourseRequirements.map((e,index)=>{
                            return(
                                <p className={"requerimientos"+index} key={"requi"+index}>{this.renderHTML("requerimientos"+index,e)}</p>
                            )
                        })}
                        {/* <h6><strong className="grey-text text-darken-2">Objetivos del curso:</strong></h6> */}
                        <p><span className="objet">{this.renderHTML('objet',CourseGoals)}</span></p>
                        {CourseGallery !== ''?
                        <h6 className="grey-text text-darken-2 mt-20"><strong>Galeria del curso</strong></h6>
                        :<div></div>}
                        <div className="row mt-20">
                            {galeKeys !== undefined ?
                                galeKeys.map((e,index)=>{
                                    return(
                                        <div className="col s4" key={"galeria"+index}>
                                            <img src={gale[e]} className="w-100" alt={"img"+index}/>
                                        </div>
                                    )
                                })
                            :<div></div>}
                        </div>
                    </div>
                    {AuthorName !== undefined?
                    <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                        <div className="row">
                            <h6>{AuthorName}</h6>
                            <p>{AuthorDescription}</p>
                            <Link to={'/Instructor/'+AuthorID} className="btn bg-orange">VER PERFIL</Link>
                        </div>
                    </div>:
                    <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                        <div className="contenido">
                            {this.renderHTML('contenido',CourseContents)}
                        </div>
                    </div>
                    }
                    <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                        <div className="contenido">
                            {sections.map((e,index)=>{
                                return(
                                    <div key={"sections"+index}>
                                        <h6 className="text-grey">{e.NameSection}</h6>
                                        <ol>
                                            {e.topics.map((f,indice)=>{
                                                return(
                                                    <li key={"listC"+indice}>{f.NameTopic}</li>
                                                )
                                            })}
                                        </ol>
                                    </div>
                                )
                            })}
                            {/* {this.renderHTML('contenido',CourseContents)} */}
                        </div>
                    </div>
                </div>
            </div>   
        )
    }
}

export default tab