import React from "react";
import logo from "../img/XCAD-Store.svg";
// export { default as AutoRotatingCarousel } from './carrusel/AutoRotatingCarousel'
import Carrusel from './carrusel'

class baner extends React.Component {
    buton(){
        switch (this.props.type) {
            case 'home':
                return(
                    <a href="/#titleC" className="btn bg-orange">
                        {/* <div className="text-orange pointer"> */}
                        <div>
                                <i className="material-icons-outlined">arrow_downward</i>
                        </div>
                    </a>
                )
            case 'service':
                return(
                    <div className="waves-effect btn bg-orange">
                        Comprar
                    </div>
                )
            default:
                break;
        }
    }
    render(){
        // const {descrip} = this.props
        return(
            <div className="row d-grid">
                {/* <div className="col s12 l12 centrar banner"></div> */}
                <div className="col s12 l12 centrar hvh-42">
                    <div className="w-70 m-auto">
                        <div className="text-center">
                            <img src={logo} width="60%" alt="logo"/>
                        </div>
                        <p className="line-red">Propósito</p>
                        <p>En Xpertcad Store, podrás adquirir los cursos disponibles de industria 4.0, los cursos desarrollados por expertos en temas relacionados, o incluso también podrás rentar nuestras plataformas directamente.</p>
                    </div>
                </div>
                <div className="mt-80 mb-40 w-100">
                    <Carrusel/>
                </div>
                
            </div>
        )
    }
}
export default baner