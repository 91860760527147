import React from "react";
import LinkRef from "./LinkRef";
export default function CardComponent(params) {
    return (
        <div className={"card hoverable" + params?.clas}>
            <div className="color-img">
            <div className={"card-image " + params?.clasImg}>
                {params?.media}
            </div>
            </div>
            <div className="card-stacked ">
                <div className="card-content">
                    {params?.children}
                </div>
                {params?.textButton ?
                    <div className="text-center">
                        {params?.link !== undefined ?
                            <LinkRef to={params?.link}>
                                <button className={"btn " + params?.clasButton}>
                                    <span className="m-auto">{params?.textButton}</span>
                                </button>
                            </LinkRef> :""
                        }
                        {params?.href !== undefined ?
                            <a href={params?.href} target="_blank" rel="noopener noreferrer">
                                <button className={"btn " + params?.clasButton}>
                                    <span className="m-auto">{params?.textButton}</span>
                                </button>
                            </a>:""
                        }
                        {params?.link === undefined && params?.href === undefined ?
                            <button className={"btn " + params?.clasButton}>
                                <span className="m-auto">{params?.textButton}</span>
                            </button>:""
                        }
                    </div>
                    : ''}
            </div>
        </div>
    )
}