import React from "react";
export default function LeyenPaypal(params) {
    return (
        <div>
            <ol>
                <li>Regístrate en PayPal. Abre tu cuenta PayPal gratis y asocia tus tarjetas de crédito.</li>
                <li>Paga con PayPal. Busca el botón de PayPal a la hora de pagar y compra fácilmente ingresando tu correo electrónico y contraseña</li>
                <li>¡Listo!</li>
            </ol>
            <div className="opps-footnote">Nota: Tu pago podría tardar hasta 24 hrs. en verse reflejado en la plataforma.</div>
        </div>
    )
}