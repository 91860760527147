import React from "react";
import { Link } from "react-router-dom";
// import { useDispatch } from "../../store/StoreProvider";

export default function TanksPlans(params) {
    // const dispatch = useDispatch()
    const handdleChangeTypetore = ()=>{
        // dispatch({ type: 'typeStore', data: 'store' })
    }
    return(
        <div className="hminvh-60">
            <div className="w-60 m-auto hminvh-68">
                <div className="card center-text p-20 text-center mt-50">
                    <h2>¡Gracias por tu compra!</h2>
                    <p><strong>Conoce mas de nuestros cursos con nuestros profecionales u observa tus pedidos</strong></p>
                    <Link to='/plans' className="mt-20 btn bg-orange">Ver más planes</Link>
                    <Link onClick={()=>handdleChangeTypetore()} to='/MyOrders'  className="mt-20 btn outline ml-20">Mis pedidos</Link>
                    <Link to='/subscriptions' className="mt-20 btn bg-orange ml-20">Mis suscripciones</Link>
                </div>
            </div>
        </div>
    )
}
