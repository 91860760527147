import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import funciones from "../../hooks/functions";
import GetImg from "../getImgs";
import GetContent from "../Others/checkout/getContent";

export default function NewSubscriptions(params) {
    const [ActualzarMetodos, setActualzarMetodos] = useState(false)
    const ordenData = params.data
    const items = Object.keys(ordenData?.OrderItems)
    const SubOrders = Object.keys(ordenData?.SubOrders)
    const handdleBuy = async () => {
        window.event.preventDefault();
        console.log("handdleBuy");
        let dataBuy = {}
        // let obj = {}
        // dataBuy.ServiceID = ServiceIdPlan
        dataBuy.Toc = params.terminos ? 1 : 0
        dataBuy.PaymentMethodID = params?.selectMB
        dataBuy.ReferenceCode = ""
        // dataBuy.PlanGatewayID = DataPlan?.PaymentMethods[Type].PlanGatewayID
        console.log(ordenData.OrderID);
        console.log(dataBuy);
        // console.log(DataPlan?.PaymentMethods[Type].PlanGatewayID);
        // if (Object.keys(DataRFC).length > 0 && ActiveFeature) {
        //     dataBuy.BillingInfo = DataRFC
        // }
        // if (Type === 3) {
        //     if (!CarDefault) {
        //         let valid = Functions.validForm("tarjeta")
        //         // console.log(valid);
        //         if (valid.invalid.length === 0) {
        //             // console.log("pago de tarjeta");
        //             obj = valid.data
        //             dataBuy.PaymentDetails = obj
        //             Functions.TarjetaCD(dataBuy, obj, DataPlan?.PlanID, DataPlan?.PaymentMethods[3]?.public_key, DataRFC, 'planes', sendBuy, errorTc)
        //             // sendBuy(dataBuy)
        //         } else {
        //             // console.log(valid.invalid[0].parentElement.innerText);
        //             Dispatch({ type: 'notification', data: { type: "message", open: true, vertical: 'top', horizontal: 'right', data: { name: "El campo " + valid.invalid[0].parentElement.innerText + " es obligatorio", type: "warning" } } })
        //         }
        //     } else {
        //         // console.log("compra con tarjeta default");
        //         // console.log(CarDefaultInfo);
        //         dataBuy.PaymentDetails = btoa(CarDefaultInfo.last4 + "/" + CarDefaultInfo.exp_month + "/" + CarDefaultInfo.exp_year)
        //         dataBuy.PaymentMethodDefault = true
        //         sendBuy(dataBuy)
        //     }
        // } else {
        //     // console.log("pago de otros servicios");
        //     // console.log("pago de otros servicios");
        //     obj = DataPlan
        //     // dataBuy.PaymentDetails = obj
        //     sendBuy(dataBuy)
        // }
    }
    useEffect(() => {
        // console.log("NewSubscriptions");
        // console.log(params);
        // console.log(params.terminos);
    }, [params])
    return (
        <div className="w-80 m-auto hminvh-75">
            {params.popFacturaF()}
            <div className="row d-block">
                <div className="col s12 m12 l8 stiky-f">
                    <div className="row">
                        <div className="col s6">
                            <h5 className="mt-20">Pedido #{ordenData.OrderID}</h5>
                        </div>
                        <div className="col s6 text-right mt-12">
                            <div>
                                <div><span className={ordenData.OrderStatusName + '-inter p-2'}>{ordenData.OrderStatusName}</span></div>
                                <div><small>Realizada el: {funciones.fechaFormat(ordenData.OrderDate)}</small></div>
                            </div>
                        </div>
                    </div>
                    <div className="card p-20">


                        {SubOrders.map((e, index) => {

                            return (
                                ordenData.SubOrders[e].InvoiceInfo.StampError !== undefined ?
                                ordenData.SubOrders[e].InvoiceInfo.StampError.ErrorCode === "ExternalAPI" ?
                                    <div className="Pending-bg p-10 text-center">

                                    </div> :
                                    <div className="Pending-bg p-10 text-center">
                                        <div>{ordenData.SubOrders[e].InvoiceInfo.StampError.RequesterRFC}</div>
                                        <button className="btn bg-orange ml-auto mt-10" onClick={() => { params.activePopFactura(true, true); params.setOrderSellerId(e) }}>Validar datos</button>
                                    </div> : <div></div>
                        )

                        })}


                        <div className="row bg-orange white-text text-white p-20 w-100 m-auto mt-10">
                            <div className="col s12 l12 text-left centrar">
                                <h6 className="white-text text-white">Suscripción</h6>
                            </div>

                        </div>

                        {items.map((f, indice) => {
                            return (
                                <div key={"courses" + indice} className="">
                                    <div className="row">
                                        <div className="col s8 centrar">
                                            {ordenData?.OrderItems[f].OItemDescription}
                                        </div>
                                        <div className="col s4">
                                            <small>Subtotal: {funciones.formatPrice(ordenData?.OrderItems[f].OItemTotalAmount)} {ordenData?.OrderItems[f].OItemCurrency}</small><br />

                                            { ordenData.OrderCurrency === "MXN" ? <small>IVA: {funciones.formatPrice(ordenData?.OrderItems[f].OItemTotalTax)} {ordenData?.OrderItems[f].OItemCurrency}</small>: ""}

                                            <br/><small>Total: {funciones.formatPrice(ordenData?.OrderItems[f].OItemTotalTotal)} {ordenData?.OrderItems[f].OItemCurrency}</small>
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                            )
                        })}

                        {SubOrders.map((e, index) => {

                             if(ordenData.OrderStatusName !== 'New' )
                                    return <div className="text-right">
                                        <Link to={'/receipt/' + ordenData.OrderID + '/' + e} className="btn bg-orange ml-auto" onClick={window.scroll(0, 0)}>Ver recibo</Link>
                                    </div>
                        })}



                    </div>
                    {ActualzarMetodos ?
                        <div>
                            {ordenData.OrderStatusName === 'New' ?
                                <div className="card p-20">
                                    <GetContent buy={handdleBuy} Default={params.Default} setDefault={params.setDefault} BillingInfo={params.BillingInfo} SuccesBuy={params.SuccesBuy} setSuccesBuy={params.setSuccesBuy} data={ordenData} type={'metodos'} activePopFactura={params.activePopFactura} selectMB={params.selectMB} checkFactura={params.checkFactura} setSelectMB={params.setSelectMB} vista={params.compra} setCompra={params.setCompra} inicial={params.inicial}/>

                                </div> :
                                <div></div>
                            }
                        </div>
                        : ""}
                </div>
                <div className="col s12 m12 l4 stiky-f" id="terminosF">
                    <div className="card p-20">
                        <div className="text-center">
                            <strong>Detalles del pedido</strong>
                        </div>
                        <hr />

                        <div>
                            {ordenData.OrderStatusName === 'New' ?
                                <div>
                                    <GetContent Default={params.Default} setDefault={params.setDefault} BillingInfo={params.BillingInfo} SuccesBuy={params.SuccesBuy} setSuccesBuy={params.setSuccesBuy} data={ordenData} type={'factura'} activePopFactura={params.activePopFactura} selectMB={params.selectMB} checkFactura={params.checkFactura} setCompra={params.setCompra} inicial={params.inicial} buy={handdleBuy} terminos={params.terminos} setTerminos={params.setTerminos}/>
                                </div> :
                                <div>
                                    <div className="logoPago text-right">
                                        {ordenData.PaymentInfo.PaymentService !== undefined ?
                                            <GetImg type={ordenData.PaymentInfo.PaymentService} class='' /> :
                                            <GetImg type={ordenData.OrderPaymentMethod} class='' />
                                        }
                                        <div className="text-left">
                                            {ordenData.PaymentInfo.PaymentService !== undefined ?
                                                <GetContent buy={handdleBuy} inicial={params.inicial} Default={params.Default} setDefault={params.setDefault} BillingInfo={params.BillingInfo} SuccesBuy={params.SuccesBuy} setSuccesBuy={params.setSuccesBuy} data={ordenData} type={ordenData.PaymentInfo.PaymentService} setCompra={params.setCompra} /> :
                                                <GetContent buy={handdleBuy} inicial={params.inicial} Default={params.Default} setDefault={params.setDefault} BillingInfo={params.BillingInfo} SuccesBuy={params.SuccesBuy} setSuccesBuy={params.setSuccesBuy} data={ordenData} type={ordenData.OrderPaymentMethod} setCompra={params.setCompra} />
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            <Link to='/MyOrders' className="btn bg-orange mt-20 w-100">Regresar</Link>
                        </div>
                    </div>
                    {ordenData.OrderStatusName === "New" && params.cardError.length > 0 ?
                        <div className="card p-20">
                            <div className="text-center">
                                <strong className="text-center">Intentos de Pago</strong>
                                <hr />
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Tipo</th>
                                            <th>Fecha</th>
                                            <th>Detalle</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {params.cardError?.map((e, i) => {
                                            return (
                                                <tr key={'fila' + i} title={ordenData?.PaymentList[3][e]?.Message} className="pointer">
                                                    <td><FontAwesomeIcon icon={faQuestionCircle} /> <GetImg type="3" class="w-px-50" /></td>
                                                    <td>{ordenData?.PaymentList[3][e]?.Card.month + '/' + ordenData?.PaymentList[3][e]?.Card.year}</td>
                                                    <td>**** {ordenData?.PaymentList[3][e]?.Card?.number}</td>
                                                    <td>{ordenData?.PaymentList[3][e]?.Status === 'error' ? 'declinado' : ''}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div> : ''
                    }
                    {ordenData.OrderStatusName === 'New'?
                    <div>
                        {ActualzarMetodos ?
                            <button className="btn bg-orange w-100" onClick={() => setActualzarMetodos(false)}>Cancelar </button> :
                            <button className="btn bg-orange w-100" onClick={() => setActualzarMetodos(true)}>Actualizar Método de Pago</button>
                        }
                    </div>
                    :""}
                </div>
            </div>
        </div>
    )
}