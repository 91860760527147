import React, { useState,useEffect } from 'react';

import Wrapper from "../../hooks/wrapper";
import Functions from "../../hooks/functions";
import 'select2/dist/css/select2.min.css';
import 'select2/dist/js/select2.min.js';
import * as $ from "jquery";
window.Jquery = window.$ = $
var altoE = 0
var alto = 0
var antes = ''
const ModalTos = (props)=>{
    const [toc, setToc] = useState('');

    useEffect (() => {
        getToc()
    },[]);

    async function getToc() {
        const res = await Wrapper.getDataU('toc')
        setToc(res.data.data.toc);
    }

    function cancelar() {
        props.setActiveFeature(false)
        props.setActiveModal(false)
    }

    console.log(toc);
    if (toc) {

        return(
            <div className="pop centrar">
                <div className="card w-70 m-auto pop-card">
                    <div className="card-title bg-orange white-text text-white">
                        <div className="row sm">
                            <div className="col s10 l10">
                                <span className="text-center w-100 fz-20">Términos y Condiciones del Servicio</span>
                            </div>
                            <div className="col s2 l2 text-right">
                                <div className="close centrar" onClick={cancelar}>
                                    <i className="material-icons-outlined text-white fz-40 ml-auto">highlight_off</i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col s12">{toc}</div>

                        <div className="col s12 right-align">
                            <a className="waves-effect waves-light bg-orange btn" onClick={cancelar}>Cerrar</a>
                        </div>
                    </div>

                </div>
            </div>
        )
    }else{
        return(
            <div></div>
        )
    }
}
export default ModalTos