class BreadcrumsClass{
    constructor(){
        this.breadcrums = []
    }
    addBreadcrum(text,link){
        let obj = {}
        obj.text = text
        obj.link = link
        this.breadcrums.push(obj)
    }
}
export default BreadcrumsClass