import React, { memo } from "react";
import imgOxxo from "../img/oxxo.png";
import imgPaypal from "../img/paypal-logo-png-transparent.png";
import imgVisa from "../img/visa.png";
import imgTrans from "../img/deposito.PNG";
import imgSpei from "../img/spei.png";
const GetImg = memo((props) => {
    // console.log(props);
    switch (props.type) {
        case 'PayPal':
        case '1':
        case 1:
            return (
                <img src={imgPaypal} alt="paypal" className={props.class} />
            )
        case "OxxoPay":
        case '2':
        case 2:
        case "Oxxo":
        case "oxxo":
            return (
                <img src={imgOxxo} alt="oxxo" className={props.class} />
            )
        case "CREDIT":
        case '3':
        case 3:
        case "card":
            return (
                <img src={imgVisa} alt="visa" className={props.class} />
            )
        case 'trans':
        case '4':
        case 4:
            return (
                <img src={imgTrans} alt="trans" className={props.class} />
            )
        case 'spei':
        case '5':
        case 5:
            return (
                <img src={imgSpei} alt="spei" className={props.class} />
            )
        default:
            break;
    }
})
export default GetImg