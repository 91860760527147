import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function FadeMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
      console.clear()
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    console.clear()
    setAnchorEl(null);
  };

  return (
    <div>
      <Button aria-controls="fade-menu" aria-haspopup="true" className="btn bg-orange white-text text-white" onClick={handleClick}>
        <FontAwesomeIcon icon={faCaretDown}/>
      </Button>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={()=>{props.setActivePop(true); props.SetTypePop('pdf'); handleClose()}}>Descargar PDF</MenuItem>
        <MenuItem onClick={()=>{props.setActivePop(true); props.SetTypePop('xml'); handleClose()}}>Descargar XML</MenuItem>
      </Menu>
    </div>
  );
}
