import React from "react";
import { Link } from "react-router-dom";

export default function LinkRef(params) {
    return (
        params.to !== undefined ?
            <Link className={params.clas} to={params.to} onClick={() => window.scroll(0, 0)}>
                {params.children}
            </Link> :
            <div className={params.clas} onClick={() => window.scroll(0, 0)}>
                {params.children}
            </div>

    )
}