import React from "react";
import Url from "../../hooks/url";
import Wrapper from "../../hooks/wrapper";
import Functions from "../../hooks/functions";
import {messageService} from "../../hooks/global";
import Loader from "../loader";
import PopFactura from "./popFactura";
import imgPaypal from "../../img/paypal-logo-png-transparent.png";
import imgOxxo from "../../img/oxxo.png";
import imgVisa from "../../img/visa.png";
import imgTrans from "../../img/deposito.PNG";
import VisorPDF from "../visorPDF";
import { Link } from "react-router-dom";

class singleInvoice extends React.Component{
    constructor(){
        super();
        this.state={
            id:'',
            pago:{},
            pop:false,
            popF:false,
            vista:'',
        }
        this.activePop = this.activePop.bind(this);
        this.activePopFactura = this.activePopFactura.bind(this);
        this.getInicial = this.getInicial.bind(this);
    }
    componentDidMount(){
        this.getInicial()
    }
    getInicial(){
        let url = window.location.pathname
        url = url.split('/')
        url = url[3]
        messageService.sendMessage('Tienda,/ > Autor > Facturas,/author/invoices/ > Factura #'+url,'bread')
        this.setState({id:url})
        this.getData(url)
    }
    async getData(id){
        const orderSeller = await Wrapper.getData(Url+'orders_sellers/'+id+'/invoices')
        // console.log(orderSeller);
        this.setState({pago:orderSeller.data.data.InvoiceInfo})
    }
    activePopFactura(data){
        this.setState({popF:data})
    }
    activePop(data){
        this.setState({pop:data})
    }
    pop(){
        if (this.state.popF) {
            return(<PopFactura activePopFactura={this.activePopFactura} id={this.state.id} getInicial={this.getInicial} type="invoice"/>)
        }
    }
    download(data,filename,type="text/plain") { 
        // Create an invisible A element
        const a = document.createElement("a");
        a.style.display = "none";
        document.body.appendChild(a);

        // Set the HREF to a Blob representation of the data to be downloaded
        a.href = window.URL.createObjectURL(
            new Blob([data], { type })
        );

        // Use download attribute to set set desired file name
        a.setAttribute("download", filename);

        // Trigger the download by simulating click
        a.click();

        // Cleanup
        window.URL.revokeObjectURL(a.href);
        document.body.removeChild(a);
    } 
    logo(data){
        // console.log(data);
        switch (data) {
            case '1' && 'PayPal':
                return(
                    <span className="logoPago mt-40">
                        <img src={imgPaypal} alt="Paypal"/>
                    </span>
                )
            case '2':
                return(
                    <span className="logoPago mt-40">
                        <img src={imgOxxo} alt="Oxxo"/>
                    </span>
                )
            case '3':
                return(
                    <span className="logoPago mt-40">
                        <img src={imgVisa} alt="Visa"/>
                    </span>
                )
            case '4'&& 'Transferencia':
                return(
                    <span className="logoPago mt-40">
                        <img src={imgTrans} alt="Transaccion"/>
                    </span>
                )
            default:
                break;
        }
    }
    viewPop(dataPdf){
        if (this.state.pop) {
            return(
                <VisorPDF pdf={dataPdf} activePop={this.activePop} title="PDF de factura"/>
            )
        }
    }
    render(){
        const {id,pago} = this.state
        // console.log(pago);
        if (Object.keys(pago).length === 0) {
            return(<Loader/>)
        } else {
            return(
                <div>
                    {/* <Navbar menu={Apis.navbar}/> */}
                    {this.viewPop(pago.OSInvoiceFileUrlPDF)}
                    {this.pop()}
                    <div className="w-90 m-auto hminvh-77 p-20">
                        <div className="row">
                            <div className="col s6 l6">
                                <h5>Factura #{id}</h5>
                            </div>
                            <div className="col s6 l6 text-right">
                                <div><span className={pago.OSInvoiceStatusName+'-inter p-3'}>{pago.OSInvoiceStatusName}</span></div>
                                <div><small>Realizada el: <strong>{Functions.fechaFormat(pago.OSInvoiceRequestDate)}</strong></small></div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s12 l8">
                                <div className="w-100 card p-20">
                                    <div className="row">
                                        <div className="col s6 l6 mt-10">
                                            <strong>Detalles de la factura</strong>
                                        </div>
                                        <div className="col s6 l6">
                                            {pago.OSInvoiceStatusName !== 'New'?
                                            pago.OSInvoiceFileUrlXML === ''?
                                            <div className="text-right">
                                                {/* {pago.BuyingInfo.BuyerInfo} */}
                                                <button className="btn bg-orange" onClick={()=>this.activePopFactura(true)}>Subir factura</button>
                                            </div>:
                                            <div className="text-right">
                                                {/* <a className="btn bg-orange" href={pago.OSInvoiceFileUrlXML} download='Factura.XML' target="_blank" rel = "noopener noreferrer">Descargar factura</a> */}
                                                <button className="btn bg-orange" onClick={()=>Functions.descargarArchivo(pago.OSInvoiceFileXMLData,'FacturaXML_'+id)}>Descargar XML</button>
                                                <button className="btn bg-orange ml-20" onClick={()=>this.activePop(true)} >Descargar PDF</button>
                                                {/* <button className="btn bg-orange" onClick={()=>Functions.imprimir('xml')} >Descargar factura</button> */}
                                            </div>
                                            :<div></div>
                                            }
                                        </div>
                                    </div>
                                    <hr/>
                                    <p><strong>Ganancia: </strong><span>{pago.OSInvoiceTotalAmount}</span></p>
                                    <p><strong>Impuestos: </strong><span>{pago.OSInvoiceTotalTax}</span></p>
                                    <p><strong>Total: </strong><span>{pago.OSInvoiceTotalTotal}</span></p>
                                    {/* <iframe src={pago.OSInvoiceFileUrlXML} title="XML" className="w-100" id="xml"/>  */}
                                    {/* <iframe src="https://api-mplace-dev.xpertcad.com/invoice.xml" title="XML" className="w-100" id="xml"/>  */}
                                </div>
                                
                            </div>
                            <div className="col s12 l4 stiky-f">
                                <div className="w-100 card p-20 text-center">
                                    <strong>Detalles del solicitante</strong>
                                    <hr/>
                                    <div className="text-right">
                                        {this.logo(pago.BuyingInfo.PaymentMethodName)}
                                    </div>
                                    <div className="text-left">
                                        {pago.BuyingInfo.BuyerInfo.BuyerName === 'Público en General'?
                                        <div>
                                            <p><strong>Factura para:</strong><br/> {pago.BuyingInfo.BuyerInfo.BuyerName}</p>
                                        </div>:
                                        <div>
                                            <p><strong>Nombre:</strong><br/> {pago.BuyingInfo.BuyerInfo.BuyerName}</p>
                                            <p><strong>Correo:</strong><br/> {pago.BuyingInfo.BuyerInfo.BuyerEmail}</p>
                                            <p><strong>RFC:</strong><br/> {pago.OSInvoiceRequestRFC}</p>
                                        </div>
                                        }
                                        <Link className="btn bg-orange w-100" to="/author/invoices">Regresar</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <Foter/> */}
                </div>
            )
        }
    }
}

export default singleInvoice