import React, { useCallback, useEffect, useState } from "react";
import Wrapper from "../hooks/wrapper";
import Loader from "../components/loader";
import Tablas from "../components/tablas";
import { useHistory } from "react-router";
import BreadcrumsClass from "../js/BreadcrumsClass";
import { useDispatch } from "../store/StoreProvider";
const headers = ['Nombre', 'Correo', 'Telefono', 'Fecha y hora', 'Cantidad', 'Status', 'Acciones']
export default function MyOrders(params) {
    const Dispatch = useDispatch()
    const history = useHistory()
    const [DataOrdes, setDataOrdes] = useState({})
    const getOrdes = useCallback(async () => {
        let dataOrdes = await Wrapper.getDataU('orders')
        // console.log(dataOrdes);
        if (dataOrdes.status === 400) {
            history.push('/')
        }else{
            setDataOrdes(dataOrdes?.data?.data?.Orders)
        }
    }, [history])
    const actionTabla = (data1,data2) => {
        console.log(data1);
        console.log(data2);
    }
    useEffect(() => {
        // console.log('prueba de redireccion');
        let bread = new BreadcrumsClass()
        bread.addBreadcrum('Tienda', "/")
        bread.addBreadcrum('Mis pedidos', "")
        Dispatch({ type: 'addBread', data: bread })
        getOrdes()
    }, [getOrdes,Dispatch])
    return (
        <div className="grey lighten-4">
            <div className="w-80 m-auto hminvh-75">
                <div>
                    <h5>Mis pedidos</h5>
                    {/* {console.log(DataOrdes)} */}
                    {Object.keys(DataOrdes).length>0?
                    <Tablas headers={headers} data={DataOrdes} changeVista={actionTabla} />
                    :<Loader/>}
                </div>
            </div>
        </div>
    )
}
