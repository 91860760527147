import React from "react";
import { messageService } from "../../hooks/global";
import Functions from "../../hooks/functions";
class formCD extends React.Component{
    constructor() {
        super();
        this.state= {
        }
        this.handleInputChange = this.handleInputChange.bind(this);
    }
  
    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
    
        this.setState({
            [name]: value
        });
    }
    showAlert = (orden,rfc) => {
        let message = document.querySelector('#error-alert')
        message.innerHTML = ''
        const {id} = this.props
        let number = Array.from(this.state.number)
        let DateCard = (number[12]+number[13]+number[14]+number[15])+'/'+this.state.exp_month+'/'+this.state.exp_year
        DateCard = btoa(DateCard)
        if (rfc !== undefined) {
            window.TarjetaCD(orden,this.state,id,orden.PaymentMethods[3].public_key,rfc,DateCard)
        }else{
            window.TarjetaCD(orden,this.state,id,orden.PaymentMethods[3].public_key,'',DateCard)
        }
        // let message = document.querySelector('#error-alert')
        messageService.sendMessage('alert-notificacion','typeAlerta');
        messageService.sendMessage('Revisando informacion','alerta');
        setTimeout(() => {
            // console.log('message');
            // console.log(message);
            // console.log(message.innerHTML);
            switch (message.innerHTML) {
                case 'El mes de expiración es inválido.':
                    this.setState({exp_month:''})
                    let mes = document.querySelector('#exp_month')
                    mes.focus()
                    mes.value = ''
                    break;
                case 'El año de expiración es inválido.':
                case 'La tarjeta ha expirado.':
                    this.setState({exp_year:''})
                    let year = document.querySelector('#exp_year')
                    year.focus()
                    year.value = ''
                    break;
                case 'El nombre del tarjetahabiente es inválido.':
                    this.setState({name:''})
                    let name = document.querySelector('#name')
                    name.focus()
                    name.value = ''
                    break;
                case 'El número de la tarjeta es inválido.':
                case 'La tarjeta ingresada ha sido declinada. Por favor intenta con otro método de pago.':
                    this.setState({number:''})
                    let number = document.querySelector('#number')
                    number.focus()
                    number.value = ''
                    break;
                case 'El cvc (código de seguridad) de la tarjeta es inválido.':
                    this.setState({cvc:''})
                    let cvc = document.querySelector('#cvc')
                    cvc.focus()
                    cvc.value = ''
                    break;
                default:
                    break;
            }
            if (message !== null && message.innerHTML !== '') {
                messageService.sendMessage('alert-notificacion','typeAlerta');
                messageService.sendMessage(message.innerHTML,'alerta');
            }else{
                messageService.sendMessage('alert-confirm','typeAlerta');
                messageService.sendMessage('La compra se realizo correctamente','alerta');
            }
            this.props.getUrl(true)
        }, 2000);
    }
 
    render(){
        return(
            <form id="tarjeta">
                <span className="card-errors"></span>
                <div>
                    <label>
                        <span>Titular de la tarjeta</span>
                        <input required type="text" size="20" name="name" onBlur={this.handleInputChange} id='name'/>
                    </label>
                </div>
                <div>
                    <label>
                        <span>Numero de tarjeta</span>
                        <input required type="text" size="20" name="number" onBlur={this.handleInputChange} onKeyPress={()=>Functions.soloNumeros()} id='number'/>
                    </label>
                </div>
                <div className="row">
                    <div className="col s12 m6 l6 xl5">
                        <div>
                            <label>
                                <span>Expiration date (MM/YYYY)</span>
                                <div className="row">
                                    <div className="col s5">
                                        <input required type="text" size="2" name="exp_month" onBlur={this.handleInputChange} maxLength='2'onKeyPress={()=>Functions.soloNumeros()} id='exp_month'/>
                                    </div>
                                    <div className="col s1"><p>/</p></div>
                                    <div className="col s6">
                                        <input required type="text" size="4" name="exp_year" onBlur={this.handleInputChange} maxLength='4' onKeyPress={()=>Functions.soloNumeros()} id='exp_year'/>
                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className="col s3 m3 l3 xl3"></div>
                    <div className="col s3 m3 l3 xl3">
                        <div>
                            <label>
                                <span>CVC</span>
                                <input required type="text" size="4" name="cvc" onBlur={this.handleInputChange} maxLength="3" onKeyPress={()=>Functions.soloNumeros()} id='cvc'/>
                            </label>
                        </div>
                    </div>
                </div>
                {/* <button className="btn bg-orange" onClick={this.create}>Realizar pago</button> */}
            </form>
        )
    }
}
export default formCD