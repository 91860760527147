import { Subject } from 'rxjs';

const subject = new Subject();
var mensaje = {}
export const messageService = {
    sendMessage: (message,type) =>{
        // console.log(type);
        switch (type) {
            case 'Cproducto':
                mensaje.nav = message
                break;
            case 'bread':
                mensaje.bread = message
                break;
            case 'alerta':
                mensaje.alert = message
                break;
            case 'typeAlerta':
                mensaje.typeAlert = message
                break;
            default:
                break;
        }
        subject.next({ text: mensaje.nav, breadcrumb:mensaje.bread, alerta:mensaje.alert, typeAlerta:mensaje.typeAlert })
    },
    clearMessages: () => subject.next(),
    getMessage: () => subject.asObservable()
};
