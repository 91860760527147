import React, { useEffect } from 'react';
// import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { useDispatch, useStore } from '../../store/StoreProvider';
import CardComponent from './CardComponet';
import LinkRef from './LinkRef';

export default function PositionedSnackbar(props) {
    const Store = useStore()
    const Dispatch = useDispatch()
    const [state, setState] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const handdleConfirm = ()=>{
        Dispatch({ type: 'confirm', data: true })
        handleClose()
    }
    const { vertical, horizontal, open } = state;
    const handleClose = () => {
        setState({ ...state, open: false });
        Dispatch({ type: 'notification', data: { open: false, vertical: 'top', horizontal: 'right' } })
    };

    useEffect(() => {
        if (Object.keys(Store.notification).length > 0) {
            // console.log(Store.notification);
            setState(Store.notification)
            // setTimeout(() => {
            //     handleClose()
            // }, 1000);
        }
    }, [Store])
    return (
        <div className="notificacions" onClick={() => Store.notification.comfirm !== undefined?{}:handleClose()}>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                onClose={handleClose}
                key={vertical + horizontal}
                className="w-30"
            >
                {Store.notification.comfirm !== undefined ?
                    <CardComponent clas="p-20 horizontal" >
                        <p>{Store.notification.data.mess}</p>
                        <div className="mt-20">
                            <button onClick={() => handdleConfirm()} className="btn green text-white"><small>Aceptar</small></button>
                            <button onClick={() => handleClose()} className="btn red text-white ml-10"><small>Cancelar</small></button>
                        </div>
                    </CardComponent>
                    : Store.notification.exist !== undefined ?
                        <CardComponent onClick={() => handleClose()} clas="horizontal" clasImg="me-circle">
                            <LinkRef onClick={() => handleClose()} to={Store?.notification?.data?.link}>
                                {Store?.notification?.data?.text !== undefined ?
                                    <small>{Store?.notification?.data?.text}</small> :
                                    <small onClick={() => handleClose()}><strong className="dark-text">{Store?.notification?.data?.name} <span className="text-orangeMe">ya fue agregado</span></strong></small>
                                }
                            </LinkRef>
                            <LinkRef onClick={() => handleClose()} to={'/cart'}>
                                <button onClick={() => handleClose()} className="btn bg-orange w-100 mt-20">
                                    <small>Ir al carrito</small>
                                </button>
                            </LinkRef>
                        </CardComponent> :
                        Store?.notification?.type === "message" ?
                            <CardComponent onClick={() => handleClose()} clas="horizontal" >
                                <Alert onClick={() => handleClose()} severity={Store?.notification?.data?.type}>{Store?.notification?.data?.name}</Alert>
                                {/* <small onClick={() => handleClose()}><strong className="dark-text">{Store?.notification?.data?.name}</strong></small> */}
                            </CardComponent> :
                            <CardComponent onClick={() => handleClose()} clas="horizontal" media={<img className="sm" src={Store?.notification?.data?.img} alt="me" height="100px" />} clasImg="me-circle">
                                <LinkRef onClick={() => handleClose()} to={Store?.notification?.data?.link}>
                                    <small onClick={() => handleClose()}><strong className="dark-text">{Store?.notification?.data?.name} <span className="text-orangeMe">fue agregado</span></strong></small>
                                </LinkRef>
                                <LinkRef onClick={() => handleClose()} to={'/cart'}>
                                    <button onClick={() => handleClose()} className="btn bg-orange w-100 mt-20">
                                        <small>Ir al carrito</small>
                                    </button>
                                </LinkRef>
                            </CardComponent>
                }
            </Snackbar>
        </div>
    );
}
